.page-loader {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    text-align: center;
    z-index: 99999;
}

.loader {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    perspective: 1200;
}

.flipper {
    position: relative;
    display: block;
    height: inherit;
    width: inherit;
    animation: flip 1.2s infinite ease-in-out;
    transform-style: preserve-3d;
}

.front,
.back {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    display: block;
    font: 500 17px/40px $main-font;
    background-color: #000;
    height: 100%;
    width: 100%;
    text-align: center;
}

.back {
    background-color: #ebebeb;
    z-index: 800;
    transform: rotateY(-180deg);
}

@keyframes flip {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}
