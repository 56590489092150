h1 {
    color: #000;
    font-size: 26px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}
h2 {
    color: #000;
    font-size: 23px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}
h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}
h4 {
    color: #000;
    font-size: 17px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}
h5 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}
h6 {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    padding: 13px 0;
    line-height: 1;
}

strong {
    font-weight: 600;
}

p {
    color: #6f6f6f;
    font: 15px/25px $content-font;
    margin-bottom: 23px;
    a {
        color: $accent-color;
        text-decoration: none;
    }
    &.p {
        font: 13px/23px $content-font;
        margin: 0;
    }
    .orange {
        background-color: $accent-color;
        color: #fff;
        line-height: 16px;
        display: inline-block;
        padding: 0 4px 2px;
    }
    .dark {
        background-color: #16171a;
        color: #fff;
        line-height: 16px;
        display: inline-block;
        padding: 0 4px 2px;
    }
    .light {
        background-color: #efefef;
        line-height: 16px;
        display: inline-block;
        padding: 0 4px 2px;
    }
    .underline {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAABCAYAAAASC7TOAAAAFklEQVQIW2Ncv379fwY0EBgYyAgSAgBXKgQCHSg8kwAAAABJRU5ErkJggg==) repeat-x bottom;
        line-height: 16px;
        display: inline-block;
        padding: 0 4px 2px;
    }
}

hr {
    border: none;
    border-top: 1px solid #f2f2f2;
    margin: 15px 0 15px;
}

blockquote {
    font: 18px/26px $accent-font-3;
    position: relative;
    padding: 3px 0 4px 16px;
    margin-bottom: 23px;
    p {
        color: #313131;
        font: 18px/26px $accent-font-3;
        margin: 0;
        position: relative;
    }
    .author {
        color: #626465;
        display: inline-block;
        font: 12px/22px $main-font;
    }
}

.bq-tp-1 {
    border-left: 1px solid $accent-color;
}

.bq-tp-2 {
    padding: 3px 0 4px 27px;
    &:before {
        content: '”';
        color: #e9e9e9;
        font-size: 100px;
        line-height: 1;
        position: absolute;
        left: 0;
        top: -6px;
    }
}

.bq-tp-3 {
    background-color: #fcfcfc;
    padding: 33px 20px 34px 50px;
    &:before {
        content: '“';
        color: $accent-color;
        font-size: 40px;
        line-height: 1;
        position: absolute;
        left: 20px;
        top: 35px;
    }
     p {
        color: #6f6f6f;
        font: 16px/24px $accent-font-3;
        margin-bottom: 4px;
    }
}

.list {
    color: #414141;
    font-size: 14px;
    margin-bottom: 25px;
    li {
        padding: 6px 0;
    }
    i {
        margin-right: 10px;
    }
}

.mark-list {
    li {
        padding-left: 20px;
        position: relative;
        &:before {
            @extend .awesome-font;
            color: #16171a;
            content: '\f00c';
            font-size: 11px;
            line-height: 35px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    &.mark-circle {
        li {
            padding-left: 14px;
            &:before {
                content: '\f111';
                font-size: 7px;
            }
        }
    }
}

.num-list {
    list-style: decimal inside;
}

.title-blc-1 {
    padding: 0 20px;
    position: relative;
    margin: 20px -20px;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        bottom: 4px;
    }
    h4 {
        padding: 0;
    }
    span {
        font-weight: 400;
    }
}

.title-blc-2 {
    padding: 0 20px;
    position: relative;
    margin: 20px -20px;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        top: 7px;
    }
    .title-blc-inner {
        border-bottom: 1px solid #f0f0f0;
        padding: 0 0 16px;
        p {
            color: #949494;
            font-size: 12px;
            line-height: 17px;
            margin: 7px 0 0;
        }
    }
    h4 {
        padding: 0;
    }
    span {
        font-weight: 400;
    }
}

.title-blc-3 {
    border-radius: 2px;
    border: 1px solid #ebebeb;
    padding: 7px 15px;
    text-align: center;
}

.title-blc-4 {
    background-color: #fafafa;
    border-radius: 2px;
    padding: 15px 25px;
    margin-bottom: 27px;
}

.title-blc-5 {
    overflow: hidden;
    margin-bottom: 19px;
    .title-27 {
        display: inline-block;
        position: relative;
        &:before {
            background-color: #ebebeb;
            content: '';
            position: absolute;
            left: 100%;
            top: 50%;
            width: 10000%;
            height: 1px;
            margin-left: 8px;
        }
        .check-ic-1 {
            margin: -2px 0 0 2px;
        }
    }
}

.title-1 {
    color: #fff;
    font: 600 30px/33px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($mb-ls) {
        font-size: 30px * $mb-index;
        line-height: 33px * $mb-index;
    }
}

.title-2 {
    color: #fff;
    font: 600 22px/26px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($md) {
        font-size: 22px * $md-index;
        line-height: 26px * $md-index;
    }
    @include media($mb-ls) {
        font-size: 17px;
        line-height: 25px;
    }
}

.title-3 {
    color: #181818;
    font: 500 15px/21px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-4 {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    float: left;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-5 {
    color: #181818;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($md) {
        font-size: 16px * $md-index;
        line-height: 23px * $md-index;
    }
}

.title-6 {
    color: #181818;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-7 {
    color: #fff;
    font: 600 21px/27px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-8 {
    color: #909090;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 14px;
    padding: 0;
    strong {
        color: #1b1b1b;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-9 {
    color: #1b1b1b;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 8px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-10 {
    color: #181818;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 5px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-11 {
    color: #fff;
    font-size: 21px;
    font-weight: 600;
    line-height: 24px;
    margin: 0 0 14px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-12 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-13 {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($sm) {
        font-size: 22px;
        line-height: 26px;
    }
    @include media($xs) {
        font-size: 20px;
        line-height: 25px;
    }
}

.title-14 {
    color: #181818;
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-15 {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($sm) {
        font-size: 15px;
    }
}

.title-16 {
    color: #000;
    font-size: 26px;
    font-weight: 400;
    float: left;
    line-height: 25px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($mb-pt) {
        font-size: 20px;
    }
}

.title-17 {
    color: #181818;
    font-size: 21px;
    font-weight: 500;
    line-height: 30px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-18 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($xs) {
        font-size: 22px;
        line-height: 28px;
    }
}

.title-19 {
    line-height: 27px;
    margin-bottom: 13px;
    padding: 3px 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-20 {
    color: #000;
    font-size: 13px;
    font-weight: 600;
    line-height: 22px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-21 {
    color: #000;
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    padding: 6px 0 11px;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($mb-pt) {
        font-size: 20px;
        line-height: 28px;
    }
}

.title-22 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    line-height: 42px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($mb-ls) {
        font-size: 24px;
        line-height: 32px;
    }
}

.title-23 {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    span {
        color: #8a8a8a;
    }
}

.title-24 {
    color: #222222;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    padding: 0;
    text-transform: uppercase;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-25 {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 29px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-26 {
    color: #000;
    font-size: 15px;
    font-weight: 500;
    line-height: 26px;
    padding: 0;
    text-transform: uppercase;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-27 {
    color: #000;
    font-size: 17px;
    font-weight: 500;
    line-height: 26px;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    @include media($xs) {
        font-size: 16px;
    }
}

.title-28 {
    color: #000;
    font: 500 13px/25px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-29 {
    color: #fff;
    font: 600 17px/25px $main-font;
    padding: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
}

.title-num {
    border: 1px solid #dbdbdb;
    border-radius: 50%;
    color: $accent-color;
    display: inline-block;
    float: left;
    font-size: 12px;
    line-height: 31px;
    width: 33px;
    height: 33px;
    text-align: center;
    margin: -3px 11px -3px 0;
}

.dropcap {
    display: inline-block;
    width: 36px;
    height: 36px;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
    float: left;
    padding: 7px;
    margin: 6px 12px 0 0;
    text-align: center;
    text-transform: uppercase;
}

.dropcap-tp-1 {
    @extend .dropcap;
    border: 1px solid #ececec;
    color: #000;
}
.dropcap-tp-2 {
    @extend .dropcap;
    background-color: $accent-color;
    color: #fff;
}
.dropcap-tp-3 {
    @extend .dropcap;
    background-color: #16171a;
    color: #fff;
}


/* Base for label styling */

.radio[type="radio"]:not(:checked),
.radio[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

.radio[type="radio"]:not(:checked) + label,
.radio[type="radio"]:checked + label {
    position: relative;
    padding-left: 23px;
    cursor: pointer;
}

label.rd-label {
    color: #626465;
    font-family: $content-font;
}

/* radio aspect */

.radio[type="radio"]:not(:checked) + label:before,
.radio[type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 13px;
    height: 13px;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 50%;
    transition: all 500ms;
}


/* checked mark aspect */

.radio[type="radio"]:not(:checked) + label:after,
.radio[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 5px;
    width: 7px;
    height: 7px;
    background: #000;
    border-radius: 50%;
    transition: all 300ms;
}


/* checked mark aspect changes */

.radio[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

.radio[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}


/* disabled radio */

.radio[type="radio"]:disabled:not(:checked) + label:before,
.radio[type="radio"]:disabled:checked + label:before {
    border-color: #bbb;
    background-color: #ddd;
}

.radio[type="radio"]:disabled:checked + label:after {
    background: #999;
}

.radio[type="radio"]:disabled + label {
    background: #aaa;
}


/* accessibility */

.radio[type="radio"]:checked:focus + label:before,
.radio[type="radio"]:not(:checked):focus + label:before {
    border-color: #919191;
}


/* hover style just for information */

label.rd-label:hover:before {
    border-color: #919191 !important;
}


/* Base for label styling */

.checkbox[type="checkbox"]:not(:checked),
.checkbox[type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.checkbox[type="checkbox"]:not(:checked) + label,
.checkbox[type="checkbox"]:checked + label {
    position: relative;
    padding-left: 23px;
    cursor: pointer;
}

label.cb-label {
    color: #626465;
    font-family: $content-font;
}

/* checkbox aspect */

.checkbox[type="checkbox"]:not(:checked) + label:before,
.checkbox[type="checkbox"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 3px;
    width: 12px;
    height: 12px;
    background: #fff;
    border: 1px solid #b2b2b2;
    border-radius: 3px;
    transition: all 500ms;
}


/* checked mark aspect */

.checkbox[type="checkbox"]:not(:checked) + label:after,
.checkbox[type="checkbox"]:checked + label:after {
    content: '✔';
    color: $accent-color;
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    font-size: 17px;
    line-height: 12px;
    text-align: center;
    transition: all 300ms;
}


/* checked mark aspect changes */

.checkbox[type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

.checkbox[type="checkbox"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}


/* disabled checkbox */

.checkbox[type="checkbox"]:disabled:not(:checked) + label:before,
.checkbox[type="checkbox"]:disabled:checked + label:before {
    border-color: #bbb;
    background-color: #ddd;
}

.checkbox[type="checkbox"]:disabled:checked + label:after {
    background: #999;
}

.checkbox[type="checkbox"]:disabled + label {
    background: #aaa;
}


/* accessibility */

.checkbox[type="checkbox"]:checked:focus + label:before,
.checkbox[type="checkbox"]:not(:checked):focus + label:before {
    border-color: #919191;
}


/* hover style just for information */

label.cb-label:hover:before {
    border-color: #919191 !important;
}


/* Base for label styling */

.radio-tp-2[type="radio"]:not(:checked),
.radio-tp-2[type="radio"]:checked {
    position: absolute;
    left: -9999px;
}

.radio-tp-2[type="radio"]:not(:checked) + label,
.radio-tp-2[type="radio"]:checked + label {
    display: block;
    position: relative;
    padding-left: 23px;
    cursor: pointer;
}

label.rd-label-tp-2 {
    color: #626465;
    font-family: $content-font;
}

/* radio aspect */

.radio-tp-2[type="radio"]:not(:checked) + label:before,
.radio-tp-2[type="radio"]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 5px;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 1px solid #aaaaaa;
    border-radius: 50%;
    transition: all 500ms;
}


/* checked mark aspect */
.radio-tp-2[type="radio"]:not(:checked) + label:after,
.radio-tp-2[type="radio"]:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 10px;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    transition: all 300ms;
}

/* checked radio aspect */
.radio-tp-2[type="radio"]:checked + label:before {
    background: #a5a5ab;
}


/* checked mark aspect changes */

.radio-tp-2[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}

.radio-tp-2[type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
}


/* disabled radio */

.radio-tp-2[type="radio"]:disabled:not(:checked) + label:before,
.radio-tp-2[type="radio"]:disabled:checked + label:before {
    border-color: #bbb;
    background-color: #ddd;
}

.radio-tp-2[type="radio"]:disabled:checked + label:after {
    background: #999;
}

.radio-tp-2[type="radio"]:disabled + label {
    background: #aaa;
}


/* accessibility */

.radio-tp-2[type="radio"]:checked:focus + label:before,
.radio-tp-2[type="radio"]:not(:checked):focus + label:before {
    border-color: #919191;
}


/* hover style just for information */

label.rd-label-tp-2:hover:before {
    border-color: #919191 !important;
}


.accordion {
    margin-bottom: 30px;
    dt {
        margin-bottom: 9px;
        &.open {
            .fa {
                color: $accent-color;
                transform: rotate(90deg);
            }
        }
        a {
            background-color: #f7f7f7;
            border-radius: 3px;
            color: #000;
            display: block;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            // height: 46px;
            padding: 12px 12px 12px 34px;
            text-decoration: none;
            position: relative;
        }
        .fa {
            font-size: 15px;
            line-height: 46px;
            position: absolute;
            left: 12px;
            top: 0;
        }
    }
    dd {
        color: #6f6f6f;
        font-size: 14px;
        line-height: 24px;
        padding: 12px 0 20px 34px;
    }
}

.gallery {
    margin-bottom: 30px;
    .inner {
        @extend .clearfix;
        margin: -3px;
    }
    .gallery-item {
        display: block;
        padding: 3px;
        .img {
            cursor: pointer;
            position: relative;
            &:before,
            &:after {
                background-color: #fff;
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                height: 1px;
                width: 23px;
                opacity: 0;
                margin: 0 0 0 -11px;
                z-index: 2;
            }
            &:before {
                width: 1px;
                height: 23px;
                margin: -11px 0 0 0;
            }
            &:hover {
                &:before,
                a:before,
                &:after {
                    opacity: 1;
                }
            }
            img {
                width: 100%;
            }
        }
        a {
            display: block;
            position: relative;
            &:before {
                background-color: rgba(22,23,26,.15);
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                z-index: 1;
            }
        }
    }
}

.tabs {
    .tabs-list {
        margin-bottom: 17px;
        .tab {
            background-color: #fff;
            border: 1px solid #eaeaea;
            border-radius: 2px;
            color: #000;
            cursor: pointer;
            font-size: 12px;
            font-weight: 500;
            line-height: 21px;
            float: left;
            margin-right: 3px;
            padding: 9px;
            min-width: 100px;
            text-align: center;
            &.active {
                background-color: #f7f7f7;
                border-color: #f7f7f7;
                color: $accent-color;
            }
        }
    }
    .tab-content {
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
}

.tables {
    color: #6f6f6f;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    th {
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
        color: #000;
        font-size: 13px;
        font-weight: 500;
        padding: 10px;
    }
    td {
        border: 1px solid #f5f5f5;
        background-color: #fff;
        font: 14px/24px $content-font;
        padding: 10px;
    }
}

.input-tp-1 {
    border: 1px solid #ebebeb;
    border-radius: 2px;
    color: #818181;
    font: 12px/26px $content-font;
    padding: 6px 12px;
    width: 100%;
}

.label-tp-1 {
    display: inline-block;
    color: #000;
    font-size: 11px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.textarea-tp-1 {
    border: 1px solid #ebebeb;
    border-radius: 2px;
    color: #818181;
    font: 12px/26px $content-font;
    padding: 6px 12px;
    width: 100%;
    height: 74px;
    resize: none;
}
