@charset "UTF-8";
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/poppins/poppins-v1-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Light"), local("Poppins-Light"), url("../fonts/poppins/poppins-v1-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v1-latin-300.woff2") format("woff2"), url("../fonts/poppins/poppins-v1-latin-300.woff") format("woff"), url("../fonts/poppins/poppins-v1-latin-300.ttf") format("truetype"), url("../fonts/poppins/poppins-v1-latin-300.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/poppins/poppins-v1-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/poppins/poppins-v1-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v1-latin-regular.woff2") format("woff2"), url("../fonts/poppins/poppins-v1-latin-regular.woff") format("woff"), url("../fonts/poppins/poppins-v1-latin-regular.ttf") format("truetype"), url("../fonts/poppins/poppins-v1-latin-regular.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/poppins/poppins-v1-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Medium"), local("Poppins-Medium"), url("../fonts/poppins/poppins-v1-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v1-latin-500.woff2") format("woff2"), url("../fonts/poppins/poppins-v1-latin-500.woff") format("woff"), url("../fonts/poppins/poppins-v1-latin-500.ttf") format("truetype"), url("../fonts/poppins/poppins-v1-latin-500.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/poppins/poppins-v1-latin-600.eot");
  /* IE9 Compat Modes */
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/poppins/poppins-v1-latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v1-latin-600.woff2") format("woff2"), url("../fonts/poppins/poppins-v1-latin-600.woff") format("woff"), url("../fonts/poppins/poppins-v1-latin-600.ttf") format("truetype"), url("../fonts/poppins/poppins-v1-latin-600.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/poppins/poppins-v1-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Poppins Bold"), local("Poppins-Bold"), url("../fonts/poppins/poppins-v1-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins/poppins-v1-latin-700.woff2") format("woff2"), url("../fonts/poppins/poppins-v1-latin-700.woff") format("woff"), url("../fonts/poppins/poppins-v1-latin-700.ttf") format("truetype"), url("../fonts/poppins/poppins-v1-latin-700.svg#Poppins") format("svg");
  /* Legacy iOS */ }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
/* line 6, ./app/vendor/reset-css/_reset.scss */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
/* line 27, ./app/vendor/reset-css/_reset.scss */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

/* line 31, ./app/vendor/reset-css/_reset.scss */
body {
  line-height: 1; }

/* line 34, ./app/vendor/reset-css/_reset.scss */
ol, ul {
  list-style: none; }

/* line 37, ./app/vendor/reset-css/_reset.scss */
blockquote, q {
  quotes: none; }
  /* line 39, ./app/vendor/reset-css/_reset.scss */
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none; }

/* line 44, ./app/vendor/reset-css/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 4, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_box-sizing.scss */
html {
  box-sizing: border-box; }

/* line 9, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit; }

/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.full {
  float: left;
  display: block;
  margin-right: 0%;
  width: 100%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .full:last-child {
    margin-right: 0; }

/* line 5, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.half {
  float: left;
  display: block;
  margin-right: 0%;
  width: 50%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .half:last-child {
    margin-right: 0; }
  @media screen and (max-width: 479px) {
    /* line 5, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .half {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .half:last-child {
        margin-right: 0; } }

/* line 12, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.one-third {
  float: left;
  display: block;
  margin-right: 0%;
  width: 33.33333%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .one-third:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 12, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-third {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-third:last-child {
        margin-right: 0; } }

/* line 19, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.two-thirds {
  float: left;
  display: block;
  margin-right: 0%;
  width: 66.66667%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .two-thirds:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 19, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .two-thirds {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .two-thirds:last-child {
        margin-right: 0; } }

/* line 26, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.one-quarter {
  float: left;
  display: block;
  margin-right: 0%;
  width: 25%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .one-quarter:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 26, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-quarter {
      float: left;
      display: block;
      margin-right: 0%;
      width: 50%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-quarter:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 639px) {
    /* line 26, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-quarter {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-quarter:last-child {
        margin-right: 0; } }

/* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.one-sixth {
  float: left;
  display: block;
  margin-right: 0%;
  width: 16.66667%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .one-sixth:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-sixth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.33333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-sixth:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 767px) {
    /* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-sixth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-sixth:last-child {
        margin-right: 0; } }

/* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.three-quarters {
  float: left;
  display: block;
  margin-right: 0%;
  width: 75%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .three-quarters:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .three-quarters {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .three-quarters:last-child {
        margin-right: 0; } }

/* line 53, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.one-fifth {
  float: left;
  display: block;
  margin-right: 0%;
  width: 20%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .one-fifth:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 53, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .one-fifth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .one-fifth:last-child {
        margin-right: 0; } }

/* line 60, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.four-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 80%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .four-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 60, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .four-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .four-fifths:last-child {
        margin-right: 0; } }

/* line 67, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.two-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 40%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .two-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 67, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .two-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .two-fifths:last-child {
        margin-right: 0; } }

/* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.three-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 60%; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .three-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .three-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .three-fifths:last-child {
        margin-right: 0; } }

/* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-half {
  float: left;
  display: block;
  margin-right: 0%;
  width: 50%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-half:last-child {
    margin-right: 0; }
  @media screen and (max-width: 767px) {
    /* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-half {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-half:last-child {
        margin-right: 0; } }

/* line 89, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-one-third {
  float: left;
  display: block;
  margin-right: 0%;
  width: 33.33333%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-one-third:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 89, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-third {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-third:last-child {
        margin-right: 0; } }

/* line 97, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-two-thirds {
  float: left;
  display: block;
  margin-right: 0%;
  width: 66.66667%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-two-thirds:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 97, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-two-thirds {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-two-thirds:last-child {
        margin-right: 0; } }

/* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-one-quarter {
  float: left;
  display: block;
  margin-right: 0%;
  width: 25%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-one-quarter:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-quarter {
      float: left;
      display: block;
      margin-right: 0%;
      width: 50%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-quarter:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 639px) {
    /* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-quarter {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-quarter:last-child {
        margin-right: 0; } }

/* line 116, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-one-sixth {
  float: left;
  display: block;
  margin-right: 0%;
  width: 16.66667%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-one-sixth:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 116, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-sixth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.33333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-sixth:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 767px) {
    /* line 116, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-sixth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-sixth:last-child {
        margin-right: 0; } }

/* line 127, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-three-quarters {
  float: left;
  display: block;
  margin-right: 0%;
  width: 75%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-three-quarters:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 127, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-three-quarters {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-three-quarters:last-child {
        margin-right: 0; } }

/* line 135, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-one-fifth {
  float: left;
  display: block;
  margin-right: 0%;
  width: 20%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-one-fifth:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 135, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-one-fifth {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-one-fifth:last-child {
        margin-right: 0; } }

/* line 143, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-two-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 40%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-two-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 143, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-two-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-two-fifths:last-child {
        margin-right: 0; } }

/* line 151, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-three-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 60%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-three-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 151, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-three-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-three-fifths:last-child {
        margin-right: 0; } }

/* line 159, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-four-fifths {
  float: left;
  display: block;
  margin-right: 0%;
  width: 80%;
  padding: 0 10px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .col-four-fifths:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 159, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .col-four-fifths {
      float: left;
      display: block;
      margin-right: 0%;
      width: 100%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .col-four-fifths:last-child {
        margin-right: 0; } }

/* line 167, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.container,
.container-fluid {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .container::after,
  .container-fluid::after {
    clear: both;
    content: "";
    display: table; }
  /* line 171, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .full-width,
  .container-fluid .full-width {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .container .full-width:last-child,
    .container-fluid .full-width:last-child {
      margin-right: 0; }
  /* line 175, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .half,
  .container-fluid .half {
    padding: 0 15px; }
  /* line 178, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .one-third,
  .container-fluid .one-third {
    padding: 0 15px; }
  /* line 181, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .two-thirds,
  .container-fluid .two-thirds {
    padding: 0 15px; }
  /* line 184, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .one-quarter,
  .container-fluid .one-quarter {
    padding: 0 15px; }
  /* line 187, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .three-quarters,
  .container-fluid .three-quarters {
    padding: 0 15px; }
  /* line 190, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .one-fifth,
  .container-fluid .one-fifth {
    padding: 0 15px; }
  /* line 193, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .four-fifths,
  .container-fluid .four-fifths {
    padding: 0 15px; }
  /* line 196, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .two-fifths,
  .container-fluid .two-fifths {
    padding: 0 15px; }
  /* line 199, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container .three-fifths,
  .container-fluid .three-fifths {
    padding: 0 15px; }

@media screen and (max-width: 1229px) {
  /* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container {
    max-width: 1020px; } }

@media screen and (max-width: 1077px) {
  /* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .container {
    max-width: 710px; } }

/* line 213, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.container-fluid {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .container-fluid::after {
    clear: both;
    content: "";
    display: table; }

/* line 217, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.row {
  display: block;
  margin-left: -15px;
  margin-right: -15px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .row::after {
    clear: both;
    content: "";
    display: table; }

/* line 223, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.col-row {
  display: block;
  margin-left: -10px;
  margin-right: -10px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .col-row::after {
    clear: both;
    content: "";
    display: table; }

/* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
.section {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 45px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .section::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .section {
      max-width: 1020px; } }
  @media screen and (max-width: 1077px) {
    /* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
    .section {
      max-width: 710px; } }
  /* line 239, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section.post-section {
    padding-top: 40px; }
  /* line 242, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section .content {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .section .content:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 242, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
      .section .content {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .section .content:last-child {
          margin-right: 0; } }
  /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section .content-sm {
    float: left;
    display: block;
    margin-right: 0%;
    width: 51.28083%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .section .content-sm:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
      .section .content-sm {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .section .content-sm:last-child {
          margin-right: 0; } }
  /* line 256, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section .side-bar {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .section .side-bar:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 256, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
      .section .side-bar {
        display: none; } }
  /* line 263, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section .side-bar-md {
    float: left;
    display: block;
    margin-right: 0%;
    width: 26.49583%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .section .side-bar-md:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 263, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
      .section .side-bar-md {
        display: none; } }
  /* line 270, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
  .section .side-bar-sm {
    float: left;
    display: block;
    margin-right: 0%;
    width: 22.22333%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .section .side-bar-sm:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 270, D:/aartyomenko/Git/spectr-html/app/scss/_grid.scss */
      .section .side-bar-sm {
        display: none; } }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_loader.scss */
.page-loader {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  text-align: center;
  z-index: 99999; }

/* line 13, D:/aartyomenko/Git/spectr-html/app/scss/_loader.scss */
.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  perspective: 1200; }

/* line 23, D:/aartyomenko/Git/spectr-html/app/scss/_loader.scss */
.flipper {
  position: relative;
  display: block;
  height: inherit;
  width: inherit;
  animation: flip 1.2s infinite ease-in-out;
  transform-style: preserve-3d; }

/* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_loader.scss */
.front,
.back {
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  display: block;
  font: 500 17px/40px "Poppins";
  background-color: #000;
  height: 100%;
  width: 100%;
  text-align: center; }

/* line 47, D:/aartyomenko/Git/spectr-html/app/scss/_loader.scss */
.back {
  background-color: #ebebeb;
  z-index: 800;
  transform: rotateY(-180deg); }

@keyframes flip {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

/* line 8, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.clearfix:after, .js-sidebar:after, .gallery .inner:after, .post:after, .rate-tp-2 ul:after, .pst-block-head:after, .pst-block-main:after, .pst-box-head:after, .pst-box-main .pst-box-inner:after, .vpst-block-head:after, .vpst-block-main:after, .trpst-block-head:after, .filters-list-3:after, .filters-blc-1:after, .filters-blc-2:after, .post-sharing-tp-1:after, .post-sharing-tp-2:after, .tags-list:after, .post-tags-list:after, .weather-days-list:after, .page-nav:after, .page-title:after, .breadcrumbs:after, .small-gallery:after, .half-gallery:after, .medium-gallery:after, .large-gallery:after, .post-next-prev:after, .rating-title:after, .rw-summary:after, .members-items:after, .member-filter:after, .forum-titles:after, .bbp-forum:after, .shop-products-items:after, .shop-product-item:after, .main-nav:after, .main-nav-list:after,
.sf-menu:after, .ft-list-1 li:after,
.ft-list-2 li:after, .ft-social-list:after, .ft-menu-list:after, .inst-list:after, .sp-popup .btns:after, .sp-popup .social .pp-social-list:after, .main-posts-1 .mp-section:after, .main-posts-2 .mp-section:after, .main-posts-3 .mp-section:after, .main-posts-4 .twitter-block .tb-twitts ul:after, .main-posts-5 .mp-section:after, .main-slider-1 .post-tp-1 figure a:after, .main-slider-1 .post-tpv-1 figure a:after, .main-slider-1 .post-tpv-2 figure a:after, .main-slider-1 .post-tp-2 figure a:after, .main-slider-1 .post-tp-12 figure a:after, .main-slider-1 .post-tp-14 figure a:after {
  content: "";
  display: table;
  clear: both; }

/* line 14, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.swiper-wrapper {
  box-sizing: border-box; }

/* line 18, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.adaptive {
  display: block;
  max-width: 100%;
  height: auto; }

/* line 24, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.table {
  display: table;
  width: 100%;
  height: 100%; }

/* line 30, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.cell {
  display: table-cell;
  vertical-align: middle; }

/* line 35, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.flex {
  display: flex;
  align-items: center; }

/* line 40, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.lsb-list a,
.mh-search .st-btn-1,
.ft-social-list a,
.tb-social-list a,
.htp-2-social-list a,
.htp-3-social-list a,
.pp-social-list a,
.sp-popup .btns a,
.social-tp-2 a,
.author-social a,
.post-tp-1 figure, .post-tpv-1 figure, .post-tpv-2 figure, .post-tp-2 figure, .post-tp-12 figure, .post-tp-14 figure,
.owl-page span,
.sbr-dots span,
.filters-list a,
.ptp-4-overlay,
.ptp-5-overlay,
.ptp-8-overlay,
.ptp-13-overlay,
.ptp-23-overlay,
.ptp-24-overlay,
.ptp-25-overlay,
.ptp-27-overlay,
.ptp-30-overlay,
.ptp-31-overlay,
.filters-list-1 a, .filters-list-2 a,
.filters-list-2 a,
.filters-list-3 a,
.sbsb-btn button,
.src-btn button,
.btn-1,
.btn-2,
.btn-3,
.btn-4,
.btn-5,
.btn-6,
.btn-7,
.btn-8,
.page-nav .pn-item,
.weather-days-list a,
.nav-arrow,
.slide-count,
.info-tp-1 a,
.post-tp-26 figure:before,
.thumbs-img figure:before,
.post-next-prev a,
.post-sharing-tp-1 a, .post-sharing-tp-2 a,
.comment-list .reply,
.gallery-item .img:before,
.gallery-item .img a:before,
.gallery-item .img:after,
.members-item:before,
.members-photo:before,
.members-photo:after,
.shop-products-item-overlay,
.popup-close-ic,
.aside-menu-close-ic,
.mb-menu-close-ic,
.sticky-header,
.left-sticky-bar {
  transition: all 500ms; }

/* line 102, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.title-3,
.title-5,
.title-6,
.title-10,
.trpst-block-foot a,
.pst-block-foot a,
.all-sb a,
.filters-drop a,
.top-rate:before,
.accordion dt .fa {
  transition: all 300ms; }

/* line 115, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.left-sticky-bar {
  transition-timing-function: ease-out; }

/* line 119, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-ic-1, .arr-left-light-ic, .arr-right-light-ic, .arr-up-light-ic, .arr-down-light-ic, .arr-left-dark-ic, .arr-right-dark-ic, .arr-up-dark-ic, .arr-down-dark-ic {
  border: 1px solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 25px;
  height: 25px;
  overflow: hidden;
  text-align: center;
  line-height: 23px;
  text-decoration: none;
  vertical-align: middle; }
  /* line 130, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-ic-1 i, .arr-left-light-ic i, .arr-right-light-ic i, .arr-up-light-ic i, .arr-down-light-ic i, .arr-left-dark-ic i, .arr-right-dark-ic i, .arr-up-dark-ic i, .arr-down-dark-ic i {
    display: inline-block;
    vertical-align: middle; }

/* line 136, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-ic-2, .arr-left-light-lg-ic, .arr-left-light-sm-ic {
  border: 2px solid #fff;
  border-radius: 50%;
  display: inline-block;
  width: 27px;
  height: 27px;
  overflow: hidden;
  text-align: center;
  line-height: 23px;
  text-decoration: none;
  vertical-align: middle; }
  /* line 147, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-ic-2 i, .arr-left-light-lg-ic i, .arr-left-light-sm-ic i {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 2px; }

/* line 154, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-ic-3 {
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  display: inline-block;
  width: 24px;
  height: 24px;
  overflow: hidden;
  text-align: center;
  line-height: 22px;
  text-decoration: none;
  vertical-align: middle; }
  /* line 165, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-ic-3 i {
    color: #474747;
    display: inline-block;
    vertical-align: middle; }

/* line 174, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-left-light-ic i {
  background-image: url(../img/sprite-retina.png);
  background-position: -34px 0px;
  width: 9px;
  height: 5px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 174, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .arr-left-light-ic i {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 181, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-right-light-ic i {
  background-image: url(../img/sprite-retina.png);
  background-position: -52px 0px;
  width: 9px;
  height: 5px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 181, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .arr-right-light-ic i {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 188, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-up-light-ic i {
  background-image: url(../img/sprite-retina.png);
  background-position: -10px 0px;
  width: 5px;
  height: 9px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 188, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .arr-up-light-ic i {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 195, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-down-light-ic i {
  background-image: url(../img/sprite-retina.png);
  background-position: -20px 0px;
  width: 5px;
  height: 9px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 195, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .arr-down-light-ic i {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 200, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-left-dark-ic {
  border-color: #d1d1d1; }
  /* line 203, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-left-dark-ic i {
    background-image: url(../img/sprite-retina.png);
    background-position: -25px 0px;
    width: 9px;
    height: 5px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* line 203, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
      .arr-left-dark-ic i {
        background-image: url(../img/sprite-retina@2x.png);
        background-size: 351px 24px; } }

/* line 208, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-right-dark-ic {
  border-color: #d1d1d1; }
  /* line 211, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-right-dark-ic i {
    background-image: url(../img/sprite-retina.png);
    background-position: -43px 0px;
    width: 9px;
    height: 5px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* line 211, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
      .arr-right-dark-ic i {
        background-image: url(../img/sprite-retina@2x.png);
        background-size: 351px 24px; } }

/* line 216, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-up-dark-ic {
  border-color: #d1d1d1; }
  /* line 219, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-up-dark-ic i {
    background-image: url(../img/sprite-retina.png);
    background-position: -5px 0px;
    width: 5px;
    height: 9px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* line 219, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
      .arr-up-dark-ic i {
        background-image: url(../img/sprite-retina@2x.png);
        background-size: 351px 24px; } }

/* line 224, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-down-dark-ic {
  border-color: #d1d1d1; }
  /* line 227, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-down-dark-ic i {
    background-image: url(../img/sprite-retina.png);
    background-position: 0px 0px;
    width: 5px;
    height: 9px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* line 227, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
      .arr-down-dark-ic i {
        background-image: url(../img/sprite-retina@2x.png);
        background-size: 351px 24px; } }

/* line 232, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-left-light-lg-ic {
  width: 35px;
  height: 35px;
  line-height: 31px; }
  /* line 237, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .arr-left-light-lg-ic i {
    background-image: url(../img/sprite-retina.png);
    background-position: -80px 0px;
    width: 11px;
    height: 12px; }
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* line 237, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
      .arr-left-light-lg-ic i {
        background-image: url(../img/sprite-retina@2x.png);
        background-size: 351px 24px; } }

/* line 244, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.arr-left-light-sm-ic i {
  background-image: url(../img/sprite-retina.png);
  background-position: -15px 0px;
  width: 5px;
  height: 7px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 244, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .arr-left-light-sm-ic i {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.list-ic-1 {
  background-image: url(../img/sprite-retina.png);
  background-position: -171px 0px;
  width: 16px;
  height: 14px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .list-ic-1 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 255, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.list-ic-2 {
  background-image: url(../img/sprite-retina.png);
  background-position: -91px 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 255, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .list-ic-2 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 261, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.list-ic-3 {
  background-image: url(../img/sprite-retina.png);
  background-position: -139px 0px;
  width: 16px;
  height: 12px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 261, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .list-ic-3 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 267, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.list-ic-4 {
  background-image: url(../img/sprite-retina.png);
  background-position: -107px 0px;
  width: 16px;
  height: 14px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 267, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .list-ic-4 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 273, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.cart-ic-light {
  background-image: url(../img/sprite-retina.png);
  background-position: -155px 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 273, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .cart-ic-light {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 279, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.cart-ic-dark {
  background-image: url(../img/sprite-retina.png);
  background-position: -123px 0px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 279, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .cart-ic-dark {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 285, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.remove-ic-1 {
  background-image: url(../img/sprite.png);
  background-position: -36px 0px;
  width: 9px;
  height: 9px;
  display: inline-block;
  vertical-align: middle; }

/* line 291, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.remove-ic-2 {
  background-image: url(../img/sprite.png);
  background-position: -45px 0px;
  width: 9px;
  height: 9px;
  display: inline-block;
  vertical-align: middle; }

/* line 297, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.check-ic-1 {
  background-image: url(../img/sprite.png);
  background-position: -54px 0px;
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle; }

/* line 303, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.paypal-ic {
  background-image: url(../img/sprite.png);
  background-position: -155px 0px;
  width: 39px;
  height: 25px;
  display: inline-block;
  vertical-align: middle; }

/* line 309, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.popup-close-ic {
  background-image: url(../img/sprite.png);
  background-position: -126px 0px;
  width: 29px;
  height: 29px;
  display: inline-block;
  vertical-align: middle; }

/* line 315, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.aside-menu-close-ic {
  background-image: url(../img/sprite.png);
  background-position: -106px 0px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle; }

/* line 321, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.popup-arr-left-ic {
  background-image: url(../img/sprite-retina.png);
  background-position: -61px 0px;
  width: 9px;
  height: 8px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 321, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .popup-arr-left-ic {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 327, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.mb-menu-close-ic {
  background-image: url(../img/sprite-retina.png);
  background-position: -187px 0px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 327, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
    .mb-menu-close-ic {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 333, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.lg-offset {
  margin-bottom: 45px; }

/* line 337, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.pull-left {
  float: left; }

/* line 341, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.pull-right {
  float: right; }

/* line 345, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.pull-center {
  float: none;
  margin-left: auto;
  margin-right: auto; }

/* line 351, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.align-left {
  float: left;
  margin: 6px 20px 15px 0; }

/* line 356, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.align-right {
  float: right;
  margin: 6px 0 15px 20px; }

/* line 361, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.align-center {
  margin: 0 0 15px; }
  /* line 363, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .align-center img {
    margin: 0 auto; }

/* line 368, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.block-center {
  margin-right: auto;
  margin-left: auto; }

/* line 373, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.text-left {
  text-align: left; }

/* line 377, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.text-right {
  text-align: right; }

/* line 381, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.text-center {
  text-align: center; }

/* line 385, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.fixed {
  position: fixed; }

/* line 389, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
video {
  width: 100% !important;
  height: auto !important; }

/* line 394, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.video-wrap {
  position: relative;
  padding-bottom: 54%;
  /* 16:9 56.25% */
  padding-top: 25px;
  height: 0; }
  /* line 399, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
  .video-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/* line 409, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.soundcloud iframe {
  display: block;
  max-height: 180px; }

/* line 415, D:/aartyomenko/Git/spectr-html/app/scss/_helper.scss */
.awesome-font, .mark-list li:before {
  font: normal normal normal 14px/1 'FontAwesome';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h1 {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 8, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h2 {
  color: #000;
  font-size: 23px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 22, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h4 {
  color: #000;
  font-size: 17px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 29, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h5 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
h6 {
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 13px 0;
  line-height: 1; }

/* line 44, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
strong {
  font-weight: 600; }

/* line 48, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
p {
  color: #6f6f6f;
  font: 15px/25px "Noto Sans";
  margin-bottom: 23px; }
  /* line 52, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p a {
    color: #fd3a13;
    text-decoration: none; }
  /* line 56, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p.p {
    font: 13px/23px "Noto Sans";
    margin: 0; }
  /* line 60, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p .orange {
    background-color: #fd3a13;
    color: #fff;
    line-height: 16px;
    display: inline-block;
    padding: 0 4px 2px; }
  /* line 67, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p .dark {
    background-color: #16171a;
    color: #fff;
    line-height: 16px;
    display: inline-block;
    padding: 0 4px 2px; }
  /* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p .light {
    background-color: #efefef;
    line-height: 16px;
    display: inline-block;
    padding: 0 4px 2px; }
  /* line 80, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  p .underline {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAABCAYAAAASC7TOAAAAFklEQVQIW2Ncv379fwY0EBgYyAgSAgBXKgQCHSg8kwAAAABJRU5ErkJggg==) repeat-x bottom;
    line-height: 16px;
    display: inline-block;
    padding: 0 4px 2px; }

/* line 88, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
hr {
  border: none;
  border-top: 1px solid #f2f2f2;
  margin: 15px 0 15px; }

/* line 94, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
blockquote {
  font: 18px/26px "Georgia";
  position: relative;
  padding: 3px 0 4px 16px;
  margin-bottom: 23px; }
  /* line 99, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  blockquote p {
    color: #313131;
    font: 18px/26px "Georgia";
    margin: 0;
    position: relative; }
  /* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  blockquote .author {
    color: #626465;
    display: inline-block;
    font: 12px/22px "Poppins"; }

/* line 112, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.bq-tp-1 {
  border-left: 1px solid #fd3a13; }

/* line 116, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.bq-tp-2 {
  padding: 3px 0 4px 27px; }
  /* line 118, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .bq-tp-2:before {
    content: '”';
    color: #e9e9e9;
    font-size: 100px;
    line-height: 1;
    position: absolute;
    left: 0;
    top: -6px; }

/* line 129, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.bq-tp-3 {
  background-color: #fcfcfc;
  padding: 33px 20px 34px 50px; }
  /* line 132, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .bq-tp-3:before {
    content: '“';
    color: #fd3a13;
    font-size: 40px;
    line-height: 1;
    position: absolute;
    left: 20px;
    top: 35px; }
  /* line 141, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .bq-tp-3 p {
    color: #6f6f6f;
    font: 16px/24px "Georgia";
    margin-bottom: 4px; }

/* line 148, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.list {
  color: #414141;
  font-size: 14px;
  margin-bottom: 25px; }
  /* line 152, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .list li {
    padding: 6px 0; }
  /* line 155, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .list i {
    margin-right: 10px; }

/* line 161, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.mark-list li {
  padding-left: 20px;
  position: relative; }
  /* line 164, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .mark-list li:before {
    color: #16171a;
    content: '\f00c';
    font-size: 11px;
    line-height: 35px;
    position: absolute;
    left: 0;
    top: 0; }

/* line 176, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.mark-list.mark-circle li {
  padding-left: 14px; }
  /* line 178, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .mark-list.mark-circle li:before {
    content: '\f111';
    font-size: 7px; }

/* line 186, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.num-list {
  list-style: decimal inside; }

/* line 190, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-blc-1 {
  padding: 0 20px;
  position: relative;
  margin: 20px -20px; }
  /* line 194, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-1:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    bottom: 4px; }
  /* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-1 h4 {
    padding: 0; }
  /* line 207, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-1 span {
    font-weight: 400; }

/* line 212, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-blc-2 {
  padding: 0 20px;
  position: relative;
  margin: 20px -20px; }
  /* line 216, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-2:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    top: 7px; }
  /* line 226, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-2 .title-blc-inner {
    border-bottom: 1px solid #f0f0f0;
    padding: 0 0 16px; }
    /* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-blc-2 .title-blc-inner p {
      color: #949494;
      font-size: 12px;
      line-height: 17px;
      margin: 7px 0 0; }
  /* line 236, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-2 h4 {
    padding: 0; }
  /* line 239, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-2 span {
    font-weight: 400; }

/* line 244, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-blc-3 {
  border-radius: 2px;
  border: 1px solid #ebebeb;
  padding: 7px 15px;
  text-align: center; }

/* line 251, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-blc-4 {
  background-color: #fafafa;
  border-radius: 2px;
  padding: 15px 25px;
  margin-bottom: 27px; }

/* line 258, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-blc-5 {
  overflow: hidden;
  margin-bottom: 19px; }
  /* line 261, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-blc-5 .title-27 {
    display: inline-block;
    position: relative; }
    /* line 264, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-blc-5 .title-27:before {
      background-color: #ebebeb;
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      width: 10000%;
      height: 1px;
      margin-left: 8px; }
    /* line 274, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-blc-5 .title-27 .check-ic-1 {
      margin: -2px 0 0 2px; }

/* line 280, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-1 {
  color: #fff;
  font: 600 30px/33px "Poppins";
  padding: 0; }
  /* line 284, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-1 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 639px) {
    /* line 280, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-1 {
      font-size: 18px;
      line-height: 19.8px; } }

/* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-2 {
  color: #fff;
  font: 600 22px/26px "Poppins";
  padding: 0; }
  /* line 298, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-2 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 1229px) {
    /* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-2 {
      font-size: 19.8px;
      line-height: 23.4px; } }
  @media screen and (max-width: 639px) {
    /* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-2 {
      font-size: 17px;
      line-height: 25px; } }

/* line 312, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-3 {
  color: #181818;
  font: 500 15px/21px "Poppins";
  padding: 0; }
  /* line 316, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-3 a {
    color: inherit;
    text-decoration: none; }

/* line 322, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-4 {
  color: #000;
  font-size: 17px;
  font-weight: 400;
  line-height: 25px;
  float: left;
  padding: 0; }
  /* line 329, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-4 a {
    color: inherit;
    text-decoration: none; }

/* line 335, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-5 {
  color: #181818;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  padding: 0; }
  /* line 341, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-5 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 1229px) {
    /* line 335, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-5 {
      font-size: 14.4px;
      line-height: 20.7px; } }

/* line 351, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-6 {
  color: #181818;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 0; }
  /* line 357, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-6 a {
    color: inherit;
    text-decoration: none; }

/* line 363, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-7 {
  color: #fff;
  font: 600 21px/27px "Poppins";
  padding: 0; }
  /* line 367, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-7 a {
    color: inherit;
    text-decoration: none; }

/* line 373, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-8 {
  color: #909090;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 14px;
  padding: 0; }
  /* line 379, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-8 strong {
    color: #1b1b1b; }
  /* line 382, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-8 a {
    color: inherit;
    text-decoration: none; }

/* line 388, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-9 {
  color: #1b1b1b;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 8px;
  padding: 0; }
  /* line 395, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-9 a {
    color: inherit;
    text-decoration: none; }

/* line 401, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-10 {
  color: #181818;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 5px;
  padding: 0; }
  /* line 408, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-10 a {
    color: inherit;
    text-decoration: none; }

/* line 414, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-11 {
  color: #fff;
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 14px;
  padding: 0; }
  /* line 421, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-11 a {
    color: inherit;
    text-decoration: none; }

/* line 427, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-12 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 0; }
  /* line 433, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-12 a {
    color: inherit;
    text-decoration: none; }

/* line 439, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-13 {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  padding: 0; }
  /* line 445, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-13 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 1077px) {
    /* line 439, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-13 {
      font-size: 22px;
      line-height: 26px; } }
  @media screen and (max-width: 767px) {
    /* line 439, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-13 {
      font-size: 20px;
      line-height: 25px; } }

/* line 459, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-14 {
  color: #181818;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  padding: 0; }
  /* line 465, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-14 a {
    color: inherit;
    text-decoration: none; }

/* line 471, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-15 {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  padding: 0; }
  /* line 477, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-15 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 1077px) {
    /* line 471, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-15 {
      font-size: 15px; } }

/* line 486, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-16 {
  color: #000;
  font-size: 26px;
  font-weight: 400;
  float: left;
  line-height: 25px;
  padding: 0; }
  /* line 493, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-16 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 479px) {
    /* line 486, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-16 {
      font-size: 20px; } }

/* line 502, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-17 {
  color: #181818;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  padding: 0; }
  /* line 508, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-17 a {
    color: inherit;
    text-decoration: none; }

/* line 514, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-18 {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  padding: 0; }
  /* line 520, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-18 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 767px) {
    /* line 514, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-18 {
      font-size: 22px;
      line-height: 28px; } }

/* line 530, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-19 {
  line-height: 27px;
  margin-bottom: 13px;
  padding: 3px 0; }
  /* line 534, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-19 a {
    color: inherit;
    text-decoration: none; }

/* line 540, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-20 {
  color: #000;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
  padding: 0; }
  /* line 546, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-20 a {
    color: inherit;
    text-decoration: none; }

/* line 552, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-21 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  padding: 6px 0 11px; }
  /* line 558, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-21 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 479px) {
    /* line 552, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-21 {
      font-size: 20px;
      line-height: 28px; } }

/* line 568, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-22 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  line-height: 42px;
  padding: 0; }
  /* line 574, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-22 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 639px) {
    /* line 568, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-22 {
      font-size: 24px;
      line-height: 32px; } }

/* line 584, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-23 {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  padding: 0; }
  /* line 590, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-23 a {
    color: inherit;
    text-decoration: none; }
  /* line 594, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-23 span {
    color: #8a8a8a; }

/* line 599, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-24 {
  color: #222222;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  padding: 0;
  text-transform: uppercase; }
  /* line 606, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-24 a {
    color: inherit;
    text-decoration: none; }

/* line 612, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-25 {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 29px;
  padding: 0; }
  /* line 618, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-25 a {
    color: inherit;
    text-decoration: none; }

/* line 624, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-26 {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  padding: 0;
  text-transform: uppercase; }
  /* line 631, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-26 a {
    color: inherit;
    text-decoration: none; }

/* line 637, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-27 {
  color: #000;
  font-size: 17px;
  font-weight: 500;
  line-height: 26px;
  padding: 0; }
  /* line 643, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-27 a {
    color: inherit;
    text-decoration: none; }
  @media screen and (max-width: 767px) {
    /* line 637, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .title-27 {
      font-size: 16px; } }

/* line 652, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-28 {
  color: #000;
  font: 500 13px/25px "Poppins";
  padding: 0; }
  /* line 656, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-28 a {
    color: inherit;
    text-decoration: none; }

/* line 662, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-29 {
  color: #fff;
  font: 600 17px/25px "Poppins";
  padding: 0; }
  /* line 666, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .title-29 a {
    color: inherit;
    text-decoration: none; }

/* line 672, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.title-num {
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  color: #fd3a13;
  display: inline-block;
  float: left;
  font-size: 12px;
  line-height: 31px;
  width: 33px;
  height: 33px;
  text-align: center;
  margin: -3px 11px -3px 0; }

/* line 686, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.dropcap, .dropcap-tp-1, .dropcap-tp-2, .dropcap-tp-3 {
  display: inline-block;
  width: 36px;
  height: 36px;
  font-size: 17px;
  font-weight: 500;
  line-height: 22px;
  float: left;
  padding: 7px;
  margin: 6px 12px 0 0;
  text-align: center;
  text-transform: uppercase; }

/* line 700, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.dropcap-tp-1 {
  border: 1px solid #ececec;
  color: #000; }

/* line 705, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.dropcap-tp-2 {
  background-color: #fd3a13;
  color: #fff; }

/* line 710, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.dropcap-tp-3 {
  background-color: #16171a;
  color: #fff; }

/* Base for label styling */
/* line 719, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:not(:checked),
.radio[type="radio"]:checked {
  position: absolute;
  left: -9999px; }

/* line 725, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:not(:checked) + label,
.radio[type="radio"]:checked + label {
  position: relative;
  padding-left: 23px;
  cursor: pointer; }

/* line 732, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.rd-label {
  color: #626465;
  font-family: "Noto Sans"; }

/* radio aspect */
/* line 739, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:not(:checked) + label:before,
.radio[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 13px;
  height: 13px;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 50%;
  transition: all 500ms; }

/* checked mark aspect */
/* line 756, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:not(:checked) + label:after,
.radio[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 5px;
  width: 7px;
  height: 7px;
  background: #000;
  border-radius: 50%;
  transition: all 300ms; }

/* checked mark aspect changes */
/* line 772, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

/* line 777, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled radio */
/* line 785, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:disabled:not(:checked) + label:before,
.radio[type="radio"]:disabled:checked + label:before {
  border-color: #bbb;
  background-color: #ddd; }

/* line 791, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:disabled:checked + label:after {
  background: #999; }

/* line 795, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:disabled + label {
  background: #aaa; }

/* accessibility */
/* line 802, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio[type="radio"]:checked:focus + label:before,
.radio[type="radio"]:not(:checked):focus + label:before {
  border-color: #919191; }

/* hover style just for information */
/* line 810, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.rd-label:hover:before {
  border-color: #919191 !important; }

/* Base for label styling */
/* line 817, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:not(:checked),
.checkbox[type="checkbox"]:checked {
  position: absolute;
  left: -9999px; }

/* line 823, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:not(:checked) + label,
.checkbox[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 23px;
  cursor: pointer; }

/* line 830, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.cb-label {
  color: #626465;
  font-family: "Noto Sans"; }

/* checkbox aspect */
/* line 837, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:not(:checked) + label:before,
.checkbox[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 3px;
  width: 12px;
  height: 12px;
  background: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 3px;
  transition: all 500ms; }

/* checked mark aspect */
/* line 854, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:not(:checked) + label:after,
.checkbox[type="checkbox"]:checked + label:after {
  content: '✔';
  color: #fd3a13;
  position: absolute;
  left: 0;
  top: 0;
  width: 12px;
  height: 12px;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  transition: all 300ms; }

/* checked mark aspect changes */
/* line 872, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

/* line 877, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled checkbox */
/* line 885, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:disabled:not(:checked) + label:before,
.checkbox[type="checkbox"]:disabled:checked + label:before {
  border-color: #bbb;
  background-color: #ddd; }

/* line 891, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:disabled:checked + label:after {
  background: #999; }

/* line 895, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:disabled + label {
  background: #aaa; }

/* accessibility */
/* line 902, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.checkbox[type="checkbox"]:checked:focus + label:before,
.checkbox[type="checkbox"]:not(:checked):focus + label:before {
  border-color: #919191; }

/* hover style just for information */
/* line 910, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.cb-label:hover:before {
  border-color: #919191 !important; }

/* Base for label styling */
/* line 917, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:not(:checked),
.radio-tp-2[type="radio"]:checked {
  position: absolute;
  left: -9999px; }

/* line 923, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:not(:checked) + label,
.radio-tp-2[type="radio"]:checked + label {
  display: block;
  position: relative;
  padding-left: 23px;
  cursor: pointer; }

/* line 931, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.rd-label-tp-2 {
  color: #626465;
  font-family: "Noto Sans"; }

/* radio aspect */
/* line 938, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:not(:checked) + label:before,
.radio-tp-2[type="radio"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 5px;
  width: 14px;
  height: 14px;
  background: #fff;
  border: 1px solid #aaaaaa;
  border-radius: 50%;
  transition: all 500ms; }

/* checked mark aspect */
/* line 954, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:not(:checked) + label:after,
.radio-tp-2[type="radio"]:checked + label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 10px;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  transition: all 300ms; }

/* checked radio aspect */
/* line 968, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:checked + label:before {
  background: #a5a5ab; }

/* checked mark aspect changes */
/* line 975, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0); }

/* line 980, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1); }

/* disabled radio */
/* line 988, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:disabled:not(:checked) + label:before,
.radio-tp-2[type="radio"]:disabled:checked + label:before {
  border-color: #bbb;
  background-color: #ddd; }

/* line 994, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:disabled:checked + label:after {
  background: #999; }

/* line 998, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:disabled + label {
  background: #aaa; }

/* accessibility */
/* line 1005, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.radio-tp-2[type="radio"]:checked:focus + label:before,
.radio-tp-2[type="radio"]:not(:checked):focus + label:before {
  border-color: #919191; }

/* hover style just for information */
/* line 1013, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
label.rd-label-tp-2:hover:before {
  border-color: #919191 !important; }

/* line 1018, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.accordion {
  margin-bottom: 30px; }
  /* line 1020, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .accordion dt {
    margin-bottom: 9px; }
    /* line 1023, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .accordion dt.open .fa {
      color: #fd3a13;
      transform: rotate(90deg); }
    /* line 1028, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .accordion dt a {
      background-color: #f7f7f7;
      border-radius: 3px;
      color: #000;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      padding: 12px 12px 12px 34px;
      text-decoration: none;
      position: relative; }
    /* line 1041, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .accordion dt .fa {
      font-size: 15px;
      line-height: 46px;
      position: absolute;
      left: 12px;
      top: 0; }
  /* line 1049, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .accordion dd {
    color: #6f6f6f;
    font-size: 14px;
    line-height: 24px;
    padding: 12px 0 20px 34px; }

/* line 1057, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.gallery {
  margin-bottom: 30px; }
  /* line 1059, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .gallery .inner {
    margin: -3px; }
  /* line 1063, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .gallery .gallery-item {
    display: block;
    padding: 3px; }
    /* line 1066, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .gallery .gallery-item .img {
      cursor: pointer;
      position: relative; }
      /* line 1069, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
      .gallery .gallery-item .img:before, .gallery .gallery-item .img:after {
        background-color: #fff;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: 1px;
        width: 23px;
        opacity: 0;
        margin: 0 0 0 -11px;
        z-index: 2; }
      /* line 1082, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
      .gallery .gallery-item .img:before {
        width: 1px;
        height: 23px;
        margin: -11px 0 0 0; }
      /* line 1088, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
      .gallery .gallery-item .img:hover:before,
      .gallery .gallery-item .img:hover a:before, .gallery .gallery-item .img:hover:after {
        opacity: 1; }
      /* line 1094, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
      .gallery .gallery-item .img img {
        width: 100%; }
    /* line 1098, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .gallery .gallery-item a {
      display: block;
      position: relative; }
      /* line 1101, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
      .gallery .gallery-item a:before {
        background-color: rgba(22, 23, 26, 0.15);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        z-index: 1; }

/* line 1117, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.tabs .tabs-list {
  margin-bottom: 17px; }
  /* line 1119, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .tabs .tabs-list .tab {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 2px;
    color: #000;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    float: left;
    margin-right: 3px;
    padding: 9px;
    min-width: 100px;
    text-align: center; }
    /* line 1133, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
    .tabs .tabs-list .tab.active {
      background-color: #f7f7f7;
      border-color: #f7f7f7;
      color: #fd3a13; }

/* line 1141, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.tabs .tab-content p {
  font-size: 14px;
  line-height: 24px; }

/* line 1148, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.tables {
  color: #6f6f6f;
  width: 100%;
  text-align: center;
  margin-bottom: 30px; }
  /* line 1153, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .tables th {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    padding: 10px; }
  /* line 1161, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
  .tables td {
    border: 1px solid #f5f5f5;
    background-color: #fff;
    font: 14px/24px "Noto Sans";
    padding: 10px; }

/* line 1169, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.input-tp-1 {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  color: #818181;
  font: 12px/26px "Noto Sans";
  padding: 6px 12px;
  width: 100%; }

/* line 1178, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.label-tp-1 {
  display: inline-block;
  color: #000;
  font-size: 11px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 6px;
  text-transform: uppercase; }

/* line 1188, D:/aartyomenko/Git/spectr-html/app/scss/_typography.scss */
.textarea-tp-1 {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  color: #818181;
  font: 12px/26px "Noto Sans";
  padding: 6px 12px;
  width: 100%;
  height: 74px;
  resize: none; }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger {
  display: block;
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 20px;
  height: 19px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s; }

/* line 19, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger:focus {
  outline: none; }

/* line 23, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger span {
  display: block;
  position: absolute;
  top: 9px;
  width: 100%;
  height: 1px;
  background: #1c1c1c; }

/* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #1c1c1c;
  content: ""; }

/* line 43, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger span::before {
  top: -4px; }

/* line 47, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.c-hamburger span::after {
  bottom: -4px; }

/* line 51, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx {
  background-color: transparent; }

/* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx span {
  transition: background 0s 0.3s; }

/* line 59, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx span::before,
.htx span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s; }

/* line 65, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx span::before {
  transition-property: top, transform; }

/* line 69, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx span::after {
  transition-property: bottom, transform; }

/* active state, i.e. menu open */
/* line 76, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx.is-active span {
  background: none; }

/* line 80, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx.is-active span::before {
  top: 0;
  transform: rotate(45deg); }

/* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx.is-active span::after {
  bottom: 0;
  transform: rotate(-45deg); }

/* line 90, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.htx.is-active span::before,
.htx.is-active span::after {
  transition-delay: 0s, 0.3s; }

/* line 95, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.st-btn-1 {
  appearance: none;
  color: #1c1c1c;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  text-decoration: none; }

/* line 106, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-1 {
  appearance: none;
  color: #fff;
  background-color: #fd3a13;
  border-radius: 2px;
  cursor: pointer;
  font: 500 11px/24px "Poppins";
  border: none;
  display: block;
  padding: 6px 10px 4px;
  min-width: 100px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 120, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-1:hover, .btn-1:active, .btn-1:focus {
    background-color: #fd5331; }

/* line 127, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-2 {
  appearance: none;
  color: #fff;
  background-color: #000;
  border-radius: 2px;
  cursor: pointer;
  font: 500 11px/24px "Poppins";
  border: none;
  display: inline-block;
  padding: 5px 10px;
  min-width: 98px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }

/* line 146, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-3 {
  appearance: none;
  color: #fff;
  background-color: #fd3a13;
  border-radius: 2px;
  cursor: pointer;
  font: 500 11px/24px "Poppins";
  border: none;
  display: inline-block;
  padding: 7px 10px;
  min-width: 132px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 160, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-3:hover, .btn-3:active, .btn-3:focus {
    background-color: #f92b02; }

/* line 167, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-4 {
  appearance: none;
  color: #606060;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
  font: 500 12px/22px "Poppins";
  border: none;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none; }
  /* line 179, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-4:hover, .btn-4:active, .btn-4:focus {
    color: #000; }

/* line 186, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-5 {
  appearance: none;
  color: #fff;
  background-color: #16171a;
  border-radius: 2px;
  cursor: pointer;
  font: 500 11px/22px "Poppins";
  border: none;
  display: inline-block;
  padding: 7px 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 199, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-5:hover, .btn-5:active, .btn-5:focus {
    background-color: #2a2d31; }
  /* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-5 i {
    margin: -3px 6px 0px 0; }

/* line 209, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-6 {
  appearance: none;
  color: #000;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  font: 500 11px/22px "Poppins";
  border: 1px solid #e8e8e8;
  display: inline-block;
  padding: 6px 22px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 223, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-6:hover, .btn-6:active, .btn-6:focus {
    background-color: #34373d;
    border-color: #34373d;
    color: #fff; }

/* line 232, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-7 {
  appearance: none;
  color: #000;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  font: 500 11px/22px "Poppins";
  border: 1px solid #e8e8e8;
  display: inline-block;
  padding: 8px 38px;
  vertical-align: top;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 247, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-7:hover, .btn-7:active, .btn-7:focus {
    color: #fd3a13; }
  @media screen and (max-width: 1077px) {
    /* line 232, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
    .btn-7 {
      padding: 8px 20px; } }

/* line 257, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
.btn-8 {
  appearance: none;
  color: #fff;
  background-color: #fd3a13;
  border-radius: 2px;
  cursor: pointer;
  font: 600 12px/24px "Poppins";
  border: none;
  display: block;
  padding: 12px 10px 11px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 271, D:/aartyomenko/Git/spectr-html/app/scss/_buttons.scss */
  .btn-8:hover, .btn-8:active, .btn-8:focus {
    background-color: #f92b02; }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

/* line 6, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
body {
  font: 13px/23px "Poppins";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

/* line 12, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
a,
button, input[type="button"], input[type="reset"], input[type="submit"],
input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea {
  outline: 0;
  appearance: none; }

/* line 17, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.inner-wrapper {
  background: #16171a;
  position: relative;
  padding: 0 0 0 58px;
  z-index: 1; }
  @media screen and (max-width: 767px) {
    /* line 17, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
    .inner-wrapper {
      padding-left: 0; } }

/* line 27, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.left-sticky-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 58px;
  height: 100%;
  overflow-y: auto; }
  @media screen and (max-width: 767px) {
    /* line 27, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
    .left-sticky-bar {
      display: none; } }

/* line 39, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.main-content {
  background: #ebebeb;
  padding: 0 0 28px;
  overflow: hidden; }

/* line 45, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.video-content {
  background: #232529;
  margin: 0 0 -28px;
  padding: 0 0 28px;
  overflow: hidden; }

/* line 52, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.smp-page {
  padding: 50px 0 0; }

/* line 56, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
.main-footer {
  background: #1f2124; }
  /* line 59, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
  .main-footer .bottom-part .ft-logo {
    float: left;
    display: block;
    margin-right: 0%;
    width: 18.83333%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-footer .bottom-part .ft-logo:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 59, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-logo {
        float: left;
        display: block;
        margin-right: 0%;
        width: 25%; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-logo:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 479px) {
      /* line 59, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-logo {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        text-align: center; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-logo:last-child {
          margin-right: 0; }
        /* line 68, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
        .main-footer .bottom-part .ft-logo a {
          display: inline-block;
          margin-bottom: 15px; } }
  /* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
  .main-footer .bottom-part .ft-about {
    float: left;
    display: block;
    margin-right: 0%;
    width: 55.5%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-footer .bottom-part .ft-about:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-about {
        float: left;
        display: block;
        margin-right: 0%;
        width: 75%; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-about:last-child {
          margin-right: 0; } }
    @media screen and (max-width: 479px) {
      /* line 74, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-about {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        text-align: center; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-about:last-child {
          margin-right: 0; } }
  /* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
  .main-footer .bottom-part .ft-social {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25.66667%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-footer .bottom-part .ft-social:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-social {
        float: left;
        display: block;
        margin-right: 0%;
        width: 75%;
        float: right;
        margin-top: 25px; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-social:last-child {
          margin-right: 0; }
        /* line 92, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
        .main-footer .bottom-part .ft-social .ft-social-list {
          float: none; } }
    @media screen and (max-width: 479px) {
      /* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
      .main-footer .bottom-part .ft-social {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        text-align: center; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-footer .bottom-part .ft-social:last-child {
          margin-right: 0; }
        /* line 99, D:/aartyomenko/Git/spectr-html/app/scss/_theme_base.scss */
        .main-footer .bottom-part .ft-social .ft-social-list {
          float: none;
          display: inline-block; } }

/* line 3, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.posts .post:last-child {
  border: none; }
  /* line 5, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .posts .post:last-child.post-tp-17, .posts .post.post-tp-21:last-child {
    padding-bottom: 15px;
    margin-bottom: 0; }

/* line 13, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.posts .post.post-tp-24:first-child, .posts .post.post-tp-25:first-child, .posts .post.post-tp-26:first-child {
  margin-top: 8px; }

/* line 20, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post {
  position: relative; }
  /* line 24, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post:hover .title-3,
  .post:hover .title-5,
  .post:hover .title-6,
  .post:hover .title-10 {
    color: #fd3a13; }
  /* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post figure img {
    width: 100%; }
  /* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post .ptp-1-overlay,
  .post .ptp-27-overlay,
  .post .ptp-28-overlay,
  .post .ptp-31-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; }
  /* line 48, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post .meta-tp-1 div {
    pointer-events: auto; }
  /* line 52, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post .category-tp-1, .post .category-tp-3,
  .post .date-tp-1 {
    pointer-events: auto; }
  /* line 56, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post a {
    pointer-events: auto; }

/* line 61, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-1, .post-tpv-1, .post-tpv-2, .post-tp-2, .post-tp-12, .post-tp-14 {
  color: #fff;
  overflow: hidden; }
  /* line 66, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-1:hover figure a:before, .post-tpv-1:hover figure a:before, .post-tpv-2:hover figure a:before, .post-tp-2:hover figure a:before, .post-tp-12:hover figure a:before, .post-tp-14:hover figure a:before {
    background: rgba(28, 3, 3, 0.27); }
  /* line 71, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-1 figure, .post-tpv-1 figure, .post-tpv-2 figure, .post-tp-2 figure, .post-tp-12 figure, .post-tp-14 figure {
    position: relative;
    overflow: hidden;
    max-height: 100%; }
    /* line 75, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-1 figure img, .post-tpv-1 figure img, .post-tpv-2 figure img, .post-tp-2 figure img, .post-tp-12 figure img, .post-tp-14 figure img {
      width: 100%; }
    /* line 78, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-1 figure:before, .post-tpv-1 figure:before, .post-tpv-2 figure:before, .post-tp-2 figure:before, .post-tp-12 figure:before, .post-tp-14 figure:before {
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.36));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.36));
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 40%;
      pointer-events: none; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-1 figure a, .post-tpv-1 figure a, .post-tpv-2 figure a, .post-tp-2 figure a, .post-tp-12 figure a, .post-tp-14 figure a {
      display: block;
      position: relative; }
      /* line 92, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-1 figure a:before, .post-tpv-1 figure a:before, .post-tpv-2 figure a:before, .post-tp-2 figure a:before, .post-tp-12 figure a:before, .post-tp-14 figure a:before {
        background: rgba(0, 0, 0, 0.2);
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transition: all 500ms; }
  /* line 104, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-1 .ptp-1-data, .post-tpv-1 .ptp-1-data, .post-tpv-2 .ptp-1-data, .post-tp-2 .ptp-1-data, .post-tp-12 .ptp-1-data, .post-tp-14 .ptp-1-data {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 0 30px 27px; }
  /* line 112, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-1 .title-1, .post-tpv-1 .title-1, .post-tpv-2 .title-1, .post-tp-2 .title-1, .post-tp-12 .title-1, .post-tp-14 .title-1 {
    margin: 0 0 6px; }
  /* line 115, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-1 .meta-tp-1, .post-tpv-1 .meta-tp-1, .post-tpv-2 .meta-tp-1, .post-tp-2 .meta-tp-1, .post-tp-12 .meta-tp-1, .post-tp-14 .meta-tp-1 {
    margin: 0 0 20px; }

/* line 124, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1:hover figure a:before, .post-tpv-2:hover figure a:before {
  background: rgba(0, 0, 0, 0.36); }

/* line 129, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 figure, .post-tpv-2 figure {
  position: relative; }
  /* line 131, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tpv-1 figure:before, .post-tpv-2 figure:before {
    background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.36));
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.36));
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 45%; }
  /* line 142, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tpv-1 figure a:before, .post-tpv-2 figure a:before {
    background: rgba(0, 0, 0, 0.26); }
  /* line 146, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tpv-1 figure img, .post-tpv-2 figure img {
    width: 100%; }

/* line 150, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 .ptp-1-data, .post-tpv-2 .ptp-1-data {
  padding: 0 23px; }

/* line 153, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 .video-lg-ic, .post-tpv-2 .video-lg-ic {
  position: absolute;
  left: 24px;
  top: 24px; }

/* line 158, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 .video-sm-ic, .post-tpv-2 .video-sm-ic {
  margin: 0 0 14px; }

/* line 161, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 .title-7, .post-tpv-2 .title-7 {
  margin-bottom: 7px; }

/* line 164, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-1 .category-tp-1, .post-tpv-2 .category-tp-1, .post-tpv-1 .category-tp-3, .post-tpv-2 .category-tp-3 {
  margin-bottom: 18px; }

/* line 173, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2:hover figure a:before {
  background: rgba(0, 0, 0, 0.45); }

/* line 177, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2:hover .title-5 {
  color: inherit; }

/* line 183, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2 figure a:before {
  background: rgba(0, 0, 0, 0.35); }

/* line 188, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2 .title-5 {
  color: #fff; }

/* line 191, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2 .ptp-1-data {
  padding: 10px 16px; }

/* line 194, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tpv-2 .date-tp-2 {
  color: #fff; }

/* line 203, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2:hover figure a:before, .post-tp-12:hover figure a:before, .post-tp-14:hover figure a:before {
  background: rgba(0, 0, 0, 0.5); }

/* line 210, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 figure a:before, .post-tp-12 figure a:before, .post-tp-14 figure a:before {
  background: rgba(0, 0, 0, 0.4); }

/* line 215, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 .title-2, .post-tp-12 .title-2, .post-tp-14 .title-2 {
  margin-bottom: 6px; }

/* line 218, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 .ptp-1-data, .post-tp-12 .ptp-1-data, .post-tp-14 .ptp-1-data {
  padding: 0 20px 20px; }

/* line 221, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 .meta-tp-1, .post-tp-12 .meta-tp-1, .post-tp-14 .meta-tp-1 {
  margin: 0; }

/* line 224, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 .category-tp-1, .post-tp-12 .category-tp-1, .post-tp-14 .category-tp-1, .post-tp-2 .category-tp-3, .post-tp-12 .category-tp-3, .post-tp-14 .category-tp-3 {
  margin: 0 0 16px; }

/* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-2 .ptp-1-views i, .post-tp-12 .ptp-1-views i, .post-tp-14 .ptp-1-views i,
.post-tp-2 .ptp-1-comments i, .post-tp-12 .ptp-1-comments i, .post-tp-14 .ptp-1-comments i {
  position: relative;
  top: 1px; }

/* line 236, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-3 {
  margin: 0 0 15px; }
  /* line 238, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-3 figure {
    float: left;
    margin: 7px 20px 0 0; }
  /* line 242, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-3 .category-tp-2 {
    margin-bottom: 5px; }
  /* line 245, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-3 .title-3 {
    margin-bottom: 2px; }

/* line 250, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-4 {
  margin: 23px 0; }
  /* line 253, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4:hover .ptp-4-overlay {
    opacity: 1; }
  /* line 256, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4:hover .ptp-4-data {
    transform: translateY(0); }
  /* line 260, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4 .ptp-4-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0; }
    /* line 269, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-4 .ptp-4-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 279, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-4 .ptp-4-overlay a,
    .post-tp-4 .ptp-4-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 286, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-4 .ptp-4-overlay a i,
      .post-tp-4 .ptp-4-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 292, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4 .ptp-4-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }
  /* line 301, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4 figure {
    margin: 0 0 20px 0;
    position: relative; }
  /* line 305, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-4 .title-3 {
    margin-bottom: 3px; }

/* line 310, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-5, .post-tp-10, .post-tp-16 {
  padding: 11px 0 11px; }
  /* line 313, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5:hover .ptp-5-overlay, .post-tp-10:hover .ptp-5-overlay, .post-tp-16:hover .ptp-5-overlay {
    opacity: 1; }
  /* line 316, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5:hover .ptp-5-data, .post-tp-10:hover .ptp-5-data, .post-tp-16:hover .ptp-5-data {
    transform: translateY(0); }
  /* line 320, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 .ptp-5-overlay, .post-tp-10 .ptp-5-overlay, .post-tp-16 .ptp-5-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0; }
    /* line 329, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-5 .ptp-5-overlay:before, .post-tp-10 .ptp-5-overlay:before, .post-tp-16 .ptp-5-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 339, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-5 .ptp-5-overlay a, .post-tp-10 .ptp-5-overlay a, .post-tp-16 .ptp-5-overlay a,
    .post-tp-5 .ptp-5-overlay span, .post-tp-10 .ptp-5-overlay span, .post-tp-16 .ptp-5-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 346, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-5 .ptp-5-overlay a i, .post-tp-10 .ptp-5-overlay a i, .post-tp-16 .ptp-5-overlay a i,
      .post-tp-5 .ptp-5-overlay span i, .post-tp-10 .ptp-5-overlay span i, .post-tp-16 .ptp-5-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 352, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 .ptp-5-data, .post-tp-10 .ptp-5-data, .post-tp-16 .ptp-5-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }
  /* line 361, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 figure, .post-tp-10 figure, .post-tp-16 figure {
    margin: 0 0 20px 0;
    position: relative; }
  /* line 365, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 .meta-tp-2, .post-tp-10 .meta-tp-2, .post-tp-16 .meta-tp-2 {
    margin-bottom: 4px; }
  /* line 368, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 .title-5, .post-tp-10 .title-5, .post-tp-16 .title-5 {
    margin-bottom: 5px; }
  /* line 371, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-5 p, .post-tp-10 p, .post-tp-16 p {
    margin-bottom: 5px; }

/* line 376, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-6, .post-tp-9 {
  padding: 7px 0 11px; }
  /* line 378, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-6 figure, .post-tp-9 figure {
    float: left;
    margin: 4px 18px 0 0; }
    @media screen and (max-width: 1229px) {
      /* line 378, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-6 figure, .post-tp-9 figure {
        max-width: 105px; } }
  /* line 385, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-6 .title-6, .post-tp-9 .title-6 {
    margin-bottom: 5px; }
  /* line 388, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-6 .rate-tp-1, .post-tp-9 .rate-tp-1 {
    margin: 4px 0; }

/* line 393, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-7 {
  padding: 8px 0 5px;
  text-align: center; }
  /* line 396, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-7 .title-5 {
    margin-bottom: 10px; }
  /* line 399, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-7 figure {
    border-radius: 50%;
    width: 67px;
    height: 67px;
    margin: 0 auto 12px;
    overflow: hidden; }
  /* line 406, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-7 p {
    margin-bottom: 6px; }

/* line 411, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-8 {
  margin: 8px 0 18px; }
  /* line 414, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8:hover .ptp-8-overlay {
    opacity: 1; }
  /* line 417, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8:hover .ptp-8-data {
    transform: translateY(0); }
  /* line 421, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8 .ptp-8-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 2; }
    /* line 431, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-8 .ptp-8-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 441, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-8 .ptp-8-overlay a,
    .post-tp-8 .ptp-8-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 448, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-8 .ptp-8-overlay a i,
      .post-tp-8 .ptp-8-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 454, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8 .ptp-8-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }
  /* line 463, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8 figure {
    margin: 0 0 20px 0;
    position: relative; }
  /* line 467, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-8 .title-5 {
    margin-bottom: 4px; }

/* line 472, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-9 {
  border-bottom: 1px dashed #ebebeb;
  padding: 10px 0px 15px; }
  /* line 476, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-9:last-child {
    border: none; }

/* line 483, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-10 .ptp-10-data {
  padding: 3px 20px 29px; }

/* line 489, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-11 .meta-tp-2 {
  margin-bottom: 5px; }

/* line 498, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12:hover figure a:before {
  background: rgba(28, 3, 3, 0.27); }

/* line 504, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12 figure:before {
  background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.36));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.36)); }

/* line 508, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12 figure a:before {
  background: rgba(28, 3, 3, 0.2); }

/* line 513, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12 .category-tp-1, .post-tp-12 .category-tp-3 {
  margin: 0px 0px 15px; }

/* line 516, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12 .title-15 {
  margin-bottom: 4px; }

/* line 519, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-12 .title-13 {
  margin-bottom: 7px; }

/* line 524, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-13, .post-tp-17, .post-tp-21 {
  margin-bottom: 14px; }
  /* line 527, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13:hover .ptp-13-overlay, .post-tp-17:hover .ptp-13-overlay, .post-tp-21:hover .ptp-13-overlay {
    opacity: 1; }
  /* line 530, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13:hover .ptp-13-data, .post-tp-17:hover .ptp-13-data, .post-tp-21:hover .ptp-13-data {
    transform: translateY(0); }
  /* line 534, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13 figure, .post-tp-17 figure, .post-tp-21 figure {
    float: left;
    margin: 8px 20px 0 0;
    position: relative; }
  /* line 539, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13 .category-tp-4, .post-tp-17 .category-tp-4, .post-tp-21 .category-tp-4 {
    margin-bottom: 5px; }
  /* line 542, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13 .meta-tp-2, .post-tp-17 .meta-tp-2, .post-tp-21 .meta-tp-2 {
    margin: 4px 0; }
  /* line 545, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13 .ptp-13-overlay, .post-tp-17 .ptp-13-overlay, .post-tp-21 .ptp-13-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 2; }
    /* line 555, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-13 .ptp-13-overlay:before, .post-tp-17 .ptp-13-overlay:before, .post-tp-21 .ptp-13-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 565, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-13 .ptp-13-overlay a, .post-tp-17 .ptp-13-overlay a, .post-tp-21 .ptp-13-overlay a,
    .post-tp-13 .ptp-13-overlay span, .post-tp-17 .ptp-13-overlay span, .post-tp-21 .ptp-13-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 572, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-13 .ptp-13-overlay a i, .post-tp-17 .ptp-13-overlay a i, .post-tp-21 .ptp-13-overlay a i,
      .post-tp-13 .ptp-13-overlay span i, .post-tp-17 .ptp-13-overlay span i, .post-tp-21 .ptp-13-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 578, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-13 .ptp-13-data, .post-tp-17 .ptp-13-data, .post-tp-21 .ptp-13-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }

/* line 593, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-14:hover figure a:before {
  background: rgba(28, 3, 3, 0.4); }

/* line 599, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-14 figure:before {
  background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.6)); }

/* line 603, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-14 figure a:before {
  background: rgba(28, 3, 3, 0.2); }

/* line 611, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-15 figure, .post-tp-18 figure {
  position: relative;
  margin-bottom: 18px; }

/* line 615, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-15 .title-5, .post-tp-18 .title-5 {
  margin-bottom: 6px; }

/* line 618, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-15 p, .post-tp-18 p {
  margin-bottom: 4px; }

/* line 621, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-15 .category-tp-1, .post-tp-18 .category-tp-1, .post-tp-15 .category-tp-3, .post-tp-18 .category-tp-3 {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 14px; }

/* line 629, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-16 {
  padding: 8px 0; }
  /* line 632, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-16 .title-14 {
    margin-bottom: 4px; }
  /* line 635, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-16 p {
    line-height: 23px; }

/* line 640, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-17, .post-tp-21 {
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 20px;
  margin-bottom: 13px; }
  @media screen and (max-width: 479px) {
    /* line 640, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-17, .post-tp-21 {
      padding-top: 7px; } }
  /* line 648, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-17 figure, .post-tp-21 figure {
    margin: 7px 20px 0 0; }
    @media screen and (max-width: 639px) {
      /* line 651, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-17 figure img, .post-tp-21 figure img {
        width: 160px; } }
    @media screen and (max-width: 479px) {
      /* line 648, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-17 figure, .post-tp-21 figure {
        float: none;
        margin: 0 0 20px 0; }
        /* line 658, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
        .post-tp-17 figure img, .post-tp-21 figure img {
          width: 100%;
          max-width: none; } }

/* line 666, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-18 {
  border-bottom: 1px solid #f2f2f2;
  padding: 8px 0 14px;
  margin-bottom: 12px; }

/* line 674, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-19 figure {
  position: relative;
  margin: 0 0 6px; }
  /* line 677, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-19 figure:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2; }
  /* line 688, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-19 figure:after {
    content: '';
    background: #f0f0f0;
    width: 1px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1; }

/* line 699, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-19 .data {
  padding: 13px 20px 20px; }

/* line 702, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-19 .meta-tp-2 {
  margin: 3px 0 1px; }

/* line 708, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-20 .ptp-20-overlay {
  background: #fff;
  color: #6d6d6d;
  height: 50%;
  position: relative; }
  /* line 713, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-20 .ptp-20-overlay:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    z-index: 2; }
  /* line 722, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-20 .ptp-20-overlay.up {
    margin-bottom: 1px; }
    /* line 724, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-20 .ptp-20-overlay.up:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      top: 100%; }
  /* line 731, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-20 .ptp-20-overlay.down {
    margin-top: 1px; }
    /* line 733, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-20 .ptp-20-overlay.down:before {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      position: absolute;
      bottom: 100%; }

/* line 742, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-20 .ptp-20-data {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px 12px; }

/* line 749, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-20 .meta-tp-1 {
  color: #888;
  margin: 4px 0 7px; }
  /* line 753, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-20 .meta-tp-1 > div:before {
    background: rgba(174, 174, 174, 0.24); }

/* line 758, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-20 .date-tp-1 {
  margin: 20px; }

/* line 763, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-21 {
  margin: 16px 0 0; }
  /* line 766, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-21 figure {
    margin: 4px 20px 0 0; }
    @media screen and (max-width: 639px) {
      /* line 769, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-21 figure img {
        width: 160px; } }
    @media screen and (max-width: 479px) {
      /* line 766, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-21 figure {
        float: none;
        margin: 4px 0 20px 0; }
        /* line 776, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
        .post-tp-21 figure img {
          width: 100%;
          max-width: none; } }

/* line 784, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-22 {
  background: #fff;
  margin: 0 0 20px; }
  /* line 787, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-22:last-child {
    margin: 0; }
  /* line 790, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-22 .title-3 {
    margin: 0 0 4px; }
  /* line 793, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-22 .data {
    padding: 15px 18px 11px; }

/* line 798, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-23 {
  background: #fff;
  margin: 0 0 20px; }
  /* line 802, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-23:hover .ptp-23-overlay {
    opacity: 1; }
  /* line 806, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-23:hover .top-rate:before {
    border-bottom-color: #fd3a13; }
  /* line 810, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-23:hover .title-3 {
    color: #fff; }
  /* line 814, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-23:last-child {
    margin: 0; }
  /* line 817, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-23 .ptp-23-overlay {
    background: rgba(0, 0, 0, 0.56);
    padding: 16px 18px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0; }

/* line 830, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-24 {
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 20px;
  margin: 0 0 22px; }
  /* line 835, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24:hover .ptp-24-overlay {
    opacity: 1; }
  /* line 838, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24:hover .ptp-24-data {
    transform: translateY(0); }
  /* line 842, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24 .ptp-24-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 2; }
    /* line 852, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-24 .ptp-24-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 862, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-24 .ptp-24-overlay a,
    .post-tp-24 .ptp-24-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 869, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-24 .ptp-24-overlay a i,
      .post-tp-24 .ptp-24-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 875, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24 .ptp-24-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }
  /* line 884, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24 figure {
    float: left;
    margin: 0 20px 0 0;
    position: relative; }
    @media screen and (max-width: 639px) {
      /* line 889, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-24 figure img {
        width: 160px; } }
    @media screen and (max-width: 479px) {
      /* line 884, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-24 figure {
        float: none;
        margin: 0 0 20px 0; }
        /* line 896, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
        .post-tp-24 figure img {
          width: 100%;
          max-width: none; } }
  /* line 902, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24 .title-14 {
    margin-bottom: 4px; }
  /* line 905, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-24 .meta-tp-2 {
    margin: 5px 0; }

/* line 910, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-25 {
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 20px;
  margin: 0 0 30px; }
  /* line 915, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25:hover .ptp-25-overlay {
    opacity: 1; }
  /* line 918, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25:hover .ptp-25-data {
    transform: translateY(0); }
  /* line 922, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 .ptp-25-overlay {
    background: rgba(31, 33, 36, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    z-index: 2; }
    /* line 932, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-25 .ptp-25-overlay:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 7px solid #fd3a13;
      border-right: 7px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
    /* line 942, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-25 .ptp-25-overlay a,
    .post-tp-25 .ptp-25-overlay span {
      color: #fff;
      font: 700 12px/23px "Inconsolata";
      margin: 0 4px 0 0;
      text-decoration: none;
      pointer-events: auto; }
      /* line 949, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-25 .ptp-25-overlay a i,
      .post-tp-25 .ptp-25-overlay span i {
        font-size: 11px;
        margin: 0 6px 0 0; }
  /* line 955, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 .ptp-25-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 16px;
    transform: translateY(25px);
    transition: all 300ms; }
  /* line 964, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 figure {
    margin: 0 0 23px 0;
    position: relative; }
  /* line 968, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 .title-17 {
    margin-bottom: 6px; }
  /* line 971, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 .meta-tp-2 {
    margin: 7px 0 2px; }
  /* line 974, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-25 p {
    font-size: 14px;
    line-height: 23px; }

/* line 980, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-26 {
  border-bottom: 1px solid #f2f2f2;
  padding: 0 0 20px 59px;
  margin: 0 0 20px; }
  @media screen and (max-width: 639px) {
    /* line 980, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-26 {
      padding: 0 0 20px 0; }
      /* line 986, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-26 .date-tp-3 {
        z-index: 1; } }
  /* line 992, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-26:hover figure:before {
    opacity: 1; }
  /* line 997, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-26 figure {
    float: left;
    margin: 0 20px 0 0;
    position: relative; }
    /* line 1001, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-26 figure:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.07);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none; }
    @media screen and (max-width: 639px) {
      /* line 1013, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-26 figure img {
        width: 160px; } }
    @media screen and (max-width: 479px) {
      /* line 997, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-26 figure {
        float: none;
        margin: 0 0 20px 0; }
        /* line 1020, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
        .post-tp-26 figure img {
          width: 100%;
          max-width: none; } }
  /* line 1026, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-26 .meta-tp-2 {
    margin: 5px 0; }
  /* line 1029, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-26 .date-tp-3 {
    position: absolute;
    left: 0;
    top: 0; }

/* line 1038, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-27:hover .ptp-27-overlay {
  background-color: rgba(15, 2, 2, 0.54); }

/* line 1042, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-27 .ptp-27-overlay {
  background-color: rgba(22, 4, 4, 0.4);
  padding: 40px 20%;
  text-align: center; }
  @media screen and (max-width: 767px) {
    /* line 1042, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-27 .ptp-27-overlay {
      padding: 40px 5%; } }

/* line 1050, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-27 .category-tp-1, .post-tp-27 .category-tp-3 {
  margin-bottom: 22px; }

/* line 1053, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-27 .meta-tp-4, .post-tp-27 .meta-tp-5 {
  margin: 9px 0 0; }

/* line 1056, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-27.mb-height {
  overflow: hidden; }
  @media screen and (max-width: 639px) {
    /* line 1056, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-27.mb-height {
      height: 350px; }
      /* line 1060, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-27.mb-height figure,
      .post-tp-27.mb-height a,
      .post-tp-27.mb-height img {
        height: 100%; }
      /* line 1065, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-27.mb-height figure img {
        max-width: none;
        width: auto; } }

/* line 1075, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28:hover .ptp-28-overlay {
  background-color: rgba(0, 0, 0, 0.5); }

/* line 1079, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28 .ptp-28-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 40px 8%;
  text-align: center; }
  @media screen and (max-width: 639px) {
    /* line 1079, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-28 .ptp-28-overlay {
      padding: 40px 15px; } }

/* line 1087, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28 .category-tp-1, .post-tp-28 .category-tp-3 {
  margin-bottom: 22px; }

/* line 1090, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28 .date-tp-4 {
  margin: 9px 0; }

/* line 1093, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28 .icon {
  margin: 0 0 36px; }

/* line 1096, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-28.mb-height {
  overflow: hidden; }
  @media screen and (max-width: 639px) {
    /* line 1096, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-28.mb-height {
      height: 350px; }
      /* line 1100, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-28.mb-height figure,
      .post-tp-28.mb-height a,
      .post-tp-28.mb-height img {
        height: 100%; }
      /* line 1105, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
      .post-tp-28.mb-height figure img {
        max-width: none;
        width: auto; } }

/* line 1113, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-29 {
  margin: 0 0 24px; }
  /* line 1115, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-29 .date-tp-2 {
    margin: 13px 0 4px; }

/* line 1121, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-30 figure {
  position: relative;
  margin-bottom: 15px; }

/* line 1126, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-30:hover .ptp-30-overlay {
  opacity: 1; }

/* line 1129, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-30:hover .ptp-30-data {
  transform: translateY(0); }

/* line 1133, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-30 .ptp-30-overlay {
  background: rgba(31, 33, 36, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  z-index: 2; }
  /* line 1143, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-30 .ptp-30-overlay:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 7px solid #fd3a13;
    border-right: 7px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0; }
  /* line 1153, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-30 .ptp-30-overlay a,
  .post-tp-30 .ptp-30-overlay span {
    color: #fff;
    font: 700 12px/23px "Inconsolata";
    margin: 0 4px 0 0;
    text-decoration: none;
    pointer-events: auto; }
    /* line 1160, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-30 .ptp-30-overlay a i,
    .post-tp-30 .ptp-30-overlay span i {
      font-size: 11px;
      margin: 0 6px 0 0; }

/* line 1166, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-30 .ptp-30-data {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 6px 16px;
  transform: translateY(25px);
  transition: all 300ms; }

@media screen and (max-width: 639px) {
  /* line 1177, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
  .post-tp-31 {
    height: 350px; }
    /* line 1180, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-31 figure,
    .post-tp-31 a,
    .post-tp-31 img {
      height: 100%; }
    /* line 1185, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-31 figure img {
      max-width: none;
      width: auto; } }

/* line 1191, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-31:hover .ptp-31-overlay {
  background-color: rgba(15, 2, 2, 0.54); }

/* line 1195, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-31 .ptp-31-overlay {
  background-color: rgba(22, 4, 4, 0.4);
  padding: 40px 20%;
  text-align: center; }
  @media screen and (max-width: 1077px) {
    /* line 1195, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-31 .ptp-31-overlay {
      padding: 40px 10%; } }
  @media screen and (max-width: 639px) {
    /* line 1195, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
    .post-tp-31 .ptp-31-overlay {
      padding: 40px 15px; } }

/* line 1206, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-31 .category-tp-1, .post-tp-31 .category-tp-3 {
  margin-bottom: 22px; }

/* line 1209, D:/aartyomenko/Git/spectr-html/app/scss/_posts.scss */
.post-tp-31 .meta-tp-4, .post-tp-31 .meta-tp-5 {
  margin: 9px 0 0; }

/* line 3, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.content .pst-block,
.content-sm .pst-block {
  margin-bottom: 30px; }

/* line 8, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.content > div:last-child > .pst-block,
.content .js-sidebar-zone > div:last-child > .pst-block,
.content-sm > div:last-child > .pst-block,
.content-sm .js-sidebar-zone > div:last-child > .pst-block {
  margin: 0; }

/* line 14, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-content {
  background-color: #fff;
  padding: 15px 20px; }
  /* line 17, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-content.trsp {
    background: none;
    padding: 0; }

/* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.side-bar .pst-block,
.side-bar-sm .pst-block {
  margin-bottom: 30px; }

/* line 29, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.side-bar > div > div:last-child .pst-block,
.side-bar-sm > div > div:last-child .pst-block {
  margin: 0; }

/* line 41, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.side-bar-sm .sbsb-block-1 {
  margin-bottom: 0; }

/* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.full-section {
  margin-bottom: 45px; }

/* line 50, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.category-tp-1, .category-tp-3 {
  background: #fd3a13;
  color: #fff;
  display: inline-block;
  font-family: "Inconsolata";
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  height: 20px;
  padding: 3px 7px 2px 9px;
  position: relative;
  margin: 0 0 15px;
  text-transform: uppercase;
  text-decoration: none; }
  /* line 64, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .category-tp-1:after, .category-tp-3:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 4px solid #fd3a13;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: -4px; }

/* line 77, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.category-tp-2 {
  color: #888;
  display: inline-block;
  font: 400 12px/23px "Inconsolata";
  text-decoration: none;
  text-transform: uppercase; }

/* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.category-tp-3 {
  background: #000; }
  /* line 88, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .category-tp-3:after {
    border-left: 4px solid #000; }

/* line 93, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.category-tp-4 {
  color: #888;
  display: inline-block;
  font: 700 12px/23px "Inconsolata";
  text-decoration: none;
  text-transform: uppercase; }

/* line 101, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.category-tp-5 {
  background: #fd3a13;
  color: #fff;
  display: inline-block;
  font-family: "Inconsolata";
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  height: 20px;
  padding: 3px 7px 2px 9px;
  position: relative;
  margin: 0 0 15px;
  text-transform: uppercase;
  text-decoration: none; }

/* line 117, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.reviews-tp-1 {
  background: #000;
  color: #fff;
  display: inline-block;
  font-family: "Inconsolata";
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  height: 20px;
  padding: 3px 7px 2px 9px;
  position: relative;
  margin: 0 0 15px;
  text-transform: uppercase;
  text-decoration: none; }
  /* line 131, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .reviews-tp-1:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 4px solid #000;
    border-bottom: 10px solid transparent;
    position: absolute;
    top: 0;
    right: -4px; }

/* line 144, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.date-tp-1 {
  display: inline-block;
  font-size: 34px;
  font-weight: 300;
  line-height: 38px;
  margin: 26px 30px; }
  /* line 150, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .date-tp-1 sub {
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    vertical-align: top; }
  /* line 157, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .date-tp-1 .month {
    display: block;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    text-transform: uppercase; }

/* line 166, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.date-tp-2 {
  color: #888;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }

/* line 172, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.date-tp-3 {
  background-color: #f2f2f2;
  color: #000;
  font-size: 20px;
  line-height: 19px;
  width: 39px;
  padding: 9px 6px;
  text-align: center;
  text-transform: uppercase;
  position: relative; }
  /* line 182, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .date-tp-3:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0; }
  /* line 192, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .date-tp-3 span {
    display: block;
    font: 400 10px/10px "Inconsolata"; }

/* line 198, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.date-tp-4 {
  color: #fff;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 202, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .date-tp-4 i {
    font-size: 10px;
    margin: 0 6px; }

/* line 208, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.author-tp-1 {
  color: #888;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 212, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-tp-1 i {
    font-size: 10px;
    margin: 0 7px 0 0; }

/* line 218, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.author-tp-2 {
  color: #fff;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 222, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-tp-2 i {
    font-size: 10px;
    margin: 0 7px 0 0; }
  /* line 226, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-tp-2 .photo {
    background-color: #f5f5f5;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0;
    vertical-align: middle; }
    @media screen and (max-width: 479px) {
      /* line 226, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-tp-2 .photo {
        display: none; } }

/* line 241, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.meta-tp-1 {
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 244, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-1 > div {
    display: inline-block;
    padding: 0 4px 0 0;
    white-space: nowrap;
    position: relative; }
    /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .meta-tp-1 > div:first-child {
      margin: 0 9px 0 0; }
  /* line 253, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-1 a {
    color: inherit;
    text-decoration: none; }
  /* line 257, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-1 i {
    font-size: 11px;
    margin: 0 7px 0 0; }

/* line 263, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.meta-tp-2 {
  color: #888;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 267, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-2 > div {
    display: inline-block;
    padding: 0 13px 0 0;
    margin: 0 7px 0 0;
    white-space: nowrap;
    position: relative; }
    /* line 273, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .meta-tp-2 > div:before {
      background: rgba(174, 174, 174, 0.24);
      content: '';
      width: 1px;
      position: absolute;
      right: 0;
      top: 6px;
      bottom: 4px; }
    /* line 283, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .meta-tp-2 > div:last-child:before {
      display: none; }
  /* line 288, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-2 a {
    color: inherit;
    text-decoration: none; }
  /* line 292, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-2 i {
    font-size: 10px;
    margin: 0 7px 0 0; }

/* line 298, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.meta-tp-3 {
  color: #888;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 302, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-3 > div {
    display: inline-block;
    margin: 0 7px 0 0;
    white-space: nowrap;
    position: relative; }
  /* line 308, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-3 a {
    color: inherit;
    text-decoration: none; }
  /* line 312, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-3 i {
    font-size: 11px;
    margin: 0 7px 0 0; }

/* line 318, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.meta-tp-4, .meta-tp-5 {
  color: #fff;
  font: 400 12px/23px "Inconsolata";
  text-transform: uppercase; }
  /* line 322, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-4 > div, .meta-tp-5 > div {
    display: inline-block;
    margin: 0 9px;
    white-space: nowrap;
    position: relative; }
  /* line 328, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-4 a, .meta-tp-5 a {
    color: inherit;
    text-decoration: none; }
  /* line 332, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-4 i, .meta-tp-5 i {
    font-size: 10px;
    margin: 0 7px 0 0; }
  /* line 336, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-4 .ptp-1-comments, .meta-tp-5 .ptp-1-comments {
    margin-left: 0; }

/* line 341, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.meta-tp-5 {
  color: #888; }
  /* line 344, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-5 .author-tp-2,
  .meta-tp-5 .date-tp-4 {
    color: #888; }
  /* line 348, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .meta-tp-5 .author-tp-2 {
    margin-left: 0; }

/* line 354, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rate-tp-1 ul {
  font-size: 0; }

/* line 357, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rate-tp-1 li {
  display: inline-block;
  margin-right: 3px; }
  /* line 360, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rate-tp-1 li:last-child {
    margin: 0; }
  /* line 363, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rate-tp-1 li i {
    color: #fd3a13;
    font-size: 12px;
    margin: 0; }

/* line 369, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rate-tp-1 a {
  text-decoration: none; }

/* line 378, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rate-tp-2 li {
  color: #c2c2c2;
  float: left;
  margin-right: 4px; }
  /* line 382, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rate-tp-2 li:last-child {
    margin: 0; }
  /* line 385, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rate-tp-2 li.active {
    color: #fd3a13; }
  /* line 388, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rate-tp-2 li i {
    display: block;
    font-size: 15px;
    margin: 0; }

/* line 394, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rate-tp-2 a {
  color: inherit;
  line-height: 26px;
  display: block;
  text-decoration: none; }

/* line 402, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.read-tp-1,
.save-tp-1 {
  color: #fff;
  font: 700 12px/25px "Inconsolata";
  text-decoration: none;
  text-transform: uppercase; }
  /* line 408, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .read-tp-1 span,
  .save-tp-1 span {
    display: inline-block;
    vertical-align: middle;
    margin: 0 4px 0 0; }

/* line 415, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.trend-pst-slider {
  height: 100%; }
  /* line 417, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trend-pst-slider .pst-block-main {
    margin: -5px 0 0; }
  /* line 420, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trend-pst-slider .trpst-block {
    height: 100%;
    padding: 5px 0 51px;
    position: relative; }
  /* line 425, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trend-pst-slider .trpst-block-foot {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
    /* line 430, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .trend-pst-slider .trpst-block-foot .more-txt {
      position: relative;
      top: 1px; }
  /* line 435, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trend-pst-slider .sbr-dots {
    float: right;
    padding: 0; }
  @media screen and (max-width: 1229px) {
    /* line 440, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .trend-pst-slider .post-tp-3 {
      margin: 0 0 7px; }
      /* line 442, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .trend-pst-slider .post-tp-3 .adaptive {
        max-width: 103px; } }

/* line 449, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-block-head {
  background: #fff;
  padding: 14px 20px 13px;
  position: relative;
  margin-bottom: 7px; }
  /* line 455, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-head:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0; }
  /* line 465, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-head .title-4 {
    position: relative;
    top: 1px; }

/* line 471, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-block-main {
  background: #fff;
  padding: 15px 20px; }
  /* line 475, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-main.trsp {
    background: none;
    padding: 0;
    margin-bottom: 0; }
  /* line 480, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-main:last-child {
    margin-bottom: 0; }

/* line 485, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-block-foot {
  background: #fcfcfc;
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  margin-top: 7px;
  text-transform: uppercase; }
  /* line 493, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-foot a {
    color: inherit;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 13px 20px; }
    /* line 499, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .pst-block-foot a:hover {
      color: #fd3a13; }
  /* line 503, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-block-foot .arr-right-dark-ic {
    float: right; }

/* line 508, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-box {
  margin-bottom: 50px; }

/* line 514, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-box-head .title-blc-1 {
  float: left;
  padding: 0 24px;
  margin: 22px -24px; }

/* line 519, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-box-head .navs {
  color: #9b9b9b;
  font-size: 17px;
  float: right;
  margin: 19px -7px; }
  /* line 524, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-box-head .navs span {
    cursor: pointer;
    display: inline-block;
    float: left;
    padding: 0 7px; }

/* line 533, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-box-main {
  border-bottom: 1px solid #f7f7f7; }
  /* line 535, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-box-main .pst-box-inner {
    margin-left: -9px;
    margin-right: -9px; }
    /* line 539, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .pst-box-main .pst-box-inner img {
      width: 100%; }
  /* line 543, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-box-main .one-third {
    padding: 0 9px; }
  /* line 546, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .pst-box-main .title-6 {
    margin-bottom: 19px; }

/* line 551, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.pst-block-hr {
  border: none;
  border-top: 1px solid #f2f2f2;
  margin: 12px -20px; }

/* line 557, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.xs-hr {
  width: 24px;
  border: none;
  border-top: 1px solid #d8d8d8;
  margin: 15px 0; }

/* line 564, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.vpst-block {
  padding: 18px 0 0; }

/* line 568, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.vpst-block-head {
  margin: 22px 0;
  padding: 0 0 0 18px;
  position: relative; }
  /* line 573, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-head:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0; }
  /* line 584, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-head i:before {
    vertical-align: middle; }
  /* line 588, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-head .title-4 {
    color: #fff; }
  /* line 591, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-head .filters {
    color: #cdcdcd; }
    /* line 593, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .vpst-block-head .filters .filters-list-1, .vpst-block-head .filters .filters-list-2 {
      border-color: #383b3f; }
      /* line 596, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .vpst-block-head .filters .filters-list-1 a:hover, .vpst-block-head .filters .filters-list-2 a:hover, .vpst-block-head .filters .filters-list-1 a.active, .vpst-block-head .filters .filters-list-2 a.active {
        color: #fff; }
  /* line 603, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-head .filters-drop {
    top: 130%; }
    /* line 605, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .vpst-block-head .filters-drop .arr {
      right: 4px; }

/* line 611, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.vpst-block-main {
  margin: 0 0 30px; }

/* line 616, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.vpst-block-foot {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  text-transform: uppercase; }
  /* line 622, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .vpst-block-foot a {
    border-top: 1px solid #2e3136;
    color: inherit;
    display: block;
    text-align: center;
    text-decoration: none;
    padding: 14px 20px 13px; }

/* line 636, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.trpst-block-head {
  padding: 13px 20px 8px; }

/* line 641, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.trpst-block-main {
  padding: 0 20px; }

/* line 645, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.trpst-block-foot {
  background: #fcfcfc;
  color: #1f1f1f;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  text-transform: uppercase; }
  /* line 652, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trpst-block-foot a {
    color: inherit;
    display: block;
    text-decoration: none;
    padding: 13px 20px; }
    /* line 657, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .trpst-block-foot a:hover {
      color: #fd3a13; }
  /* line 661, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trpst-block-foot .arr-right-dark-ic {
    float: right; }

/* line 666, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters {
  color: #929292;
  float: right; }

/* line 671, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-list-1, .filters-list-2 {
  font: 400 13px/25px "Inconsolata";
  float: left;
  padding: 0 10px;
  text-transform: uppercase; }
  @media screen and (max-width: 767px) {
    /* line 671, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-list-1, .filters-list-2 {
      display: none; } }
  /* line 679, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-list-1 li, .filters-list-2 li {
    float: left; }
  /* line 682, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-list-1 a, .filters-list-2 a {
    color: inherit;
    display: block;
    padding: 0 10px;
    text-decoration: none; }
    /* line 687, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-list-1 a:hover, .filters-list-2 a:hover, .filters-list-1 a.active, .filters-list-2 a.active {
      color: #474747; }

/* line 694, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-more {
  border-left: 1px solid #ebebeb;
  font-size: 18px;
  float: left;
  padding: 1px 0 1px 18px; }
  /* line 700, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-more i:before {
    vertical-align: middle; }

/* line 706, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-btn {
  cursor: pointer; }

/* line 710, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-drop {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  display: none;
  width: 130px;
  padding: 9px 0;
  position: absolute;
  top: 99%;
  right: -5px;
  z-index: 1; }
  /* line 721, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-drop .arr {
    display: block;
    width: 20px;
    height: 10px;
    position: absolute;
    right: 24px;
    top: -10px;
    overflow: hidden; }
    /* line 729, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-drop .arr:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: #fff;
      transform: rotate(45deg);
      top: 5px;
      left: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.15); }
  /* line 741, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-drop a {
    color: #929292;
    display: block;
    font: 700 13px/28px "Inconsolata";
    padding: 0 16px;
    text-decoration: none;
    text-transform: uppercase; }
    /* line 748, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-drop a:hover, .filters-drop a.active {
      color: #fd3a13; }

/* line 755, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-list-2 {
  border: none;
  margin: 0;
  padding: 0; }
  /* line 760, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-list-2 a {
    padding: 0 0 0 6px; }

/* line 765, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-list-3 {
  font: 500 12px/21px "Poppins";
  text-transform: uppercase; }
  /* line 769, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-list-3 li {
    float: left;
    margin-right: 12px; }
  /* line 773, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-list-3 a {
    color: inherit;
    display: block;
    padding: 2px 0 17px;
    position: relative;
    text-decoration: none; }
    /* line 779, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-list-3 a:before {
      background: #fd3a13;
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 1px;
      opacity: 0;
      transform: scale(0.5, 1);
      transform-origin: 50% 0;
      transition: transform 300ms, opacity 200ms; }
    /* line 792, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .filters-list-3 a:hover, .filters-list-3 a.active {
      color: #000; }
      /* line 795, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .filters-list-3 a:hover:before, .filters-list-3 a.active:before {
        opacity: 1;
        transform: scale(1, 1); }

/* line 803, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-blc-1 {
  background-color: #f8f8f8;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  padding: 22px 24px; }
  /* line 811, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-blc-1 .members-items {
    background-color: #fff;
    border-radius: 2px;
    display: inline-block;
    font-size: 9px;
    line-height: 22px;
    text-align: center;
    min-width: 23px;
    padding: 0 3px;
    margin-left: 6px;
    vertical-align: middle; }
  /* line 823, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-blc-1 .filters-list {
    text-align: right; }
    @media screen and (max-width: 479px) {
      /* line 823, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .filters-blc-1 .filters-list {
        text-align: left;
        margin-top: 10px; } }

/* line 832, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.filters-blc-2 {
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px;
  padding: 32px 24px 7px; }
  /* line 840, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .filters-blc-2 .members-items {
    background-color: #fff;
    border-radius: 2px;
    display: inline-block;
    font-size: 9px;
    line-height: 22px;
    text-align: center;
    min-width: 23px;
    padding: 0 3px;
    margin-left: 6px;
    vertical-align: middle; }

/* line 854, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.all-sb {
  float: right; }
  /* line 856, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .all-sb a {
    color: #474747;
    display: inline-block;
    font-family: "Inconsolata";
    font-weight: 400;
    text-decoration: none;
    text-transform: uppercase; }
    /* line 863, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .all-sb a:hover {
      color: #fd3a13; }

/* line 869, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.inner-filters {
  color: #838181; }
  /* line 871, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .inner-filters + .pst-block-hr {
    margin-top: 0; }

/* line 876, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-navs {
  float: left;
  padding: 0 18px; }
  /* line 879, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-navs a {
    text-decoration: none; }

/* line 884, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.reviews-rate {
  color: #fff;
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 39px;
  height: 39px;
  font-size: 10px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 15px 0 0 15px; }
  /* line 898, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .reviews-rate:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0px;
    height: 0px;
    border-bottom: 39px solid #181818;
    border-left: 39px solid transparent; }
  /* line 909, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .reviews-rate span {
    position: relative;
    z-index: 2; }

/* line 915, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.top-rate {
  color: #fff;
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 39px;
  height: 39px;
  font-size: 10px;
  font-weight: 500;
  line-height: 25px;
  text-align: center;
  text-decoration: none;
  padding: 15px 15px 0 0; }
  /* line 929, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .top-rate:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 0px;
    height: 0px;
    border-bottom: 39px solid #181818;
    border-right: 39px solid transparent; }
  /* line 940, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .top-rate span {
    position: relative;
    z-index: 2; }

/* line 948, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.js-pst-block [class^="col-"],
.js-pst-block [class*=" col-"] {
  width: 100%; }

/* line 955, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.latest-blg .owl-item {
  padding: 0 10px; }
  /* line 957, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .latest-blg .owl-item.active + .active {
    border-left: 1px solid #f2f2f2; }

/* line 965, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.owl-pagination .owl-page,
.owl-pagination li,
.sbr-dots .owl-page,
.sbr-dots li {
  display: inline-block;
  margin: 2px; }
  /* line 971, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .owl-pagination .owl-page.active span, .owl-pagination .owl-page.slick-active span,
  .owl-pagination li.active span,
  .owl-pagination li.slick-active span,
  .sbr-dots .owl-page.active span,
  .sbr-dots .owl-page.slick-active span,
  .sbr-dots li.active span,
  .sbr-dots li.slick-active span {
    background: #fd3a13;
    border-color: #fd3a13; }

/* line 977, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.owl-pagination span,
.sbr-dots span {
  cursor: pointer;
  display: block;
  border: 1px solid #a9a9a9;
  border-radius: 50%;
  width: 7px;
  height: 7px; }

/* line 987, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbr-dots {
  text-align: center;
  padding: 15px 20px 11px; }

/* line 992, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-tp-1 {
  padding: 8px 0; }

/* line 997, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-list li {
  color: #fff;
  font-size: 12px;
  line-height: 19px;
  margin: 0 0 9px; }
  /* line 1002, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .social-list li:last-child {
    margin: 0; }
  /* line 1005, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .social-list li.fb {
    background: #516fc2; }
    /* line 1007, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-list li.fb .soc-ic {
      background: #4c68b3; }
  /* line 1011, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .social-list li.tw {
    background: #09b2f4; }
    /* line 1013, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-list li.tw .soc-ic {
      background: #00abed; }
  /* line 1017, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .social-list li.gp {
    background: #f73232; }
    /* line 1019, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-list li.gp .soc-ic {
      background: #eb1212; }

/* line 1024, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-list a {
  color: inherit;
  display: block;
  height: 33px;
  padding: 8px 14px 8px 46px;
  position: relative;
  text-decoration: none; }

/* line 1032, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-list .soc-btn {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  float: right;
  padding: 0 0 0 16px; }

/* line 1038, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-list .soc-ic {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 33px;
  font-size: 14px;
  line-height: 35px;
  text-align: center; }

/* line 1051, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.social-tp-2 {
  color: #1f2124; }
  /* line 1053, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .social-tp-2 ul {
    overflow: hidden; }
    /* line 1055, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul li {
      float: left;
      margin-right: 18px; }
    /* line 1059, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul a {
      color: inherit;
      display: inline-block;
      font-size: 12px;
      padding: 0 5px; }
    /* line 1065, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul i {
      margin: 0; }
    /* line 1068, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul .tw a:hover {
      color: #00abed; }
    /* line 1071, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul .fb a:hover {
      color: #4c68b3; }
    /* line 1074, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul .gp a:hover {
      color: #e51616; }
    /* line 1077, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul .in a:hover {
      color: #1d5e9f; }
    /* line 1080, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .social-tp-2 ul .dr a:hover {
      color: #f51f9d; }

/* line 1086, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-sharing-tp-1, .post-sharing-tp-2 {
  margin: 20px 0 33px; }
  /* line 1089, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 ul, .post-sharing-tp-2 ul {
    float: left; }
  /* line 1092, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 li, .post-sharing-tp-2 li {
    float: left;
    margin: 0 5px 5px 0; }
    /* line 1095, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 li:last-child, .post-sharing-tp-2 li:last-child {
      margin: 0; }
  /* line 1100, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 .fb a, .post-sharing-tp-2 .fb a {
    background-color: #526eac; }
    /* line 1102, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 .fb a:hover, .post-sharing-tp-2 .fb a:hover {
      background-color: #4360a0; }
  /* line 1108, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 .tw a, .post-sharing-tp-2 .tw a {
    background-color: #00abed; }
    /* line 1110, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 .tw a:hover, .post-sharing-tp-2 .tw a:hover {
      background-color: #0d9fd8; }
  /* line 1116, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 .vk a, .post-sharing-tp-2 .vk a {
    background-color: #426b9a; }
    /* line 1118, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 .vk a:hover, .post-sharing-tp-2 .vk a:hover {
      background-color: #355e8d; }
  /* line 1124, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 .gp a, .post-sharing-tp-2 .gp a {
    background-color: #eb4026; }
    /* line 1126, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 .gp a:hover, .post-sharing-tp-2 .gp a:hover {
      background-color: #e23319; }
  /* line 1131, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-1 a, .post-sharing-tp-2 a {
    border-radius: 2px;
    color: #fff;
    display: block;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    min-width: 125px;
    padding: 6px 15px;
    text-align: center;
    text-decoration: none; }
    @media screen and (max-width: 479px) {
      /* line 1131, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .post-sharing-tp-1 a, .post-sharing-tp-2 a {
        min-width: 56px; } }
    /* line 1145, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-sharing-tp-1 a .fa, .post-sharing-tp-2 a .fa {
      margin-right: 9px; }
      @media screen and (max-width: 479px) {
        /* line 1145, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
        .post-sharing-tp-1 a .fa, .post-sharing-tp-2 a .fa {
          margin: 0; } }

/* line 1154, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sharing-block {
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 20px 21px 25px; }
  /* line 1158, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sharing-block .sharing-title {
    color: #000;
    font-weight: 600;
    margin-bottom: 12px; }
  /* line 1163, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sharing-block .comments {
    color: #1f2124;
    float: right;
    font-size: 11px;
    font-weight: 500;
    padding: 5px 0;
    text-transform: uppercase; }
    @media screen and (max-width: 479px) {
      /* line 1163, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .sharing-block .comments {
        margin-top: 10px; } }
    /* line 1173, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sharing-block .comments i {
      color: #8a8a8a;
      line-height: 10px;
      display: inline-block;
      margin-right: 6px;
      vertical-align: middle; }

/* line 1183, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-sharing-tp-2 {
  margin: 0; }
  /* line 1186, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-sharing-tp-2 a {
    min-width: 83px; }
    @media screen and (max-width: 479px) {
      /* line 1186, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .post-sharing-tp-2 a {
        min-width: 45px; } }

/* line 1194, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sb-banner {
  background: #fff;
  padding: 20px;
  margin-bottom: 30px; }
  /* line 1198, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sb-banner .banner-inner {
    position: relative; }
    /* line 1200, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sb-banner .banner-inner:before {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 6px solid #fd3a13;
      border-right: 6px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
  /* line 1211, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sb-banner .banner-overlay {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 22px 20px; }

/* line 1220, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbsb-block-1 {
  background: #f7f7f7;
  padding: 36px 20px 40px;
  margin-bottom: 30px; }

/* line 1226, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbsb-form-1 {
  background: #fff;
  border-radius: 2px; }
  /* line 1229, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-1 .sbsb-form {
    display: table;
    width: 100%;
    padding: 4px; }
    /* line 1233, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sbsb-form-1 .sbsb-form > div {
      display: table-cell;
      vertical-align: middle; }
  /* line 1238, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-1 .sbsb-input {
    position: relative; }
    /* line 1240, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sbsb-form-1 .sbsb-input input {
      border: none;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 30px;
      width: 100%;
      padding: 5px 10px 5px 37px; }
  /* line 1249, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-1 .sbsb-icon {
    color: #8d8d8d;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 17px;
    line-height: 30px;
    padding: 0 0 0 11px; }
    /* line 1258, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sbsb-form-1 .sbsb-icon i:before {
      vertical-align: middle; }
  /* line 1262, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-1 .sbsb-btn {
    width: 88px; }
    /* line 1264, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sbsb-form-1 .sbsb-btn button {
      appearance: none;
      color: #fff;
      background-color: #fd3a13;
      border-radius: 2px;
      cursor: pointer;
      font: 500 11px/20px "Poppins";
      border: none;
      display: block;
      padding: 6px 10px 4px;
      min-width: 88px;
      text-align: center;
      text-transform: uppercase; }
      /* line 1277, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .sbsb-form-1 .sbsb-btn button:hover, .sbsb-form-1 .sbsb-btn button:active, .sbsb-form-1 .sbsb-btn button:focus {
        background-color: #fd5331; }

/* line 1286, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-form-1 {
  background: #fff;
  border-radius: 2px;
  overflow: hidden; }
  /* line 1290, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-1 .src-form {
    display: table;
    width: 100%;
    padding: 4px; }
    /* line 1294, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-1 .src-form > div {
      display: table-cell;
      vertical-align: middle; }
  /* line 1299, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-1 .src-input {
    position: relative; }
    /* line 1301, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-1 .src-input input {
      border: none;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 30px;
      width: 100%;
      padding: 5px 10px 5px 37px; }
  /* line 1310, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-1 .src-icon {
    color: #8d8d8d;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 17px;
    line-height: 30px;
    padding: 0 0 0 11px; }
    /* line 1319, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-1 .src-icon i:before {
      vertical-align: middle; }
  /* line 1323, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-1 .src-btn {
    width: 88px; }
    /* line 1325, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-1 .src-btn button {
      appearance: none;
      color: #fff;
      background-color: #fd3a13;
      border-radius: 2px;
      cursor: pointer;
      font: 500 11px/20px "Poppins";
      border: none;
      display: block;
      padding: 6px 10px 4px;
      min-width: 88px;
      text-align: center;
      text-transform: uppercase; }
      /* line 1338, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .search-form-1 .src-btn button:hover, .search-form-1 .src-btn button:active, .search-form-1 .src-btn button:focus {
        background-color: #f15232; }

/* line 1347, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-form-2 {
  background: #fff;
  border-radius: 2px;
  overflow: hidden; }
  /* line 1351, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-2 .src-form {
    display: table;
    width: 100%; }
    /* line 1355, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-2 .src-form > div {
      display: table-cell;
      vertical-align: middle; }
  /* line 1360, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-2 .src-input {
    position: relative; }
    /* line 1362, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-2 .src-input input {
      border: none;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 38px;
      width: 100%;
      padding: 5px 15px 5px 15px; }
  /* line 1371, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-2 .src-btn {
    width: 38px; }
    /* line 1373, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-2 .src-btn button {
      appearance: none;
      color: #fff;
      background-color: #fd3a13;
      border-radius: 2px;
      cursor: pointer;
      font: 500 13px/28px "Poppins";
      border: none;
      display: block;
      padding: 6px 10px 4px;
      width: 38px;
      text-align: center;
      text-transform: uppercase; }
      /* line 1386, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .search-form-2 .src-btn button:hover, .search-form-2 .src-btn button:active, .search-form-2 .src-btn button:focus {
        background-color: #f15232; }

/* line 1395, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-form-3 {
  padding: 8px 0; }
  /* line 1397, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-3 .src-input {
    position: relative; }
    /* line 1399, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-3 .src-input input {
      border: 1px solid #ededed;
      border-radius: 2px;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 38px;
      width: 100%;
      padding: 5px 15px 5px 15px; }
  /* line 1409, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .search-form-3 .src-icon {
    color: #a9a9a9;
    font-size: 13px;
    height: 38px;
    line-height: 38px;
    padding: 0 12px 0 0;
    position: absolute;
    right: 0;
    top: 0; }
    /* line 1418, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .search-form-3 .src-icon i {
      display: block;
      transform: scaleX(-1); }

/* line 1427, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbsb-form-2 .sbsb-input {
  background: #fff;
  border-radius: 2px;
  position: relative;
  margin: 0 0 6px; }
  /* line 1432, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-2 .sbsb-input input {
    border: none;
    color: #626465;
    font: 12px/20px "Noto Sans";
    height: 38px;
    width: 100%;
    padding: 9px 10px 9px 37px; }

/* line 1441, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbsb-form-2 .sbsb-icon {
  color: #8d8d8d;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 17px;
  line-height: 38px;
  padding: 0 0 0 11px; }
  /* line 1450, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-2 .sbsb-icon i:before {
    vertical-align: middle; }

/* line 1454, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbsb-form-2 .sbsb-btn {
  width: 100%; }
  /* line 1456, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sbsb-form-2 .sbsb-btn button {
    appearance: none;
    color: #fff;
    background-color: #fd3a13;
    border-radius: 2px;
    cursor: pointer;
    font: 500 11px/28px "Poppins";
    border: none;
    display: block;
    padding: 6px 10px 4px;
    width: 100%;
    text-align: center;
    text-transform: uppercase; }
    /* line 1469, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sbsb-form-2 .sbsb-btn button:hover, .sbsb-form-2 .sbsb-btn button:active, .sbsb-form-2 .sbsb-btn button:focus {
      background-color: #f15232; }

/* line 1479, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sbr-slider .pst-block-main,
.sbr-slider .post-tp-10 {
  padding: 0; }

/* line 1485, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.sidebar-slider {
  position: relative; }
  /* line 1487, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sidebar-slider figure {
    position: relative; }
  /* line 1490, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sidebar-slider .category-tp-1, .sidebar-slider .category-tp-3 {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 18px 20px; }
  /* line 1496, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sidebar-slider .sbr-navs {
    background: #181818;
    position: absolute;
    right: 0;
    bottom: 0; }
  /* line 1502, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .sidebar-slider .slick-arrow {
    cursor: pointer;
    width: 39px;
    height: 39px;
    padding: 17px 15px;
    float: left;
    position: relative; }
    /* line 1509, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sidebar-slider .slick-arrow i {
      vertical-align: top; }
    /* line 1512, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .sidebar-slider .slick-arrow.prev {
      margin-right: 1px; }
      /* line 1514, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .sidebar-slider .slick-arrow.prev:after {
        background: #3e3e3e;
        content: '';
        position: absolute;
        right: -1px;
        top: 12px;
        bottom: 12px;
        width: 1px; }

/* line 1535, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.tags-block {
  padding: 8px 0 5px; }

/* line 1539, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.tags-list {
  font-size: 0; }
  /* line 1542, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .tags-list li {
    float: left;
    margin: 0 5px 5px 0; }
  /* line 1546, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .tags-list a {
    background: #ededed;
    color: #1f1f1f;
    display: block;
    font: 700 10px/15px "Noto Sans";
    padding: 5px 8px;
    min-width: 52px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }

/* line 1559, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-tags {
  padding: 10px 0;
  margin-bottom: 25px; }

/* line 1564, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-tags-list {
  font-size: 0; }
  /* line 1567, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-tags-list li {
    float: left;
    margin: 0 5px 5px 0; }
  /* line 1571, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-tags-list a {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.03);
    color: #1f1f1f;
    display: block;
    font: 500 10px/15px "Poppins";
    padding: 5px 8px;
    min-width: 60px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase; }

/* line 1589, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .pst-block-main,
.ftrd-author .pst-block-main {
  padding: 0; }

/* line 1592, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .full-img,
.ftrd-author .full-img {
  margin-bottom: 18px;
  position: relative; }

/* line 1596, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .post-wrap,
.ftrd-author .post-wrap {
  padding: 0 20px 5px; }

/* line 1599, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .post-tp-11,
.ftrd-author .post-tp-11 {
  border-bottom: 1px dashed #ebebeb;
  padding-bottom: 14px;
  margin-bottom: 9px; }

/* line 1604, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .post-tp-6, .ltst-reviews .post-tp-9,
.ftrd-author .post-tp-6,
.ftrd-author .post-tp-9 {
  border-bottom: 1px dashed #ebebeb; }
  /* line 1606, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .ltst-reviews .post-tp-6:last-child, .ltst-reviews .post-tp-9:last-child,
  .ftrd-author .post-tp-6:last-child,
  .ftrd-author .post-tp-9:last-child {
    border: none; }

/* line 1610, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ltst-reviews .category-tp-1, .ltst-reviews .category-tp-3,
.ltst-reviews .category-tp-3,
.ftrd-author .category-tp-1,
.ftrd-author .category-tp-3,
.ftrd-author .category-tp-3 {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 18px 20px; }

/* line 1619, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.poling-block {
  padding: 10px 0; }

/* line 1623, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.poling-list {
  margin-bottom: 14px; }
  /* line 1625, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .poling-list li {
    padding: 3px 0; }

/* line 1631, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.info-tp-1 a {
  border-left: 1px solid #f6f6f6;
  color: #c5c5c5;
  display: inline-block;
  font-family: "Inconsolata";
  font-weight: 700;
  line-height: 25px;
  padding-left: 18px;
  margin-left: 16px;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none; }
  @media screen and (max-width: 1229px) {
    /* line 1631, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .info-tp-1 a {
      padding-left: 10px;
      margin-left: 8px; } }
  /* line 1647, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .info-tp-1 a:first-child {
    border: none;
    margin: 0;
    padding-left: 0; }
  /* line 1652, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .info-tp-1 a:hover {
    color: #fd3a13; }

/* line 1659, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .info-tp-1 {
  float: right; }

/* line 1663, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .post-list li {
  border-bottom: 1px dashed #ebebeb;
  padding: 14px 0 9px 26px;
  position: relative; }
  /* line 1667, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-widget .post-list li:last-child {
    border: none; }

/* line 1671, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .post-list p {
  color: #6f6f6f;
  font: 14px/23px 'Georgia'; }

/* line 1675, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .post-list a {
  color: #fd3a13;
  text-decoration: none; }

/* line 1679, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .post-list .date {
  color: #c7c7c7;
  font-family: "Inconsolata";
  font-size: 12px;
  font-weight: 700;
  margin: 3px 0;
  text-transform: uppercase; }

/* line 1687, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-widget .post-list .fa {
  color: #e7e7e7;
  font-size: 16px;
  position: absolute;
  left: 0;
  top: 17px; }

/* line 1698, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.map-widget .map {
  height: 298px;
  margin: -15px -20px; }

/* line 1706, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-widget .pst-block-head .arr-ic-3 {
  float: right; }

/* line 1710, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-widget .pst-block-main {
  padding: 15px 20px 0;
  margin-bottom: 0; }

/* line 1715, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-widget .title-4 i {
  color: #fd3a13;
  font-size: 14px;
  line-height: 14px; }

/* line 1723, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-block {
  color: #676666;
  font-size: 12px;
  min-height: 60px;
  padding: 5px 0 5px 112px;
  position: relative; }
  /* line 1729, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-block .temperature {
    position: absolute;
    left: 0; }
  /* line 1733, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-block i {
    display: inline-block;
    color: #fd3a13;
    font-size: 26px;
    margin-top: 5px;
    vertical-align: top; }

/* line 1742, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.day {
  color: #8e8e8e;
  font-size: 9px;
  line-height: 8px;
  text-align: right;
  text-transform: uppercase; }

/* line 1750, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.degrees-1 {
  color: #000;
  display: inline-block;
  font-size: 34px;
  font-weight: 500;
  line-height: 40px; }

/* line 1758, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.degrees-2 {
  color: #000;
  display: inline-block;
  font-size: 23px;
  font-weight: 500;
  line-height: 26px; }
  /* line 1764, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .degrees-2 i {
    display: inline-block;
    vertical-align: top;
    margin: 2px 4px; }

/* line 1771, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.degrees-ic-1 {
  display: inline-block;
  border: 2px solid #000;
  border-radius: 50%;
  width: 8px;
  height: 8px; }

/* line 1779, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.degrees-ic-2 {
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  width: 5px;
  height: 5px; }

/* line 1787, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-days {
  margin: -12px 0 0; }

/* line 1793, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.weather-days-list li {
  float: left;
  width: 20%;
  text-align: center; }
  /* line 1799, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-days-list li:hover a, .weather-days-list li.active a {
    background: rgba(235, 235, 235, 0.11); }
  /* line 1803, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-days-list li a {
    display: block;
    padding: 22px 0 25px;
    text-decoration: none; }
  /* line 1808, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-days-list li .degrees-2 {
    margin-right: -13px; }
  /* line 1811, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .weather-days-list li .day {
    text-align: center; }

/* line 1817, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-nav {
  color: #c3c3c3;
  font-size: 12px;
  font-weight: 500;
  margin: 14px 0 15px; }
  /* line 1823, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-nav .pn-item {
    color: #646464;
    border: 1px solid #ececec;
    border-radius: 50%;
    display: block;
    font-size: 12px;
    float: left;
    width: 33px;
    height: 33px;
    padding: 4px;
    margin: 0 5px 10px 0;
    text-align: center;
    text-decoration: none; }
    /* line 1836, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .page-nav .pn-item.current {
      background: #fd3a13;
      border-color: #fd3a13;
      color: #fff; }
  /* line 1842, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-nav .extend {
    display: block;
    font-family: "Montserrat";
    font-size: 12px;
    float: left;
    width: 33px;
    height: 33px;
    padding: 4px;
    margin: 0 5px 0 0;
    text-align: center;
    text-decoration: none; }
  /* line 1854, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-nav .page-count {
    display: block;
    float: right;
    line-height: 33px; }

/* line 1861, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-nav-prev-ic {
  background-image: url(../img/sprite.png);
  background-position: -18px 0px;
  width: 9px;
  height: 7px;
  display: inline-block; }

/* line 1866, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-nav-next-ic {
  background-image: url(../img/sprite.png);
  background-position: -9px 0px;
  width: 9px;
  height: 7px;
  display: inline-block; }

/* line 1871, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.go-top {
  border: 2px solid #b1b5bc;
  border-radius: 50%;
  color: #8e9399;
  font-size: 16px;
  line-height: 35px;
  display: block;
  width: 39px;
  height: 39px;
  position: fixed;
  top: 50%;
  right: 37px;
  margin-top: -20px;
  text-align: center; }
  @media screen and (max-width: 767px) {
    /* line 1871, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .go-top {
      display: none; } }

/* line 1890, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.banner {
  background: #fff;
  padding: 11px;
  margin: 45px 0; }

/* line 1898, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.lifestyle-pst .post-tp-17:last-child, .lifestyle-pst .post-tp-21:last-child {
  border: none;
  padding-bottom: 15px; }

/* line 1906, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.latest-pst .post-tp-15, .latest-pst .post-tp-18 {
  margin: 8px 0 18px; }

/* line 1913, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.latest-pst .post-tp-17:last-child, .latest-pst .post-tp-21:last-child {
  border: none;
  padding: 0 0 15px; }

/* line 1918, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.latest-pst .meta-tp-2 {
  margin: 4px 0 3px; }

/* line 1924, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.latest-nws .posts {
  margin-bottom: 25px; }

/* line 1930, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.trend-pst-tp-2 .pst-block-main {
  padding: 0; }
  /* line 1934, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .trend-pst-tp-2 .pst-block-main > div > div:first-child figure:after {
    display: none; }

/* line 1942, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.twitter-block {
  background: #121314;
  color: #fff;
  padding: 20px 20px 74px;
  position: relative; }
  /* line 1947, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block a {
    color: inherit;
    text-decoration: none; }
  /* line 1951, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block p {
    color: #fff;
    max-height: 92px;
    overflow: hidden; }
  /* line 1956, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-icon {
    font-size: 20px;
    padding: 5px 0; }
  /* line 1960, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-user {
    overflow: hidden;
    margin: 14px 0 18px; }
    /* line 1963, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .twitter-block .tb-user .photo {
      border-radius: 50%;
      float: left;
      overflow: hidden;
      width: 55px;
      height: 55px;
      margin: 0 12px 0 0; }
    /* line 1971, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .twitter-block .tb-user .tb-name {
      font-weight: 500;
      margin: 8px 0 0; }
    /* line 1975, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .twitter-block .tb-user .tb-login {
      color: #727272;
      font-size: 10px;
      line-height: 16px; }
  /* line 1981, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .time-date {
    display: block;
    color: #727272;
    font-size: 10px;
    margin: 7px 0; }
  /* line 1988, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-twitts .twitt {
    padding: 8px 0; }
  /* line 1991, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-twitts li {
    border-bottom: 1px solid #26282c;
    margin: 0 0 8px; }
  /* line 1996, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-bottom {
    height: 74px;
    line-height: 24px;
    padding: 25px 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%; }
  /* line 2005, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .td-action {
    float: left; }
    /* line 2007, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .twitter-block .td-action a {
      margin-right: 40px; }
      /* line 2009, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .twitter-block .td-action a:last-child {
        margin: 0; }
  /* line 2014, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .twitter-block .tb-nav {
    float: right;
    font-size: 7px; }

/* line 2021, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.animated {
  visibility: hidden; }

/* line 2025, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.fadeOutUp,
.fadeInUp,
.fadeIn {
  visibility: visible; }

/* line 2031, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-head-tile {
  margin: 29px 0 26px; }

/* line 2035, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.breadcrumbs-block {
  padding: 8px 0; }

/* line 2039, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-title {
  padding: 8px 0;
  position: relative; }
  /* line 2043, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-title .filters-more {
    border-color: #dddddd; }
  /* line 2047, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-title .filters-drop .arr {
    right: 4px; }

/* line 2053, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.breadcrumbs {
  color: #6f6f6f;
  font-family: "Noto Sans"; }
  /* line 2057, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .breadcrumbs .bc-item {
    float: left; }
  /* line 2060, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .breadcrumbs .fa {
    font-size: 10px;
    line-height: 23px;
    vertical-align: top;
    margin: 0 7px 0 5px; }
  /* line 2066, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .breadcrumbs a {
    color: inherit;
    text-decoration: none; }

/* line 2074, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.video-content .page-title .filters-more {
  border-color: #383a3e; }

/* line 2080, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.video-content .filters-list-1 a:hover, .video-content .filters-list-2 a:hover, .video-content .filters-list-1 a.active, .video-content .filters-list-2 a.active {
  color: #fffefe; }

/* line 2086, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.video-content .title-16 {
  color: #fff; }

/* line 2091, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.thumbs-slider {
  margin: -15px -20px 0; }

/* line 2095, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.thumbs-img {
  cursor: pointer; }
  /* line 2099, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .thumbs-img:hover figure:before {
    background-color: rgba(0, 0, 0, 0.5); }
  /* line 2104, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .thumbs-img figure {
    position: relative; }
    /* line 2106, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .thumbs-img figure:before {
      content: '';
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none; }
    /* line 2116, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .thumbs-img figure img {
      width: 100%; }

/* line 2122, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-tile {
  background-color: #f8f8f8;
  margin: 0 -20px 32px;
  padding: 40px 20px 10px; }
  @media screen and (max-width: 1077px) {
    /* line 2129, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-tile .col-eq > div .title-3 {
      height: 42px;
      overflow: hidden; } }

/* line 2138, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.tpg-block,
.scd-block {
  padding: 14px 0 0; }
  /* line 2141, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .tpg-block .bq-tp-3,
  .scd-block .bq-tp-3 {
    margin-right: -20px;
    margin-left: -20px; }

/* line 2148, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.scd-block:first-child {
  padding-top: 0; }

/* line 2153, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-content {
  padding: 10px 4px; }
  /* line 2155, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-content .title-blc-1,
  .post-content .title-blc-2 {
    padding: 0 24px;
    margin-right: -24px;
    margin-left: -24px; }
  /* line 2161, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-content .bq-tp-3 {
    margin-right: -24px;
    margin-left: -24px; }
  /* line 2166, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-content figure img {
    width: 100%; }
  @media screen and (max-width: 639px) {
    /* line 2171, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-content .align-left {
      float: none;
      margin: 6px 0 15px; } }

/* line 2178, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-main-img {
  margin: -25px -24px 35px;
  position: relative; }
  /* line 2181, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-img figure {
    position: relative; }
    /* line 2183, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-main-img figure:before {
      background: -webkit-linear-gradient(transparent, rgba(0, 0, 0, 0.36));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.36));
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%; }
  /* line 2193, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-img .post-main-overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  /* line 2201, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-img .post-main-data {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 24px; }
  /* line 2208, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-img .title-21 {
    color: #fff; }
  /* line 2211, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-img .meta-tp-5,
  .post-main-img .author-tp-2,
  .post-main-img .date-tp-4 {
    color: #fff; }

/* line 2218, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-main-tile {
  background-color: #f8f8f8;
  margin: -25px -24px 35px;
  padding: 45px 24px 15px; }
  /* line 2222, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-tile p {
    color: #aaa; }
  /* line 2225, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-main-tile .meta-tp-5 {
    margin-bottom: 12px; }

/* line 2230, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-video {
  margin: -25px -24px 35px; }

/* line 2234, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.small-gallery {
  margin: 0 -3px;
  padding: 10px 0 33px; }
  /* line 2238, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .small-gallery .item {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%;
    padding: 0 3px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .small-gallery .item:last-child {
      margin-right: 0; }
    @media screen and (max-width: 639px) {
      /* line 2238, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .small-gallery .item {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        padding: 3px; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .small-gallery .item:last-child {
          margin-right: 0; } }
  /* line 2246, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .small-gallery figure {
    position: relative; }
    /* line 2248, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .small-gallery figure:before {
      content: '';
      background-color: rgba(22, 23, 26, 0.15);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      opacity: 0; }
    /* line 2259, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .small-gallery figure img {
      width: 100%; }

/* line 2265, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.half-gallery {
  margin: -3px -3px -2px -2px;
  padding: 10px 0 33px; }
  /* line 2269, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .half-gallery .item {
    padding: 3px 3px 2px 2px; }

/* line 2274, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.medium-gallery {
  margin: -3px -3px -2px -2px;
  padding: 10px 0 33px; }
  /* line 2278, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .medium-gallery .item {
    padding: 3px 3px 2px 2px; }
  /* line 2281, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .medium-gallery .left {
    float: left;
    width: 55.6%; }
    @media screen and (max-width: 639px) {
      /* line 2281, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .medium-gallery .left {
        width: 100%; } }
  /* line 2288, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .medium-gallery .right {
    float: right;
    width: 44.4%; }
    @media screen and (max-width: 639px) {
      /* line 2288, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .medium-gallery .right {
        width: 100%; } }

/* line 2297, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.large-gallery {
  margin: -3px -27px -2px -26px;
  padding: 10px 0 33px; }
  /* line 2301, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .large-gallery .item {
    padding: 3px 3px 2px 2px; }
  /* line 2304, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .large-gallery .left {
    float: left;
    width: 64.104%; }
    @media screen and (max-width: 639px) {
      /* line 2304, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .large-gallery .left {
        width: 100%; } }
  /* line 2311, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .large-gallery .right {
    float: right;
    width: 35.896%; }
    @media screen and (max-width: 639px) {
      /* line 2311, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .large-gallery .right {
        width: 100%; } }

/* line 2320, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-img-1 {
  margin-bottom: 17px; }
  /* line 2322, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-img-1 figcaption {
    color: #626465;
    font: italic 400 12px/24px "Noto Sans";
    padding: 8px 0; }

/* line 2329, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-img-2 {
  margin-bottom: 23px; }
  /* line 2331, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-img-2 figcaption {
    color: #626465;
    font: italic 400 12px/24px "Noto Sans";
    padding: 8px 0; }

/* line 2338, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-img-3 {
  margin: 0 -24px 23px; }
  /* line 2340, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-img-3 figcaption {
    color: #626465;
    font: italic 400 12px/24px "Noto Sans";
    padding: 8px 0; }

/* line 2347, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.post-next-prev {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  color: #a9a9a9;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 40px 0; }
  /* line 2356, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-next-prev a {
    color: inherit;
    display: block;
    padding: 25px;
    position: relative;
    text-decoration: none; }
    /* line 2362, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-next-prev a:hover {
      color: #000; }
      /* line 2364, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .post-next-prev a:hover .fa {
        color: #fd3a13; }
    /* line 2368, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-next-prev a .fa {
      color: #bbb;
      font-size: 17px;
      position: absolute;
      top: 50%;
      margin-top: -8px; }
  /* line 2376, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-next-prev .post-prev,
  .post-next-prev .post-next {
    width: 50%;
    float: left; }
  /* line 2381, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-next-prev .post-prev {
    text-align: right; }
    /* line 2383, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-next-prev .post-prev a {
      border-right: 1px solid #f1f1f1;
      margin-right: -1px; }
    /* line 2387, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .post-next-prev .post-prev .fa {
      left: 0; }
  /* line 2392, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-next-prev .post-next a {
    border-left: 1px solid #f1f1f1; }
  /* line 2395, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .post-next-prev .post-next .fa {
    right: 0; }

/* line 2401, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.author-box-1 {
  background-color: #f8f8f8;
  padding: 28px 20px 27px 132px;
  position: relative;
  margin-bottom: 27px; }
  @media screen and (max-width: 479px) {
    /* line 2401, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .author-box-1 {
      padding: 28px 20px 27px 20px; } }
  /* line 2409, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-1 .author-photo {
    background-color: #fff;
    border-radius: 50%;
    width: 95px;
    height: 95px;
    overflow: hidden;
    position: absolute;
    left: 20px;
    top: 22px; }
    @media screen and (max-width: 479px) {
      /* line 2409, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-photo {
        display: none; } }
  /* line 2422, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-1 .author-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 1px; }
  /* line 2428, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-1 .author-profession {
    color: #8e8e8e;
    font: italic 12px/22px "Georgia";
    margin-bottom: 6px; }
  /* line 2433, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-1 .author-description {
    color: #6f6f6f;
    font: 13px/21px "Georgia";
    margin-bottom: 8px; }
  /* line 2438, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-1 .author-social {
    color: #000; }
    /* line 2440, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .author-box-1 .author-social ul {
      overflow: hidden; }
      /* line 2442, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul li {
        float: left;
        margin-right: 8px; }
      /* line 2446, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul a {
        color: inherit;
        display: inline-block;
        padding: 0 5px; }
      /* line 2451, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul i {
        margin: 0; }
      /* line 2454, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul .tw a:hover {
        color: #00abed; }
      /* line 2457, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul .fb a:hover {
        color: #4c68b3; }
      /* line 2460, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul .gp a:hover {
        color: #e51616; }
      /* line 2463, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul .in a:hover {
        color: #1d5e9f; }
      /* line 2466, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-1 .author-social ul .dr a:hover {
        color: #f51f9d; }

/* line 2473, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.author-box-2, .author-box-3 {
  background-color: #f8f8f8;
  padding: 36px 20px 25px;
  position: relative;
  margin-bottom: 30px;
  text-align: center; }
  /* line 2479, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-2 .author-photo, .author-box-3 .author-photo {
    background-color: #fff;
    border-radius: 50%;
    width: 134px;
    height: 134px;
    margin: 0 auto 16px;
    overflow: hidden; }
  /* line 2487, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-2 .author-name, .author-box-3 .author-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px; }
  /* line 2492, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-2 .author-profession, .author-box-3 .author-profession {
    color: #8e8e8e;
    font: italic 12px/22px "Georgia";
    margin-bottom: 6px; }
  /* line 2497, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-2 .author-description, .author-box-3 .author-description {
    color: #6f6f6f;
    font: 13px/21px "Georgia";
    margin-bottom: 12px; }
  /* line 2502, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .author-box-2 .author-social, .author-box-3 .author-social {
    color: #000;
    display: inline-block; }
    /* line 2505, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .author-box-2 .author-social ul, .author-box-3 .author-social ul {
      overflow: hidden; }
      /* line 2507, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul li, .author-box-3 .author-social ul li {
        float: left;
        margin-right: 8px; }
      /* line 2511, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul a, .author-box-3 .author-social ul a {
        color: inherit;
        display: inline-block;
        padding: 0 5px; }
      /* line 2516, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul i, .author-box-3 .author-social ul i {
        margin: 0; }
      /* line 2519, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul .tw a:hover, .author-box-3 .author-social ul .tw a:hover {
        color: #00abed; }
      /* line 2522, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul .fb a:hover, .author-box-3 .author-social ul .fb a:hover {
        color: #4c68b3; }
      /* line 2525, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul .gp a:hover, .author-box-3 .author-social ul .gp a:hover {
        color: #e51616; }
      /* line 2528, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul .in a:hover, .author-box-3 .author-social ul .in a:hover {
        color: #1d5e9f; }
      /* line 2531, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .author-box-2 .author-social ul .dr a:hover, .author-box-3 .author-social ul .dr a:hover {
        color: #f51f9d; }

/* line 2540, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.author-box-3 .author-description {
  padding: 0 10px; }

/* line 2545, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.comments-block {
  margin-bottom: 49px; }

/* line 2550, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.comment-list .comment {
  padding: 0 0 0 61px;
  position: relative; }
  /* line 2553, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment .comment-photo {
    background-color: #e8e6df;
    border-radius: 50%;
    width: 49px;
    height: 49px;
    position: absolute;
    left: 0;
    top: 4px;
    overflow: hidden; }
  /* line 2563, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment .title-20 {
    margin-bottom: 4px; }
  /* line 2566, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment article {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 13px;
    margin-bottom: 18px; }
    /* line 2571, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .comment-list .comment article:hover .reply {
      opacity: 1; }
  /* line 2576, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment p {
    color: #6f6f6f;
    font: 400 15px/25px "Georgia";
    margin-bottom: 5px; }
  /* line 2581, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment .comment-date {
    color: #afafaf;
    display: inline-block;
    font-size: 11px;
    line-height: 23px;
    margin-right: 10px; }
  /* line 2588, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comment-list .comment .reply {
    cursor: pointer;
    color: #fd3a13;
    display: inline-block;
    font-size: 11px;
    font-weight: 600;
    line-height: 23px;
    margin-right: 10px;
    opacity: 0; }
    @media screen and (max-width: 479px) {
      /* line 2588, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .comment-list .comment .reply {
        opacity: 1; } }

/* line 2604, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.comments-form {
  padding: 12px 0 7px;
  margin-top: -20px; }
  /* line 2607, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comments-form .btn-3 {
    margin: 8px 0; }
  /* line 2610, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .comments-form .half,
  .comments-form .full {
    padding: 0 15px; }
    /* line 2614, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .comments-form .half .form-control input,
    .comments-form .full .form-control input {
      width: 100%; }

/* line 2622, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.form-control label {
  color: #000;
  font: 500 11px/21px "Poppins";
  text-transform: uppercase; }

/* line 2627, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.form-control input {
  appearance: none;
  background-color: #f5f5f5;
  border: none;
  border-radius: 2px;
  font: 500 13px/25px "Poppins";
  display: block;
  width: 480px;
  max-width: 100%;
  padding: 10px;
  margin: 7px 0 12px; }

/* line 2639, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.form-control textarea {
  appearance: none;
  background-color: #f5f5f5;
  border: none;
  border-radius: 2px;
  resize: none;
  font: 500 13px/25px "Poppins";
  display: block;
  width: 100%;
  height: 164px;
  padding: 10px;
  margin: 7px 0 12px; }

/* line 2652, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.form-control .rate-tp-2 {
  margin: 4px 0; }

/* line 2657, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.img-slider {
  margin-bottom: 34px; }
  /* line 2660, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .img-slider.tp-2 .slider {
    margin-left: -24px;
    margin-right: -24px; }
  /* line 2665, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .img-slider .navs {
    color: #6f6f6f;
    font: 400 11px/24px "Georgia"; }
    /* line 2668, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .img-slider .navs > div {
      display: inline-block; }
    /* line 2671, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .img-slider .navs .fa {
      color: #626465;
      font-size: 15px;
      line-height: 26px; }
    /* line 2676, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .img-slider .navs .slide-count {
      margin: 0 4px; }
    /* line 2679, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .img-slider .navs .prev,
    .img-slider .navs .next {
      border: 2px solid #dedede;
      border-radius: 50%;
      cursor: pointer;
      width: 30px;
      height: 30px;
      text-align: center; }

/* line 2691, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-content {
  padding: 20px; }
  /* line 2693, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-content .posts {
    padding: 8px 0; }

/* line 2699, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.team-person-blocks .half,
.team-person-blocks .one-third {
  padding-left: 15px;
  padding-right: 15px; }

/* line 2706, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.magazine-details {
  padding: 1px 0 5px; }

/* line 2710, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.details-info {
  color: #626465;
  font: 400 14px/25px "Noto Sans"; }
  /* line 2713, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .details-info > div {
    margin-bottom: 10px; }
  /* line 2719, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .details-info .address i,
  .details-info .phone i,
  .details-info .email i {
    font-size: 15px;
    line-height: 24px;
    vertical-align: middle;
    margin-right: 6px; }
  /* line 2727, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .details-info .email a {
    color: #fd3a13;
    text-decoration: none; }
  /* line 2732, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .details-info .social-tp-2 {
    padding: 8px 0 10px; }

/* line 2737, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.contactf-form-block {
  padding: 1px 0 0; }

/* line 2741, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-pane {
  background-color: #f8f8f8;
  margin: -15px -20px 30px;
  padding: 30px 20px; }

/* line 2747, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.page-404 {
  padding: 170px 0 57px; }
  /* line 2749, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-404 .search-form-2 {
    max-width: 486px;
    margin-top: 43px;
    margin-bottom: 50px; }
  /* line 2754, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .page-404 h2 {
    padding: 13px 0 5px; }

/* line 2759, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.large-404 {
  color: #dedede;
  font: 600 200px/188px "Poppins"; }
  @media screen and (max-width: 767px) {
    /* line 2759, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .large-404 {
      font-size: 150px;
      line-height: 148px; } }

/* line 2768, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.tags {
  padding-top: 17px; }

/* line 2772, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.review-rating-tp-1,
.review-rating-tp-3 {
  margin: -2px -3px -3px -2px;
  padding: 10px 0 30px; }
  /* line 2776, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .review-rating-tp-1 .percent,
  .review-rating-tp-3 .percent {
    font-size: 12px;
    font-weight: 400;
    line-height: 26px; }

/* line 2783, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rw-item {
  padding: 2px 3px 3px 2px; }
  /* line 2785, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rw-item.lg {
    float: left;
    width: 80%; }
    @media screen and (max-width: 639px) {
      /* line 2785, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .rw-item.lg {
        width: 100%; } }
  /* line 2792, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rw-item.sm {
    float: left;
    width: 20%; }
    @media screen and (max-width: 639px) {
      /* line 2792, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .rw-item.sm {
        width: 100%; } }
    /* line 2798, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .rw-item.sm .rw-rating {
      padding: 24px 12px 18px; }
      @media screen and (max-width: 767px) {
        /* line 2798, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
        .rw-item.sm .rw-rating {
          padding: 24px 8px 18px; } }

/* line 2807, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rw-rating {
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 24px 24px 18px; }

/* line 2813, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rating-line-tp-1 {
  margin-bottom: 18px; }

/* line 2817, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rating-line-tp-2 {
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 10px;
  margin-bottom: 13px; }
  /* line 2821, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rating-line-tp-2:last-child {
    border: none;
    padding: 0;
    margin-bottom: 2px; }
  /* line 2826, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rating-line-tp-2 .rate-tp-2 {
    float: right; }
    @media screen and (max-width: 479px) {
      /* line 2829, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .rating-line-tp-2 .rate-tp-2.mb-left {
        float: left; } }

/* line 2838, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rating-title .title-23 {
  float: left; }

/* line 2841, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rating-title .percent {
  float: right; }

/* line 2846, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rating-meter {
  background-color: #e3e3e3;
  border-radius: 2px;
  height: 4px;
  position: relative;
  margin-top: 6px; }
  /* line 2852, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .rating-meter > span {
    background-color: #fd3a13;
    border-radius: 2px;
    display: block;
    height: 100%; }

/* line 2862, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rw-summary .title-6 {
  line-height: 24px;
  margin-bottom: 2px; }

/* line 2866, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.rw-summary p {
  color: #626465;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 12px; }

/* line 2874, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.result-tp-1, .result-tp-2 {
  color: #000;
  font-weight: 600;
  line-height: 14px;
  padding: 23px 0;
  text-align: center; }
  /* line 2880, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .result-tp-1 span, .result-tp-2 span {
    display: block; }
  /* line 2883, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .result-tp-1 .num, .result-tp-2 .num {
    display: block;
    font-size: 19px; }
    /* line 2886, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .result-tp-1 .num strong, .result-tp-2 .num strong {
      display: inline-block;
      font-size: 40px;
      line-height: 38px; }

/* line 2894, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.result-tp-2 {
  padding: 5px 0 4px; }
  /* line 2897, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .result-tp-2 .rate-tp-2 {
    float: none;
    display: inline-block;
    margin-top: 8px; }
    /* line 2902, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .result-tp-2 .rate-tp-2 li.active {
      color: #000; }

/* line 2909, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.review-rating-tp-2 {
  margin: -2px -3px -3px -2px;
  padding: 10px 0 30px; }

/* line 2914, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.drop-box-1 {
  color: #616161;
  cursor: pointer;
  font-weight: 400;
  display: inline-block;
  margin-left: 4px; }
  /* line 2920, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .drop-box-1 .fa {
    margin-left: 4px; }

/* line 2925, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members {
  padding: 15px 0 31px; }

/* line 2929, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-items {
  margin: -12px; }
  /* line 2932, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-items .one-third {
    padding: 12px; }

/* line 2937, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-item {
  background-color: #fafafa;
  display: block;
  padding: 42px 10px 36px;
  position: relative;
  text-align: center;
  text-decoration: none; }
  /* line 2944, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 6px solid #fd3a13;
    border-right: 6px solid transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0; }
  /* line 2956, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item:hover:before {
    opacity: 1; }
  /* line 2960, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item:hover .members-photo:before {
    background-color: rgba(0, 0, 0, 0.11); }
  /* line 2963, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item:hover .members-photo:after {
    opacity: 1; }
  /* line 2968, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item .members-photo {
    background-color: #fff;
    border-radius: 50%;
    width: 134px;
    height: 134px;
    margin: 0 auto 16px;
    position: relative;
    overflow: hidden; }
    /* line 2976, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .members-item .members-photo:before {
      content: '';
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1; }
    /* line 2986, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .members-item .members-photo:after {
      display: inline-block;
      font: normal normal normal 6px/134px FontAwesome;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\f111 \f111 \f111';
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      letter-spacing: 2px;
      z-index: 2; }
  /* line 3004, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-item .members-name {
    color: #000;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px; }

/* line 3012, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbs-pagination {
  color: #656565;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 20px; }

/* line 3022, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.profile .title-blc-2,
.groups .title-blc-2,
.friends .title-blc-2,
.forums .title-blc-2 {
  margin-top: 7px; }

/* line 3027, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mess {
  border: 1px solid #656565;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  padding: 12px 17px; }
  /* line 3034, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mess.info {
    color: #656565;
    border-color: #f6d0c9; }

/* line 3041, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.profile-fields .name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; }

/* line 3049, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-members {
  background-color: #f8f8f8;
  padding: 30px 24px; }

/* line 3054, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.search-forums {
  background-color: #f8f8f8;
  padding: 30px 24px; }

/* line 3059, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmb-form {
  background: #fff;
  border-radius: 2px; }
  /* line 3062, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmb-form .mmb-form {
    width: 100%;
    padding: 4px; }
  /* line 3066, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmb-form .mmb-input {
    position: relative; }
    /* line 3068, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmb-form .mmb-input input {
      border: none;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 30px;
      width: 100%;
      padding: 5px 10px 5px 37px; }
  /* line 3077, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmb-form .mmb-icon {
    color: #8d8d8d;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 17px;
    line-height: 30px;
    padding: 0 0 0 11px; }
    /* line 3086, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmb-form .mmb-icon i:before {
      vertical-align: middle; }

/* line 3092, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.srcf-form {
  background: #fff;
  border-radius: 2px; }
  /* line 3095, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .srcf-form .srcf-form {
    width: 100%;
    padding: 4px; }
  /* line 3099, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .srcf-form .srcf-input {
    position: relative; }
    /* line 3101, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .srcf-form .srcf-input input {
      border: none;
      color: #626465;
      font: 12px/20px "Noto Sans";
      height: 30px;
      width: 100%;
      padding: 5px 37px 5px 12px; }
  /* line 3110, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .srcf-form .srcf-icon {
    color: #8d8d8d;
    display: block;
    position: absolute;
    top: 0;
    right: 12px;
    font-size: 13px;
    line-height: 30px; }
    /* line 3118, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .srcf-form .srcf-icon i {
      display: block;
      transform: scaleX(-1); }
      /* line 3121, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .srcf-form .srcf-icon i:before {
        vertical-align: middle; }

/* line 3129, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-list .mls-item {
  border-bottom: 1px solid #f7f7f7;
  position: relative;
  padding: 23px 0 24px 62px;
  min-height: 80px; }
  /* line 3134, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-list .mls-item:last-child {
    border: none; }

/* line 3138, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-list .mls-photo {
  background-color: #f8f8f8;
  border-radius: 50%;
  width: 49px;
  height: 49px;
  position: absolute;
  left: 0;
  top: 15px;
  overflow: hidden; }
  /* line 3147, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .members-list .mls-photo img {
    display: block; }

/* line 3151, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-list .mls-name {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; }

/* line 3157, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.members-list .mls-date {
  color: #939393;
  font-size: 11px;
  line-height: 13px; }

/* line 3164, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.member-main {
  background-color: #fff;
  overflow: hidden; }

/* line 3169, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmb-blc {
  background-color: #f8f8f8;
  margin-bottom: 3px;
  padding: 13px 24px;
  font-size: 13px;
  font-weight: 500;
  line-height: 26px; }

/* line 3178, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.member-info {
  padding: 40px 24px 33px;
  text-align: center; }

/* line 3183, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbi-photo {
  background-color: #fff;
  border-radius: 50%;
  width: 163px;
  height: 163px;
  margin: 0 auto 28px;
  overflow: hidden; }

/* line 3192, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbi-name {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 4px; }

/* line 3200, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbi-nick {
  color: #ababab;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 4px; }

/* line 3208, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbi-active {
  color: #898989;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 17px; }

/* line 3216, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbi-description {
  color: #898989;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px; }
  /* line 3221, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbi-description a {
    color: #000;
    text-decoration: none; }

/* line 3229, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.member-filter .mf-item {
  margin-right: 25px;
  float: left; }
  /* line 3233, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .member-filter .mf-item.active a {
    color: #000; }
  /* line 3237, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .member-filter .mf-item a {
    display: block;
    color: #828282;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    text-transform: uppercase;
    text-decoration: none; }
    @media screen and (max-width: 479px) {
      /* line 3237, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .member-filter .mf-item a {
        padding: 5px 0; } }
    /* line 3248, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .member-filter .mf-item a span {
      background-color: #fff;
      color: #000;
      display: inline-block;
      font-size: 9px;
      line-height: 21px;
      margin: 0 0 0 6px;
      padding: 0 4px;
      min-width: 23px;
      vertical-align: middle;
      text-align: center; }

/* line 3266, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mmbs-item {
  background-color: #f8f8f8;
  min-height: 124px;
  padding: 30px 10px 28px 123px;
  position: relative;
  margin-bottom: 20px; }
  @media screen and (max-width: 479px) {
    /* line 3266, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item {
      padding: 30px 10px 28px 83px; } }
  /* line 3275, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-photo {
    background-color: #fff;
    border-radius: 50%;
    width: 82px;
    height: 82px;
    position: absolute;
    left: 21px;
    top: 21px;
    overflow: hidden; }
    @media screen and (max-width: 479px) {
      /* line 3275, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .mmbs-item .mmbs-item-photo {
        width: 62px;
        height: 62px;
        left: 11px; } }
  /* line 3290, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-title {
    color: #8f8f8f;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 7px; }
    /* line 3296, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-title b {
      color: #000; }
    /* line 3299, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-title .mmbs-icon-photo {
      background-color: #fff;
      border-radius: 50%;
      display: inline-block;
      width: 23px;
      height: 23px;
      margin: -2px 5px 0;
      vertical-align: middle;
      overflow: hidden; }
  /* line 3310, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-date {
    color: #ababab;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 8px;
    text-decoration: none; }
    /* line 3317, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-date i {
      font-size: 13px; }
  /* line 3321, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-date-active {
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin: 8px 0;
    text-decoration: none; }
    /* line 3328, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-date-active i {
      font-size: 13px; }
  /* line 3332, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-description {
    color: #626465;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px; }
    /* line 3337, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-description a {
      color: #000;
      text-decoration: none; }
  /* line 3342, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .mmbs-item-text {
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px; }
    /* line 3347, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mmbs-item .mmbs-item-text a {
      color: #000;
      text-decoration: none; }
  /* line 3352, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mmbs-item .btn-4 {
    min-width: 142px;
    margin: 15px 0 6px; }

/* line 3358, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.member-sub-stream {
  margin: 23px 0 0; }
  @media screen and (max-width: 479px) {
    /* line 3358, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .member-sub-stream {
      margin: 23px 0 0 -40px; } }
  /* line 3363, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .member-sub-stream .mmbs-item {
    min-height: 0;
    padding: 0 0 21px 62px;
    margin: 0; }
    /* line 3367, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .member-sub-stream .mmbs-item:last-child {
      padding-bottom: 0; }
    /* line 3370, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .member-sub-stream .mmbs-item .mmbs-item-photo {
      left: 0;
      top: -2px;
      width: 49px;
      height: 49px; }

/* line 3380, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.fs-list-block .fs-item {
  color: #414141;
  border-bottom: 1px solid #f7f7f7;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  padding: 10px 0 10px 12px;
  position: relative; }
  /* line 3388, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .fs-list-block .fs-item:last-child {
    border: none; }
  /* line 3391, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .fs-list-block .fs-item:after {
    display: inline-block;
    font: normal normal normal 12px/38px FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\f105';
    color: #000;
    position: absolute;
    left: 0;
    top: 0; }
  /* line 3403, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .fs-list-block .fs-item .count {
    float: right;
    font-weight: 400; }
  /* line 3407, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .fs-list-block .fs-item a {
    color: inherit;
    display: block;
    text-decoration: none; }

/* line 3416, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forum-block .breadcrumbs-block {
  padding: 0 0 23px; }

/* line 3423, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forum-list-block {
  border-bottom: 7px solid #f8f8f8;
  margin-bottom: 30px; }

/* line 3428, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forums-comment-block {
  margin-bottom: 30px; }
  /* line 3432, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .forums-comment-block .forum-titles .bbp-reply-author,
  .forums-comment-block .bbp-footer .bbp-reply-author {
    width: 15%; }
    @media screen and (max-width: 479px) {
      /* line 3432, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .forums-comment-block .forum-titles .bbp-reply-author,
      .forums-comment-block .bbp-footer .bbp-reply-author {
        width: 83px; } }
  /* line 3438, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .forums-comment-block .forum-titles .bbp-reply-content,
  .forums-comment-block .bbp-footer .bbp-reply-content {
    width: 85%; }
    @media screen and (max-width: 479px) {
      /* line 3438, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .forums-comment-block .forum-titles .bbp-reply-content,
      .forums-comment-block .bbp-footer .bbp-reply-content {
        width: auto; } }

/* line 3448, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forums-list .bbp-forum-topic {
  width: 51%; }
  @media screen and (max-width: 479px) {
    /* line 3448, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .forums-list .bbp-forum-topic {
      width: 100%;
      margin-bottom: 20px; } }

/* line 3455, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forums-list .bbp-forum-voices-count {
  width: 12%;
  font-size: 12px;
  text-align: center; }
  @media screen and (max-width: 479px) {
    /* line 3455, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .forums-list .bbp-forum-voices-count {
      width: 28%;
      text-align: left; } }

/* line 3464, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forums-list .bbp-forum-posts-count {
  width: 12%;
  font-size: 12px;
  text-align: center; }
  @media screen and (max-width: 479px) {
    /* line 3464, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .forums-list .bbp-forum-posts-count {
      width: 26%;
      text-align: left; } }

/* line 3473, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forums-list .bbp-forum-freshness {
  width: 25%;
  text-align: center; }
  @media screen and (max-width: 479px) {
    /* line 3473, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .forums-list .bbp-forum-freshness {
      width: 46%;
      text-align: left; } }
  /* line 3480, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .forums-list .bbp-forum-freshness > a {
    color: #656565;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none; }

/* line 3489, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-header {
  background-color: #1f2124;
  border-radius: 3px;
  padding: 10px 17px; }
  /* line 3493, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-header li {
    float: left; }

/* line 3499, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-body li {
  float: left; }

/* line 3504, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-footer {
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 10px 17px; }
  /* line 3508, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-footer li {
    float: left; }
  /* line 3511, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-footer .forum-titles {
    color: #000; }

/* line 3516, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.forum-titles {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase; }

/* line 3525, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-forum {
  padding: 25px 17px; }
  /* line 3528, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-forum:nth-child(even) {
    background-color: #fcfcfc; }

/* line 3533, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-forum-title {
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 4px;
  text-decoration: none; }

/* line 3542, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-forum-content {
  color: #656565;
  font-size: 11px;
  font-weight: 500; }

/* line 3548, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-topic-started-by {
  color: #656565;
  font-size: 11px;
  font-weight: 500; }
  /* line 3552, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-topic-started-by a {
    color: #000;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none; }

/* line 3560, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-meta {
  margin: 0; }

/* line 3564, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-author {
  color: #000;
  font-size: 11px;
  font-weight: 500;
  text-decoration: none; }

/* line 3571, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-author-avatar {
  background-color: #f8f8f8;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin: -2px 5px 0;
  vertical-align: middle;
  overflow: hidden; }

/* line 3582, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-pagination {
  padding: 0 0 22px;
  margin: -6px 0 0; }
  /* line 3585, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-pagination .bbp-pagination-count {
    color: #656565;
    font-size: 12px;
    font-weight: 400; }

/* line 3592, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.bbp-reply-post {
  min-height: 195px;
  margin: 4px 0; }
  /* line 3595, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-reply-post .bbp-reply-header {
    background-color: #f8f8f8;
    color: #646464;
    padding: 5px 18px;
    font-size: 11px;
    font-weight: 500;
    line-height: 24px;
    text-align: right; }
    /* line 3603, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-header .bbp-reply-post-date {
      float: left; }
    /* line 3606, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-header a {
      color: inherit;
      text-decoration: none; }
    /* line 3610, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-header .bbp-reply-permalink {
      margin-left: 10px; }
  /* line 3614, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .bbp-reply-post .bbp-reply-topic {
    position: relative;
    padding: 30px 0 30px 100px; }
    /* line 3617, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-topic .bbp-reply-author {
      position: absolute;
      left: 7px;
      top: 25px;
      max-width: 90px; }
    /* line 3623, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-topic .bbp-reply-avatar {
      background-color: #f8f8f8;
      border-radius: 50%;
      display: block;
      width: 72px;
      height: 72px;
      margin: 0 auto 10px;
      overflow: hidden; }
    /* line 3632, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-topic .bbp-author-name {
      color: #000;
      display: block;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      text-decoration: none; }
    /* line 3640, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .bbp-reply-post .bbp-reply-topic .bbp-author-role {
      color: #8c8c8c;
      display: block;
      font: italic 400 11px/12px "Georgia";
      text-align: center; }

/* line 3651, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-products-items {
  margin: -9px -9px 3px; }
  /* line 3654, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-items .one-third {
    padding: 9px; }

/* line 3659, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-products-item {
  text-align: center; }
  /* line 3661, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-item .shop-products-item-img {
    background-color: #fafafa;
    position: relative; }
    /* line 3665, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-products-item .shop-products-item-img:hover .shop-products-item-overlay {
      opacity: 1;
      visibility: visible; }
    /* line 3670, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-products-item .shop-products-item-img a {
      display: block; }
    /* line 3673, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-products-item .shop-products-item-img img {
      display: block;
      max-width: 100%;
      width: 100%; }
  /* line 3679, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-item .shop-products-item-overlay {
    background-color: rgba(22, 23, 26, 0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    pointer-events: none; }
    /* line 3690, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-products-item .shop-products-item-overlay:after {
      content: '';
      width: 0;
      height: 0;
      border-bottom: 6px solid #fd3a13;
      border-right: 6px solid transparent;
      position: absolute;
      left: 0;
      bottom: 0; }
  /* line 3701, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-item .btn-5 {
    pointer-events: auto;
    min-width: 125px; }
  /* line 3705, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-item .shop-products-item-name {
    color: #202020;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 15px; }
    /* line 3711, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-products-item .shop-products-item-name a {
      color: inherit;
      text-decoration: none; }
  /* line 3716, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-products-item .shop-products-item-price {
    color: #fd3a13;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 7px; }

/* line 3726, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart-widget .cart-products {
  color: #626465;
  font: 13px/22px "Noto Sans";
  padding: 5px 0; }
  /* line 3730, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .cart-widget .cart-products i {
    margin: -3px 6px 0 0; }

/* line 3736, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.lpw-list {
  margin-top: 11px; }
  /* line 3738, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .lpw-list .fs-item {
    border-bottom: 1px solid #f0f0f0;
    min-height: 72px;
    position: relative;
    padding: 3px 0 13px 88px;
    margin: 0 0 13px; }
    /* line 3744, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .lpw-list .fs-item:last-child {
      border: none;
      margin-bottom: 0; }
  /* line 3749, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .lpw-list .product-img {
    position: absolute;
    left: 0;
    top: 0; }
  /* line 3754, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .lpw-list .product-title {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; }
    /* line 3759, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .lpw-list .product-title a {
      color: inherit;
      text-decoration: none; }
  /* line 3764, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .lpw-list .product-price {
    color: #696868;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px; }
    /* line 3769, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .lpw-list .product-price strike {
      text-decoration: none;
      position: relative;
      margin-right: 7px; }
      /* line 3773, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .lpw-list .product-price strike:before {
        background-color: #696868;
        content: '';
        position: absolute;
        top: 45%;
        left: -2px;
        right: -2px;
        height: 1px; }

/* line 3786, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.range-wrap {
  padding: 3px 0 8px; }

/* line 3790, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.range-slider {
  background-color: #e0e0e0;
  height: 2px;
  margin: 8px 0 16px; }
  /* line 3794, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .range-slider .ui-slider-range {
    background-color: #000; }
  /* line 3797, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .range-slider .ui-slider-handle {
    background-color: #fd3a13;
    cursor: pointer;
    width: 8px;
    height: 8px; }
    /* line 3802, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .range-slider .ui-slider-handle:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #fd3a13; }

/* line 3816, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.range-amount-block {
  color: #6d6d6d;
  font: 12px/25px "Noto Sans";
  padding: 6px 0 0; }
  /* line 3820, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .range-amount-block .range-amount {
    display: block;
    line-height: 36px;
    float: right;
    white-space: nowrap; }

/* line 3829, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-product-block .title-blc-3 {
  margin-bottom: 23px; }

/* line 3833, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-product-block .product-description p {
  font-size: 14px;
  line-height: 24px; }

/* line 3839, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-product-block .product-addinfo .pai-list {
  color: #6f6f6f;
  font: 14px/24px "Noto Sans";
  margin: -8px 0 23px; }
  /* line 3843, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-block .product-addinfo .pai-list .pai-item {
    border-bottom: 1px solid #f0f0f0;
    padding: 8px 0; }
    /* line 3846, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-block .product-addinfo .pai-list .pai-item:last-child {
      border: none; }

/* line 3854, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-product-item {
  margin-top: 30px;
  margin-left: -12px;
  margin-right: -12px;
  margin-bottom: 34px; }
  /* line 3860, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .half {
    padding-left: 12px;
    padding-right: 12px; }
  /* line 3864, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-img {
    padding: 6px 0; }
    @media screen and (max-width: 479px) {
      /* line 3864, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-product-item .shop-product-img {
        margin-bottom: 20px; } }
  /* line 3870, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-title {
    color: #202020;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    padding: 0;
    margin-bottom: 10px; }
  /* line 3878, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-rate {
    color: #8f8f8f;
    font: 400 12px "Georgia";
    margin-bottom: 12px; }
    /* line 3882, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-item .shop-product-rate .rate-tp-1 {
      display: inline-block; }
    /* line 3885, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-item .shop-product-rate span {
      margin-left: 8px;
      letter-spacing: 0.03em; }
  /* line 3890, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-price {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 14px; }
  /* line 3896, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-description {
    font-size: 13px;
    line-height: 23px; }
  /* line 3901, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-controls .quantity {
    display: inline-block;
    cursor: default;
    margin-right: 20px;
    user-select: none; }
    /* line 3906, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-item .shop-product-controls .quantity .minus,
    .shop-product-item .shop-product-controls .quantity .plus {
      background-color: #efefef;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      position: relative;
      vertical-align: middle; }
      /* line 3918, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-product-item .shop-product-controls .quantity .minus:before,
      .shop-product-item .shop-product-controls .quantity .plus:before {
        background-color: #000;
        content: '';
        position: absolute;
        top: 8px;
        left: 5px;
        width: 7px;
        height: 1px; }
    /* line 3929, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-item .shop-product-controls .quantity .plus:after {
      background-color: #000;
      content: '';
      position: absolute;
      top: 5px;
      left: 8px;
      width: 1px;
      height: 7px; }
    /* line 3939, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-product-item .shop-product-controls .quantity .num {
      display: inline-block;
      color: #626465;
      font: 13px/17px "Noto Sans";
      padding: 0 5px;
      vertical-align: middle; }
  /* line 3947, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-product-item .shop-product-controls .btn-5 {
    min-width: 125px; }

/* line 3954, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-reviews .pr-comments {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 40px; }
  /* line 3958, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-reviews .pr-comments .title-25 {
    margin-bottom: 16px; }

/* line 3963, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-reviews .pr-comments-list-item {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 112px 8px 84px;
  position: relative;
  margin-bottom: 20px; }
  @media screen and (max-width: 479px) {
    /* line 3963, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .product-reviews .pr-comments-list-item {
      padding: 8px 0 8px 84px; } }
  /* line 3971, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-reviews .pr-comments-list-item:last-child {
    border: none;
    margin: 0; }
  /* line 3975, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-reviews .pr-comments-list-item .prc-avatar {
    background-color: #efefef;
    border-radius: 50%;
    display: block;
    width: 67px;
    height: 67px;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0; }
  /* line 3986, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-reviews .pr-comments-list-item p {
    font-size: 14px;
    line-height: 24px; }
  /* line 3990, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-reviews .pr-comments-list-item .prc-rating {
    border-left: 1px solid #ebebeb;
    position: absolute;
    right: 0;
    top: 0;
    height: 66px;
    line-height: 66px;
    width: 102px;
    text-align: right; }
    @media screen and (max-width: 479px) {
      /* line 3990, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .product-reviews .pr-comments-list-item .prc-rating {
        position: relative; } }
    /* line 4002, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .product-reviews .pr-comments-list-item .prc-rating .rate-tp-1 li {
      margin-right: 4px; }
      /* line 4004, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .product-reviews .pr-comments-list-item .prc-rating .rate-tp-1 li:last-child {
        margin: 0; }
      /* line 4007, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .product-reviews .pr-comments-list-item .prc-rating .rate-tp-1 li i {
        font-size: 13px; }

/* line 4015, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.mini-cart {
  padding: 0 0 0 23px; }
  /* line 4017, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mini-cart .total {
    color: #686666;
    font-size: 13px;
    font-weight: 500;
    margin: 3px 0 17px; }
    /* line 4022, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mini-cart .total .amount {
      color: #000; }
  /* line 4026, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .mini-cart .buttons {
    margin-bottom: 5px; }
    /* line 4028, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .mini-cart .buttons .btn-5 {
      min-width: 110px;
      margin-right: 1px; }

/* line 4036, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-list-widget .mini-cart-item {
  border-bottom: 1px solid #f0f0f0;
  min-height: 72px;
  position: relative;
  padding: 3px 0 13px 88px;
  margin: 0 0 13px; }
  /* line 4042, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-list-widget .mini-cart-item:last-child {
    border: none;
    margin-bottom: 0; }

/* line 4047, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-list-widget .product-img {
  position: absolute;
  left: 0;
  top: 0; }

/* line 4052, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-list-widget .product-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; }
  /* line 4057, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-list-widget .product-title a {
    color: inherit;
    text-decoration: none; }

/* line 4062, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-list-widget .product-price {
  display: inline-block;
  color: #696868;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px; }
  /* line 4068, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .product-list-widget .product-price strike {
    text-decoration: none;
    position: relative;
    margin-right: 7px; }
    /* line 4072, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .product-list-widget .product-price strike:before {
      background-color: #696868;
      content: '';
      position: absolute;
      top: 45%;
      left: -2px;
      right: -2px;
      height: 1px; }

/* line 4083, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.product-list-widget .remove {
  position: absolute;
  top: 18px;
  left: -23px; }

/* line 4090, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-table-wrap {
  margin: 15px -20px 30px; }

/* line 4095, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.shop-table-cart {
  border-radius: 2px;
  width: 100%; }
  /* line 4099, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart th {
    background-color: #fcfcfc;
    border: 1px solid #ebebeb;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    padding: 12px 10px;
    text-align: center;
    text-transform: uppercase; }
    @media screen and (max-width: 479px) {
      /* line 4099, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart th {
        padding: 12px 5px; } }
  /* line 4113, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart td {
    background-color: #fff;
    border: 1px solid #ebebeb;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    padding: 19px 10px;
    vertical-align: middle; }
    /* line 4122, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-table-cart td.product-remove {
      padding: 19px 0; }
      /* line 4124, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart td.product-remove a {
        display: block;
        line-height: 40px;
        padding: 0 12px; }
    /* line 4130, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-table-cart td.product-thumbnail {
      padding: 19px 18px; }
      @media screen and (max-width: 479px) {
        /* line 4130, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
        .shop-table-cart td.product-thumbnail {
          padding: 19px 5px; } }
      /* line 4135, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart td.product-thumbnail figure {
        display: inline-block;
        width: 58px;
        margin-right: 16px;
        vertical-align: middle; }
        @media screen and (max-width: 479px) {
          /* line 4135, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
          .shop-table-cart td.product-thumbnail figure {
            display: none; } }
      /* line 4144, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart td.product-thumbnail a {
        color: inherit;
        text-decoration: none; }
    /* line 4149, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-table-cart td.product-price {
      color: #fd3a13; }
    /* line 4153, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-table-cart td.product-subtotal {
      color: #fd3a13; }
  /* line 4157, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart .product-remove {
    width: 38px;
    text-align: center; }
  /* line 4161, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart .product-thumbnail {
    width: 535px; }
  /* line 4164, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart .product-price {
    width: 180px;
    text-align: center; }
  /* line 4168, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart .product-quantity {
    width: 210px;
    text-align: center; }
    /* line 4171, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .shop-table-cart .product-quantity .quantity {
      display: inline-block;
      cursor: default;
      user-select: none; }
      /* line 4175, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart .product-quantity .quantity .minus,
      .shop-table-cart .product-quantity .quantity .plus {
        cursor: pointer;
        display: inline-block;
        width: 10px;
        height: 10px;
        line-height: 10px;
        text-align: center;
        position: relative;
        vertical-align: middle; }
        @media screen and (max-width: 479px) {
          /* line 4175, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
          .shop-table-cart .product-quantity .quantity .minus,
          .shop-table-cart .product-quantity .quantity .plus {
            display: block;
            margin: 8px auto; } }
        /* line 4189, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
        .shop-table-cart .product-quantity .quantity .minus:before,
        .shop-table-cart .product-quantity .quantity .plus:before {
          background-color: #000;
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 10px;
          height: 2px; }
      /* line 4200, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart .product-quantity .quantity .plus:after {
        background-color: #000;
        content: '';
        position: absolute;
        top: 0;
        left: 4px;
        width: 2px;
        height: 10px; }
      /* line 4210, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .shop-table-cart .product-quantity .quantity .num {
        color: #222;
        display: inline-block;
        padding: 0 5px;
        min-width: 35px;
        text-align: center;
        vertical-align: middle; }
  /* line 4220, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .shop-table-cart .product-subtotal {
    width: 130px;
    text-align: center; }

/* line 4226, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.actions {
  margin-bottom: 35px; }
  /* line 4228, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .actions .input-tp-1 {
    width: 40%; }
    @media screen and (max-width: 767px) {
      /* line 4228, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .actions .input-tp-1 {
        width: 100%;
        margin-bottom: 10px; } }
  @media screen and (max-width: 767px) {
    /* line 4235, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .actions .btn-7 {
      width: 100%;
      margin-bottom: 20px; } }
  @media screen and (max-width: 1077px) {
    /* line 4242, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .actions .right-side {
      text-align: right; } }

/* line 4248, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart-collaterals {
  margin-bottom: 25px; }
  /* line 4250, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .cart-collaterals .title-14 {
    margin-bottom: 23px; }
  /* line 4253, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .cart-collaterals .mb-offset {
    margin-bottom: 55px; }

/* line 4259, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart .right-side {
  width: 366px;
  float: right; }
  @media screen and (max-width: 1077px) {
    /* line 4259, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .cart .right-side {
      width: auto;
      float: none; } }

/* line 4267, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart .cart-line {
  margin-bottom: 20px; }
  @media screen and (max-width: 767px) {
    /* line 4270, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .cart .cart-line .mb-offset {
      margin-bottom: 20px; } }

/* line 4275, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart .form-block {
  margin-bottom: 35px; }

/* line 4281, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.checkout .checkout-line {
  margin-bottom: 20px; }

/* line 4284, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.checkout p {
  font-size: 14px;
  line-height: 24px; }

/* line 4290, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.select-pt-1 {
  width: 100%; }

/* line 4294, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ui-selectmenu-button {
  background-color: #fff;
  display: block;
  width: 100%; }
  /* line 4298, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .ui-selectmenu-button span.ui-icon {
    color: #777777;
    font-size: 14px;
    right: 16px;
    text-indent: 0; }
  /* line 4304, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .ui-selectmenu-button span.ui-selectmenu-text {
    border: 1px solid #ebebeb;
    border-radius: 2px;
    color: #818181;
    font: 12px/26px "Noto Sans";
    padding: 6px 40px 6px 12px; }

/* line 4314, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.ui-selectmenu-menu .ui-menu {
  background-color: #fff;
  border: 1px solid #ebebeb;
  border-radius: 0 0 2px 2px;
  padding-top: 2px;
  margin-top: -2px; }
  /* line 4320, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .ui-selectmenu-menu .ui-menu .ui-menu-item {
    color: #777777;
    font: 12px/26px "Noto Sans";
    padding: 6px 40px 6px 12px; }
    /* line 4324, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .ui-selectmenu-menu .ui-menu .ui-menu-item.ui-state-focus {
      background-color: #fafafa;
      margin: 0; }

/* line 4332, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.cart_totals {
  color: #797979;
  font: 13px/20px "Noto Sans";
  width: 100%;
  margin: -6px 0 42px; }
  /* line 4337, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .cart_totals td {
    padding: 0 0 18px; }
  /* line 4340, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .cart_totals span {
    color: #fd3a13; }

/* line 4345, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.remember {
  color: #797979;
  font: 12px/40px "Noto Sans"; }
  /* line 4348, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .remember a {
    color: #fd3a13;
    text-decoration: none;
    margin-left: 15px; }
  /* line 4353, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .remember .btn-7 {
    margin-right: 25px; }

/* line 4358, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.login-customer-block {
  margin: 15px 0 50px; }

/* line 4362, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.enter-coupon-block {
  margin-bottom: 44px; }

/* line 4367, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.checkout-address-block .checkout-line {
  margin-bottom: 11px; }

/* line 4370, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.checkout-address-block .offbt {
  margin-bottom: 20px; }

@media screen and (max-width: 767px) {
  /* line 4374, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .checkout-address-block .col-half {
    margin-bottom: 11px; }
    /* line 4376, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .checkout-address-block .col-half:last-child {
      margin-bottom: 0; } }

/* line 4383, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.create-account {
  color: #797979;
  font: 12px/40px "Noto Sans"; }
  /* line 4386, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .create-account a {
    color: #fd3a13;
    text-decoration: none;
    margin-left: 15px; }

/* line 4393, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.your-order-block {
  margin-bottom: 25px; }

/* line 4397, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
.order-table {
  border: 1px solid #ebebeb;
  width: 100%; }
  /* line 4401, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .list-first td {
    padding-top: 25px; }
  /* line 4406, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .list-last td {
    padding-bottom: 25px; }
  /* line 4410, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table th {
    background-color: #fcfcfc;
    border: 1px solid #ebebeb;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    width: 50%;
    padding: 11px 24px;
    text-align: left;
    text-transform: uppercase; }
    @media screen and (max-width: 479px) {
      /* line 4410, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .order-table th {
        padding: 11px 10px; } }
  /* line 4425, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table td {
    color: #797979;
    width: 50%;
    padding: 11px 24px; }
    @media screen and (max-width: 479px) {
      /* line 4425, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .order-table td {
        padding: 11px 10px; } }
    /* line 4432, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .order-table td.bd {
      border: 1px solid #ebebeb; }
    /* line 4435, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .order-table td.bdr {
      border-right: 1px solid #ebebeb; }
  /* line 4439, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .ot-list {
    margin: 3px 0; }
  /* line 4443, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .ot-list-item {
    color: #797979;
    font: 13px/16px "Noto Sans"; }
  /* line 4447, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .black {
    color: #222; }
  /* line 4450, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .orange {
    color: #fd3a13; }
  /* line 4453, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .payment {
    padding: 12px 0; }
    /* line 4455, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .order-table .payment .title-28 {
      margin-bottom: 7px; }
      /* line 4457, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
      .order-table .payment .title-28 i {
        margin: 0 8px; }
    /* line 4461, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
    .order-table .payment p {
      color: #888;
      font: 14px/25px "Georgia";
      margin: 0; }
  /* line 4467, D:/aartyomenko/Git/spectr-html/app/scss/_theme_style.scss */
  .order-table .submit {
    padding: 19px 0; }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-1,
.header-tp-2,
.header-tp-3,
.header-tp-4 {
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 767px) {
    /* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .header-tp-1,
    .header-tp-2,
    .header-tp-3,
    .header-tp-4 {
      display: none; } }

/* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar .tb-nav {
  float: left;
  display: block;
  margin-right: 0%;
  width: 29%;
  padding: 0 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .top-bar .tb-nav:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1229px) {
    /* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-nav {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.16667%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-nav:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 1077px) {
    /* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-nav {
      float: left;
      display: block;
      margin-right: 0%;
      width: 50%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-nav:last-child {
        margin-right: 0; } }

/* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar .tb-date {
  float: left;
  display: block;
  margin-right: 0%;
  width: 26.5%;
  padding: 0 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .top-bar .tb-date:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1229px) {
    /* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-date {
      float: left;
      display: block;
      margin-right: 0%;
      width: 30.66667%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-date:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 1077px) {
    /* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-date {
      display: none; } }

/* line 35, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar .tb-saved-art {
  float: left;
  display: block;
  margin-right: 0%;
  width: 17.5%;
  padding: 0 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .top-bar .tb-saved-art:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1229px) {
    /* line 35, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-saved-art {
      display: none; } }

/* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar .tb-social {
  float: left;
  display: block;
  margin-right: 0%;
  width: 16.25%;
  padding: 0 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .top-bar .tb-social:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1229px) {
    /* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-social {
      float: left;
      display: block;
      margin-right: 0%;
      width: 24.58333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-social:last-child {
        margin-right: 0; }
      /* line 47, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .top-bar .tb-social .tb-social-list {
        float: right; } }
  @media screen and (max-width: 1077px) {
    /* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-social {
      float: left;
      display: block;
      margin-right: 0%;
      width: 33.33333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-social:last-child {
        margin-right: 0; } }

/* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar .tb-sing-login {
  float: left;
  display: block;
  margin-right: 0%;
  width: 10.75%;
  padding: 0 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .top-bar .tb-sing-login:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1229px) {
    /* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-sing-login {
      float: left;
      display: block;
      margin-right: 0%;
      width: 11.58333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-sing-login:last-child {
        margin-right: 0; } }
  @media screen and (max-width: 1077px) {
    /* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar .tb-sing-login {
      float: left;
      display: block;
      margin-right: 0%;
      width: 16.66667%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .top-bar .tb-sing-login:last-child {
        margin-right: 0; } }

/* line 67, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.main-header,
.sticky-header {
  padding: 0 0 0 58px; }
  @media screen and (max-width: 639px) {
    /* line 67, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-header,
    .sticky-header {
      padding-left: 0; } }
  /* line 73, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-header .mh-logo,
  .sticky-header .mh-logo {
    float: left;
    display: block;
    margin-right: 0%;
    width: 35.2125%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-header .mh-logo:last-child,
    .sticky-header .mh-logo:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 73, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-header .mh-logo,
      .sticky-header .mh-logo {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        text-align: center; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-header .mh-logo:last-child,
        .sticky-header .mh-logo:last-child {
          margin-right: 0; } }
  /* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-header .mh-banner,
  .sticky-header .mh-banner {
    float: left;
    display: block;
    margin-right: 0%;
    width: 64.7875%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-header .mh-banner:last-child,
    .sticky-header .mh-banner:last-child {
      margin-right: 0; }
    @media screen and (max-width: 1077px) {
      /* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-header .mh-banner,
      .sticky-header .mh-banner {
        float: left;
        display: block;
        margin-right: 0%;
        width: 100%;
        text-align: center; }
        /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
        .main-header .mh-banner:last-child,
        .sticky-header .mh-banner:last-child {
          margin-right: 0; } }
    /* line 88, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-header .mh-banner img,
    .sticky-header .mh-banner img {
      width: 100%; }
  /* line 92, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-header .main-nav,
  .sticky-header .main-nav {
    float: left;
    display: block;
    margin-right: 0%;
    width: 93.33333%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-header .main-nav:last-child,
    .sticky-header .main-nav:last-child {
      margin-right: 0; }
  /* line 96, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-header .hd-search,
  .sticky-header .hd-search {
    float: left;
    display: block;
    margin-right: 0%;
    width: 6.66667%;
    padding: 0 15px; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .main-header .hd-search:last-child,
    .sticky-header .hd-search:last-child {
      margin-right: 0; }

/* line 103, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-1 .top-bar {
  color: #666; }
  /* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .top-bar-inner {
    padding: 10px 0 9px; }
  /* line 108, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .top-bar-inner {
    border-bottom: 1px solid #ebebeb; }
  /* line 112, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .tb-nav-list a {
    color: #666; }
  /* line 118, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .tb-date i,
  .header-tp-1 .top-bar .tb-saved-art i {
    color: #1c1c1c; }
  /* line 123, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .tb-social-list a {
    color: #1c1c1c; }
  /* line 128, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-1 .top-bar .tb-sing-login a {
    color: #666; }

/* line 136, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-4 .top-bar {
  background: #000;
  color: #e3e3e3; }
  /* line 139, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-4 .top-bar .top-bar-inner {
    padding: 10px 0; }
  /* line 143, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-4 .top-bar .tb-nav-list a {
    color: #e3e3e3; }
  /* line 148, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-4 .top-bar .tb-social-list a {
    color: #fff; }
  /* line 153, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-4 .top-bar .tb-sing-login a {
    color: #fff; }

/* line 160, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.sticky-header {
  background-color: #fff;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 10px;
  z-index: 99;
  transform: translateY(-130%);
  visibility: hidden; }
  @media screen and (max-width: 1077px) {
    /* line 160, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .sticky-header {
      display: none; } }
  /* line 174, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .sticky-header.visible {
    transform: translateY(0);
    visibility: visible; }
  /* line 178, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .sticky-header .logo {
    float: left;
    padding: 10px 20px; }
    /* line 181, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .sticky-header .logo img {
      display: block; }

/* line 191, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-1 .hd-search .st-btn-1,
.header-tp-4 .hd-search .st-btn-1,
.sticky-header .hd-search .st-btn-1 {
  margin: 13px 0 17px; }

/* line 200, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-2 {
  padding-left: 58px; }
  /* line 202, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-2 .main-nav {
    float: left; }
    /* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .header-tp-2 .main-nav .htx {
      display: none; }
  /* line 208, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-2 .hd-search {
    padding: 0 23px; }
    /* line 210, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .header-tp-2 .hd-search .hd-search-block {
      right: 0;
      top: 42px; }
      /* line 213, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .header-tp-2 .hd-search .hd-search-block:before {
        right: 25px; }

/* line 220, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.full-width-header {
  padding: 10px 23px 0;
  vertical-align: top; }
  @media screen and (max-width: 1077px) {
    /* line 220, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .full-width-header {
      padding: 10px 0; } }
  /* line 226, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .full-width-header .left-side {
    position: relative; }
  /* line 229, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .full-width-header .right-side {
    font-size: 11px;
    font-weight: 500;
    float: right;
    line-height: 20px;
    padding: 12px 0 22px;
    vertical-align: top;
    text-transform: uppercase; }
    /* line 237, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .full-width-header .right-side > div {
      float: left; }
  /* line 241, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .full-width-header .menu-btn {
    display: none;
    border-right: 1px solid #e0e0e0;
    float: left;
    padding-right: 23px;
    margin: 10px 20px 0 0; }
    @media screen and (max-width: 1520px) {
      /* line 241, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .full-width-header .menu-btn {
        display: block; } }
  /* line 251, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .full-width-header .c-hamburger {
    width: 25px;
    height: 20px; }
    /* line 254, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .full-width-header .c-hamburger span {
      height: 2px; }
      /* line 256, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .full-width-header .c-hamburger span:before, .full-width-header .c-hamburger span:after {
        height: 2px; }
      /* line 260, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .full-width-header .c-hamburger span:before {
        top: -6px; }
      /* line 263, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .full-width-header .c-hamburger span:after {
        bottom: -6px; }

/* line 270, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-2-logo {
  float: left;
  margin: 4px 25px 0 0; }
  /* line 273, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-2-logo a {
    float: left; }

/* line 278, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-2-social {
  margin: 0 6px; }

/* line 283, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-2-social-list a {
  color: #1c1c1c; }

/* line 288, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-2-saved-art {
  border-left: 1px solid #e0e0e0;
  font-size: 15px;
  padding: 0 15px; }
  /* line 292, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-2-saved-art a {
    color: inherit;
    text-decoration: none; }

/* line 298, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-2-sing-login {
  border-left: 1px solid #e0e0e0;
  padding-left: 6px; }
  /* line 301, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-2-sing-login i {
    display: inline-block;
    font-size: 15px;
    line-height: 14px;
    margin: 0 14px;
    vertical-align: middle; }
  /* line 308, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-2-sing-login a {
    color: #1c1c1c;
    text-decoration: none; }

/* line 316, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-logo {
  text-align: center; }
  /* line 318, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-3-logo a {
    display: inline-block;
    vertical-align: top; }

/* line 325, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-social-list a {
  color: #1c1c1c; }

/* line 330, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-saved-art {
  display: inline-block;
  vertical-align: top; }
  /* line 333, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-3-saved-art i {
    display: inline-block;
    font-size: 13px;
    line-height: 17px;
    vertical-align: middle;
    margin-right: 5px; }

/* line 342, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-sing-login {
  display: inline-block;
  vertical-align: top;
  margin-left: 32px; }
  /* line 346, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .htp-3-sing-login a {
    color: #1c1c1c;
    text-decoration: none; }

/* line 352, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-row {
  display: block;
  margin-left: -10px;
  margin-right: -10px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .htp-3-row::after {
    clear: both;
    content: "";
    display: table; }

/* line 358, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-button {
  float: left;
  display: block;
  margin-right: 0%;
  width: 8.33333%;
  padding: 32px 10px 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .htp-3-button:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 358, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .htp-3-button {
      float: left;
      display: block;
      margin-right: 0%;
      width: 5.83333%;
      padding: 32px 0 15px 10px; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .htp-3-button:last-child {
        margin-right: 0; } }

/* line 367, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-menu {
  float: left;
  display: block;
  margin-right: 0%;
  width: 83.33333%;
  padding: 19px 0 0; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .htp-3-menu:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 367, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .htp-3-menu {
      float: left;
      display: block;
      margin-right: 0%;
      width: 88.33333%; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .htp-3-menu:last-child {
        margin-right: 0; } }

/* line 376, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.htp-3-search {
  float: left;
  display: block;
  margin-right: 0%;
  width: 8.33333%;
  padding: 32px 10px 15px; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .htp-3-search:last-child {
    margin-right: 0; }
  @media screen and (max-width: 1077px) {
    /* line 376, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .htp-3-search {
      float: left;
      display: block;
      margin-right: 0%;
      width: 5.83333%;
      padding: 32px 10px 15px 0; }
      /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
      .htp-3-search:last-child {
        margin-right: 0; } }

/* line 386, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-tp-3 {
  padding-left: 58px; }
  /* line 388, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .top-side {
    border-bottom: 1px solid #ebebeb;
    font-size: 11px;
    font-weight: 500;
    padding: 36px 0;
    text-transform: uppercase; }
  /* line 395, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .left-side {
    padding: 7px 0;
    text-align: left; }
  /* line 399, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .right-side {
    padding: 7px 0;
    text-align: right; }
  /* line 403, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .bottom-side {
    position: relative; }
  /* line 406, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .htp-3-menu {
    text-align: center; }
  /* line 409, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .main-nav-wrap {
    text-align: center; }
  /* line 412, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .main-nav {
    display: inline-block;
    vertical-align: top;
    text-align: left; }
  /* line 418, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .header-tp-3 .hd-search .hd-search-block {
    top: 40px; }
    /* line 420, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .header-tp-3 .hd-search .hd-search-block:before {
      right: 25px; }

/* line 429, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.header-mb {
  background-color: #000;
  display: none;
  color: #fff;
  padding: 15px 0 9px;
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 767px) {
    /* line 429, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .header-mb {
      display: block; } }

/* line 441, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.hdm-menu {
  float: left;
  display: block;
  margin-right: 0%;
  width: 20.83333%;
  padding: 7px 0; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .hdm-menu:last-child {
    margin-right: 0; }
  /* line 444, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-menu .c-hamburger span {
    background: #fff; }
    /* line 446, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hdm-menu .c-hamburger span:before, .hdm-menu .c-hamburger span:after {
      background-color: #fff; }
  /* line 451, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-menu .htx.is-active span {
    background: none; }

/* line 456, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.hdm-logo {
  float: left;
  display: block;
  margin-right: 0%;
  width: 58.33333%;
  text-align: center; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .hdm-logo:last-child {
    margin-right: 0; }
  /* line 459, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-logo h1 {
    padding: 0;
    margin: 0; }
  /* line 463, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-logo a {
    display: inline-block;
    vertical-align: top; }

/* line 469, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.hdm-search-user {
  float: left;
  display: block;
  margin-right: 0%;
  width: 20.83333%;
  padding: 7px 0;
  text-align: right; }
  /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
  .hdm-search-user:last-child {
    margin-right: 0; }
  /* line 473, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-search-user .hd-search {
    display: inline-block;
    vertical-align: top; }
    /* line 476, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hdm-search-user .hd-search .st-btn-1 {
      color: #fff; }
      /* line 478, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hdm-search-user .hd-search .st-btn-1:hover {
        color: #fff; }
    /* line 482, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hdm-search-user .hd-search .hd-search-block {
      right: -50px;
      top: 40px; }
      /* line 485, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hdm-search-user .hd-search .hd-search-block:before {
        right: 50px; }
  /* line 490, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hdm-search-user .user {
    display: inline-block;
    vertical-align: top;
    color: #fff;
    font-size: 15px;
    margin-left: 16px; }
    /* line 496, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hdm-search-user .user a {
      color: inherit;
      text-decoration: none; }

/* line 504, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.top-bar {
  font-size: 11px;
  font-weight: 500;
  line-height: 26px;
  padding: 0 0 0 58px;
  text-transform: uppercase;
  vertical-align: middle; }
  @media screen and (max-width: 639px) {
    /* line 504, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar {
      padding-left: 0; } }
  /* line 514, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .top-bar i {
    font-size: 13px;
    margin: 0 7px 0 0; }
    /* line 517, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .top-bar i.fa {
      font-size: 12px; }

/* line 523, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.tb-nav-list {
  overflow: hidden; }
  @media screen and (max-width: 479px) {
    /* line 523, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .tb-nav-list {
      display: inline-block; } }
  /* line 528, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-nav-list li {
    float: left;
    margin: 0 15px 0 0; }
    @media screen and (max-width: 639px) {
      /* line 528, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .tb-nav-list li {
        margin: 0 12px 0 0; } }
    /* line 534, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .tb-nav-list li:last-child {
      margin: 0; }
  /* line 538, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-nav-list a {
    color: #e3e3e3;
    text-decoration: none; }

/* line 544, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.tb-social-list,
.htp-2-social-list,
.htp-3-social-list {
  overflow: hidden; }
  @media screen and (max-width: 479px) {
    /* line 544, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .tb-social-list,
    .htp-2-social-list,
    .htp-3-social-list {
      display: inline-block; } }
  /* line 551, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list li,
  .htp-2-social-list li,
  .htp-3-social-list li {
    float: left;
    margin: 0 6px; }
  /* line 555, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list a,
  .htp-2-social-list a,
  .htp-3-social-list a {
    display: inline-block;
    font-size: 12px;
    padding: 0 5px; }
  /* line 560, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list i,
  .htp-2-social-list i,
  .htp-3-social-list i {
    margin: 0; }
  /* line 563, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list .tw a:hover,
  .htp-2-social-list .tw a:hover,
  .htp-3-social-list .tw a:hover {
    color: #00abed; }
  /* line 564, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list .fb a:hover,
  .htp-2-social-list .fb a:hover,
  .htp-3-social-list .fb a:hover {
    color: #4c68b3; }
  /* line 565, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list .gp a:hover,
  .htp-2-social-list .gp a:hover,
  .htp-3-social-list .gp a:hover {
    color: #e51616; }
  /* line 566, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list .in a:hover,
  .htp-2-social-list .in a:hover,
  .htp-3-social-list .in a:hover {
    color: #1d5e9f; }
  /* line 567, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-social-list .dr a:hover,
  .htp-2-social-list .dr a:hover,
  .htp-3-social-list .dr a:hover {
    color: #f51f9d; }

/* line 570, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.tb-sing-login {
  text-align: right; }
  /* line 572, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .tb-sing-login a {
    color: #fff;
    text-decoration: none; }

/* line 578, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.mh-top {
  margin: 34px 0 20px; }
  @media screen and (max-width: 1077px) {
    /* line 581, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .mh-top .flex {
      display: block; }
    /* line 584, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .mh-top a {
      display: inline-block;
      margin-bottom: 25px; } }

/* line 591, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.mh-bottom {
  color: #1c1c1c; }

/* line 595, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.main-nav-wrap {
  position: relative; }

/* line 601, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.main-nav .htx {
  float: left;
  margin: 12px 0 20px; }

/* line 607, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.main-nav-list,
.sf-menu {
  float: left;
  margin: 0 0 0 15px; }
  /* line 612, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-nav-list ul.sub,
  .sf-menu ul.sub {
    background-color: #000;
    display: none;
    padding: 11px 0;
    min-width: 220px;
    position: absolute;
    left: 0;
    z-index: 2; }
    /* line 620, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list ul.sub ul.sub,
    .sf-menu ul.sub ul.sub {
      top: -11px;
      left: 100%; }
    /* line 624, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list ul.sub li > a,
    .sf-menu ul.sub li > a {
      color: #fff;
      display: block;
      font-weight: 500;
      padding: 8px 16px;
      position: relative;
      text-decoration: none; }
      /* line 632, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-nav-list ul.sub li > a.sf-with-ul:before, .main-nav-list ul.sub li > a.sf-with-ul:after,
      .sf-menu ul.sub li > a.sf-with-ul:before,
      .sf-menu ul.sub li > a.sf-with-ul:after {
        background-color: #fff;
        content: '';
        position: absolute;
        width: 8px;
        height: 2px;
        top: 17px;
        right: 13px; }
      /* line 642, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-nav-list ul.sub li > a.sf-with-ul:before,
      .sf-menu ul.sub li > a.sf-with-ul:before {
        width: 2px;
        height: 8px;
        top: 14px;
        right: 16px; }
  /* line 651, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-nav-list li,
  .sf-menu li {
    position: relative; }
    /* line 653, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list li.mf,
    .sf-menu li.mf {
      position: static; }
      /* line 655, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-nav-list li.mf > ul.sub,
      .sf-menu li.mf > ul.sub {
        background: #1f2124;
        width: 100%;
        padding: 0; }
  /* line 663, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-nav-list > li,
  .sf-menu > li {
    float: left; }
    /* line 667, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list > li.active > a:after, .main-nav-list > li:hover > a:after,
    .sf-menu > li.active > a:after,
    .sf-menu > li:hover > a:after {
      opacity: 1;
      transform: scale(1, 1); }
  /* line 673, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .main-nav-list > li > a,
  .sf-menu > li > a {
    color: #1c1c1c;
    display: block;
    font-weight: 600;
    padding: 12px 12px 20px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase; }
    @media screen and (max-width: 1229px) {
      /* line 673, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .main-nav-list > li > a,
      .sf-menu > li > a {
        padding: 12px 8px 20px; } }
    /* line 684, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list > li > a:after,
    .sf-menu > li > a:after {
      background: #fd3a13;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      opacity: 0;
      transform: scale(0.5, 1);
      transform-origin: 50% 0;
      transition: transform 300ms, opacity 200ms; }
    /* line 697, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .main-nav-list > li > a .fa,
    .sf-menu > li > a .fa {
      color: #959595;
      margin: 0 2px; }

/* line 709, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.mega-menu .post {
  margin-bottom: 20px; }

/* line 714, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.hd-search {
  text-align: right;
  position: relative; }
  /* line 717, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hd-search .st-btn-1 {
    font-size: 15px;
    vertical-align: top; }
    /* line 720, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hd-search .st-btn-1:hover {
      color: #515151; }
  /* line 724, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .hd-search .hd-search-block {
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: none;
    padding: 11px 7px;
    position: absolute;
    right: -20px;
    top: 100%;
    width: 370px;
    z-index: 1; }
    @media screen and (max-width: 1229px) {
      /* line 724, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block {
        right: 0; } }
    @media screen and (max-width: 767px) {
      /* line 724, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block {
        max-width: 320px; } }
    /* line 741, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hd-search .hd-search-block:before {
      content: '▲';
      color: #fff;
      position: absolute;
      right: 38px;
      top: -14px;
      width: 13px;
      height: 14px;
      overflow: hidden;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      @media screen and (max-width: 1229px) {
        /* line 741, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
        .hd-search .hd-search-block:before {
          right: 15px; } }
    /* line 755, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .hd-search .hd-search-block .search {
      background: #f6f6f6;
      border-radius: 2px;
      display: table;
      padding: 7px 0;
      width: 100%;
      height: 38px; }
      /* line 762, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block .search .search-input,
      .hd-search .hd-search-block .search .search-btn {
        display: table-cell; }
      /* line 766, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block .search .search-btn {
        width: 22%;
        position: relative; }
        /* line 769, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
        .hd-search .hd-search-block .search .search-btn:before {
          content: '';
          background: #ebebeb;
          width: 1px;
          position: absolute;
          top: 2px;
          bottom: 2px; }
      /* line 778, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block .search input {
        background-color: transparent;
        border: none;
        color: #000;
        font: 11px "Noto Sans";
        width: 100%;
        height: 24px;
        padding: 0 12px; }
      /* line 787, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
      .hd-search .hd-search-block .search button {
        appearance: none;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: #000;
        font: 500 11px "Poppins";
        width: 100%;
        height: 24px;
        padding: 0 6px;
        text-align: center;
        text-transform: uppercase; }

/* line 804, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.pp-search-block {
  max-width: 720px;
  margin: 0 auto;
  position: relative; }
  /* line 808, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .pp-search-block .search {
    border-bottom: 2px solid #fff;
    display: table;
    width: 100%;
    padding: 0 0 13px; }
  /* line 814, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .pp-search-block .search-btn {
    display: table-cell;
    width: 20px; }
    /* line 817, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .pp-search-block .search-btn button {
      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #fff;
      display: inline-block;
      font-size: 15px;
      line-height: 25px;
      height: 25px; }
  /* line 829, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .pp-search-block .search-input {
    display: table-cell; }
    /* line 831, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
    .pp-search-block .search-input input {
      background-color: transparent;
      border: none;
      color: #fff;
      font: 300 18px/25px "Poppins";
      width: 100%;
      padding: 0 7px; }
      /* line 4, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/css3/_placeholder.scss */
      .pp-search-block .search-input input::-webkit-input-placeholder {
        color: #fff;
        opacity: 1; }
      /* line 4, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/css3/_placeholder.scss */
      .pp-search-block .search-input input::-moz-placeholder {
        color: #fff;
        opacity: 1; }
      /* line 4, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/css3/_placeholder.scss */
      .pp-search-block .search-input input:-moz-placeholder {
        color: #fff;
        opacity: 1; }
      /* line 4, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/css3/_placeholder.scss */
      .pp-search-block .search-input input:-ms-input-placeholder {
        color: #fff;
        opacity: 1; }
  /* line 844, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .pp-search-block .mfp-close {
    background-image: url(../img/sprite-retina.png);
    background-position: -187px 0px;
    width: 20px;
    height: 20px;
    opacity: 1;
    top: -70px;
    right: -43px;
    text-indent: -9999px;
    overflow: hidden; }

/* line 854, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.mm-life-st-pst {
  background: #16171a;
  padding: 11px 22px 3px; }
  /* line 857, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .mm-life-st-pst figure {
    margin: 0 0 15px; }

/* line 862, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.mm-life-st-title {
  color: #fff;
  padding: 16px 0; }
  /* line 865, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .mm-life-st-title span {
    font-size: 17px;
    font-weight: 600;
    line-height: 21px; }
  /* line 870, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
  .mm-life-st-title .all {
    color: inherit;
    float: right;
    font-size: 12px;
    line-height: 21px;
    text-decoration: none;
    text-transform: uppercase; }

/* line 881, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.cats ul {
  padding: 15px 0; }

/* line 884, D:/aartyomenko/Git/spectr-html/app/scss/_header.scss */
.cats a {
  line-height: 28px;
  padding: 8px 20px !important; }

/* line 2, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.main-footer p {
  color: #bec1c3; }

/* line 7, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-widget {
  margin: 0 0 22px; }
  @media screen and (max-width: 1229px) {
    /* line 7, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-widget {
      margin: 0 0 37px; } }

/* line 14, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.top-part {
  border-bottom: 1px solid #303338;
  padding: 40px 0 10px; }
  /* line 17, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .top-part .ft-title-1 {
    margin: 0 0 12px; }

/* line 24, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-list-1 li,
.ft-list-2 li {
  border-bottom: 1px dashed #303338;
  padding: 10px 0 12px; }

/* line 29, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-list-1 .date,
.ft-list-2 .date {
  color: #888;
  font-family: "Inconsolata";
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase; }

/* line 36, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-list-1 figure,
.ft-list-2 figure {
  float: left;
  margin: 4px 18px 2px 0; }
  /* line 39, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-list-1 figure img,
  .ft-list-2 figure img {
    display: block; }

/* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-list-2 li:last-child {
  border: none; }

/* line 51, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.bottom-part {
  color: #bec1c3;
  padding: 40px 0; }

/* line 58, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list li {
  float: left;
  margin: 0 2px 0 1px; }

/* line 62, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list a {
  border: 1px solid #303338;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  line-height: 34px;
  display: block;
  width: 34px;
  height: 34px;
  text-align: center; }

/* line 73, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list .tw a:hover {
  color: #00abed; }

/* line 76, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list .fb a:hover {
  color: #4c68b3; }

/* line 79, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list .gp a:hover {
  color: #e51616; }

/* line 82, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list .in a:hover {
  color: #1d5e9f; }

/* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-social-list .dr a:hover {
  color: #f51f9d; }

/* line 90, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.bottom-bar {
  background: #121314;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  padding: 18px 0 17px;
  text-transform: uppercase; }

@media screen and (max-width: 1077px) {
  /* line 99, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-menu-list {
    display: inline-block; } }

@media screen and (max-width: 767px) {
  /* line 99, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-menu-list {
    margin: 15px 0; } }

/* line 107, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-menu-list li {
  float: left;
  margin: 0 25px 0 0; }
  /* line 110, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-menu-list li:last-child {
    margin: 0; }
  @media screen and (max-width: 767px) {
    /* line 107, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-menu-list li {
      float: none;
      text-align: center;
      margin: 0 0 5px; } }

/* line 119, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-menu-list a {
  color: #fff;
  text-decoration: none; }

/* line 125, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.copyright {
  text-align: right; }
  @media screen and (max-width: 1077px) {
    /* line 125, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .copyright {
      text-align: center;
      margin-top: 15px; } }

/* line 133, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-title-1 {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  margin: 0 0 20px;
  padding: 0; }

/* line 141, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-title-2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0 0 4px;
  padding: 0; }
  /* line 148, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-title-2 a {
    color: #fff;
    text-decoration: none; }

/* line 154, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.ft-search {
  padding: 15px 0; }
  /* line 156, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .ft-search .search {
    background: #272a2e;
    border-radius: 2px;
    display: table;
    padding: 7px 0;
    width: 100%;
    height: 38px; }
    /* line 163, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-search .search .search-input,
    .ft-search .search .search-btn {
      display: table-cell; }
    /* line 167, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-search .search .search-btn {
      width: 22%;
      position: relative; }
      /* line 170, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
      .ft-search .search .search-btn:before {
        content: '';
        background: #3b3f45;
        width: 1px;
        position: absolute;
        top: 5px;
        bottom: 5px; }
    /* line 179, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-search .search input {
      background-color: transparent;
      border: none;
      color: #fff;
      font: 11px "Noto Sans";
      width: 100%;
      height: 24px;
      padding: 0 12px; }
    /* line 188, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
    .ft-search .search button {
      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #fff;
      font: 500 11px "Poppins";
      width: 100%;
      height: 24px;
      padding: 0 6px;
      text-align: center;
      text-transform: uppercase; }

/* line 204, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
.inst-list {
  margin: 0 -1px 0 -5px;
  padding: 12px 0; }
  /* line 208, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .inst-list li {
    width: 20%;
    float: left;
    padding: 0 0 0 5px; }
  /* line 213, D:/aartyomenko/Git/spectr-html/app/scss/_footer.scss */
  .inst-list img {
    display: block;
    width: 100%; }

/* line 2, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.left-sticky-bar.sticky {
  position: fixed;
  top: 65px; }
  @media screen and (max-width: 1077px) {
    /* line 2, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .left-sticky-bar.sticky {
      top: 0; } }

/* line 12, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-list a {
  color: #c1c1c1;
  display: block;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  padding: 12px 5px 10px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase; }
  /* line 22, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
  .lsb-list a:hover, .lsb-list a.active {
    background: #1f2124; }

/* line 29, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-1,
.lsb-icon-2,
.lsb-icon-3,
.lsb-icon-4,
.lsb-icon-5,
.lsb-icon-6 {
  display: inline-block; }

/* line 38, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-1 {
  background-image: url(../img/sprite-retina.png);
  background-position: -303px 0px;
  width: 24px;
  height: 24px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 38, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-1 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-2 {
  background-image: url(../img/sprite-retina.png);
  background-position: -279px 0px;
  width: 24px;
  height: 24px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-2 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-3 {
  background-image: url(../img/sprite-retina.png);
  background-position: -255px 0px;
  width: 24px;
  height: 16px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-3 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 50, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-4 {
  background-image: url(../img/sprite-retina.png);
  background-position: -207px 0px;
  width: 24px;
  height: 22px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 50, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-4 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 54, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-5 {
  background-image: url(../img/sprite-retina.png);
  background-position: -327px 0px;
  width: 24px;
  height: 24px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 54, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-5 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 58, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
.lsb-icon-6 {
  background-image: url(../img/sprite-retina.png);
  background-position: -231px 0px;
  width: 24px;
  height: 24px; }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* line 58, D:/aartyomenko/Git/spectr-html/app/scss/_left_sticky_bar.scss */
    .lsb-icon-6 {
      background-image: url(../img/sprite-retina@2x.png);
      background-size: 351px 24px; } }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.sp-popup {
  background-color: #fff;
  border: 1px solid #f9f9f9;
  color: #656565;
  max-width: 350px;
  padding: 22px 26px 32px;
  position: relative;
  margin: 0 auto;
  text-align: center; }
  /* line 13, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup.signin .form.not-valid .btn-8 {
    background-color: #74777a; }
    /* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup.signin .form.not-valid .btn-8:hover, .sp-popup.signin .form.not-valid .btn-8:active, .sp-popup.signin .form.not-valid .btn-8:focus {
      background-color: #74777a; }
  /* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup.recentpass .btns {
    margin-bottom: 20px; }
  /* line 29, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .btns {
    margin-bottom: 30px; }
    /* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .btns a {
      border-bottom: 1px solid #dadada;
      color: #9f9f9f;
      display: block;
      width: 50%;
      float: left;
      font: 500 14px/21px "Poppins";
      padding: 12px;
      text-decoration: none;
      text-align: center; }
      /* line 42, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
      .sp-popup .btns a:hover, .sp-popup .btns a.active {
        border-color: #000;
        color: #000; }
  /* line 50, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .social .pp-social-list {
    display: inline-block;
    margin: 8px 0 13px; }
    /* line 54, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list li {
      float: left; }
    /* line 57, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list a {
      border-radius: 50%;
      border: 1px solid #f7f7f7;
      color: #000;
      display: block;
      font-size: 14px;
      line-height: 37px;
      width: 37px;
      height: 37px;
      margin: 4px;
      text-decoration: none;
      text-align: center; }
    /* line 70, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list .tw a:hover {
      color: #00abed; }
    /* line 71, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list .fb a:hover {
      color: #4c68b3; }
    /* line 72, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list .gp a:hover {
      color: #e51616; }
    /* line 73, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .social .pp-social-list .vk a:hover {
      color: #5e79bf; }
  /* line 76, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .pp-title {
    color: #656565;
    font: 400 13px/21px "Poppins";
    overflow: hidden;
    margin-bottom: 10px; }
    /* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .sp-popup .pp-title span {
      display: inline-block;
      padding: 0 8px;
      position: relative; }
      /* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
      .sp-popup .pp-title span:before, .sp-popup .pp-title span:after {
        content: '';
        background-color: #e5e5e5;
        height: 1px;
        width: 200px;
        position: absolute;
        top: 50%; }
      /* line 94, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
      .sp-popup .pp-title span:before {
        right: 100%; }
      /* line 97, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
      .sp-popup .pp-title span:after {
        left: 100%; }
  /* line 103, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .form input {
    border: none;
    border-bottom: 1px solid #e5e5e5;
    color: #656565;
    font: 400 13px/21px "Poppins";
    width: 100%;
    padding: 11px 0;
    margin-bottom: 7px; }
  /* line 112, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .form .btn-8 {
    margin: 16px 0; }
  /* line 115, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .form a,
  .sp-popup .form span {
    color: #656565;
    font: 400 12px/21px "Poppins";
    text-decoration: none; }
  /* line 122, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .sp-popup .popup-close-ic {
    right: -65px;
    top: -52px;
    cursor: pointer;
    padding: 0; }
    @media screen and (max-width: 767px) {
      /* line 122, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
      .sp-popup .popup-close-ic {
        display: none; } }

/* line 134, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mb-menu {
  pointer-events: none;
  text-align: center; }
  /* line 137, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .mb-menu .mfp-close {
    cursor: pointer;
    padding: 0;
    pointer-events: auto;
    right: 15%;
    top: -10%; }

/* line 146, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mobile-nav-list {
  display: inline-block;
  vertical-align: top;
  pointer-events: auto; }
  /* line 150, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .mobile-nav-list .mn-item {
    color: #fff;
    font: 600 14px/44px "Poppins";
    text-transform: uppercase; }
    /* line 154, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
    .mobile-nav-list .mn-item a {
      color: inherit;
      text-decoration: none; }

/* line 163, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.aside-menu {
  padding: 45px 0 0;
  width: 230px; }
  /* line 166, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .aside-menu .mfp-close {
    cursor: pointer;
    top: 25px;
    right: 25px; }

/* line 173, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.am-container {
  padding: 22px 22px 25px; }
  /* line 174, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .am-container:first-child {
    border-bottom: 1px solid #ebebeb; }

/* line 182, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.am-list .am-item a {
  display: block;
  color: #1c1c1c;
  font: 600 13px/22px "Poppins";
  padding: 8px 0;
  text-decoration: none;
  text-transform: uppercase; }

/* line 193, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.twitter-posts {
  padding: 10px 0; }

/* line 198, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.tp-list .tp-list-item {
  color: #595959;
  font: 400 12px/21px "Poppins";
  word-wrap: break-word;
  margin-bottom: 18px; }
  /* line 203, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .tp-list .tp-list-item .icon {
    color: #16171a;
    margin: 4px 0; }
  /* line 207, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .tp-list .tp-list-item a {
    color: #fd3a13;
    text-decoration: none; }

/* line 214, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.tp-all {
  color: #6f7173;
  font: 400 12px/21px "Poppins";
  text-decoration: none; }

/* line 221, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-bg {
  background-color: #1f2124; }
  /* line 223, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
  .mfp-bg.mfp-mb-menu {
    background-color: #000; }

/* overlay at start */
/* line 232, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-bg {
  opacity: 0;
  transition: all 0.4s ease-out; }

/* overlay animate in */
/* line 240, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-bg.mfp-ready {
  opacity: 0.86; }

/* overlay animate out */
/* line 247, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-bg.mfp-removing {
  opacity: 0; }

/* content at start */
/* line 254, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-wrap .mfp-content {
  opacity: 0;
  transform: translateY(-150px);
  transition: all 0.3s ease-out; }

/* content animate it */
/* line 263, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-wrap.mfp-ready .mfp-content {
  transform: translateY(0px);
  opacity: 1; }

/* content animate out */
/* line 271, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade-top.mfp-wrap.mfp-removing .mfp-content {
  transform: translateY(150px);
  opacity: 0; }

/* overlay at start */
/* line 280, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.4s ease-out; }

/* overlay animate in */
/* line 287, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.88; }

/* overlay animate out */
/* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0; }

/* content at start */
/* line 301, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.3s ease-out; }

/* content animate it */
/* line 309, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1; }

/* content animate out */
/* line 316, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0; }

/* line 321, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-bg.mfp-asd-menu {
  background-color: rgba(0, 0, 0, 0.6); }

/* line 327, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-asd-menu .mfp-container {
  padding: 0;
  text-align: left; }

/* line 331, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-asd-menu .mfp-content {
  background-color: #fff;
  width: auto;
  min-height: 100%;
  vertical-align: top; }

/* overlay at start */
/* line 342, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out; }

/* overlay animate in */
/* line 350, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-bg.mfp-ready {
  opacity: 1; }

/* overlay animate out */
/* line 357, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-bg.mfp-removing {
  opacity: 0; }

/* content at start */
/* line 364, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-wrap .mfp-content {
  transform: translateX(-100%);
  transition: all 0.25s ease-out; }

/* content animate it */
/* line 372, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-wrap.mfp-ready .mfp-content {
  transform: translateX(0); }

/* content animate out */
/* line 379, D:/aartyomenko/Git/spectr-html/app/scss/_popups.scss */
.mfp-slide-left.mfp-wrap.mfp-removing .mfp-content {
  transform: translateX(-100%); }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.trending-posts-line {
  overflow: hidden; }

/* line 5, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.trending-line {
  line-height: 26px;
  margin: 28px 0;
  padding: 0 70px 0 120px;
  position: relative; }
  @media screen and (max-width: 639px) {
    /* line 5, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .trending-line {
      padding: 0 0 0 120px; } }
  /* line 13, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .trending-line .trending-now {
    background-color: #000;
    color: #fff;
    position: absolute;
    left: 0;
    font-size: 10px;
    padding: 0 12px 0 11px;
    text-transform: uppercase; }
    /* line 21, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .trending-line .trending-now:after {
      content: '';
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-left: 5px solid #000;
      border-bottom: 13px solid transparent;
      position: absolute;
      right: -5px; }
  /* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .trending-line .tl-slider-control {
    position: absolute;
    top: 0;
    right: 0; }
    @media screen and (max-width: 639px) {
      /* line 32, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .trending-line .tl-slider-control {
        display: none; } }
  /* line 40, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .trending-line .tl-slider {
    height: 26px;
    overflow: hidden; }
    /* line 43, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .trending-line .tl-slider a {
      color: #272727;
      text-decoration: none; }
  /* line 48, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .trending-line .tls-prev,
  .trending-line .tls-next {
    cursor: pointer;
    margin-left: 4px; }

/* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-posts-1 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 17px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .main-posts-1::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-1 {
      max-width: 1020px; }
      /* line 61, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-1 .main-slider-2 .post-tp-1 img, .main-posts-1 .main-slider-2 .post-tpv-1 img, .main-posts-1 .main-slider-2 .post-tpv-2 img, .main-posts-1 .main-slider-2 .post-tp-2 img, .main-posts-1 .main-slider-2 .post-tp-12 img, .main-posts-1 .main-slider-2 .post-tp-14 img {
        height: 414px;
        width: auto;
        max-width: none; } }
  @media screen and (max-width: 1077px) {
    /* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-1 {
      max-width: 710px; }
      /* line 71, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-1 .main-slider-2 .post-tp-1 img, .main-posts-1 .main-slider-2 .post-tpv-1 img, .main-posts-1 .main-slider-2 .post-tpv-2 img, .main-posts-1 .main-slider-2 .post-tp-2 img, .main-posts-1 .main-slider-2 .post-tp-12 img, .main-posts-1 .main-slider-2 .post-tp-14 img {
        height: auto;
        width: 100%;
        max-width: 100%; } }
  /* line 79, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-1 .mp-section {
    margin-bottom: 28px; }
    /* line 82, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-1 .mp-section:first-child {
      background: #fff;
      height: 471px; }
      /* line 85, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-1 .mp-section:first-child > div {
        height: 100%; }
      @media screen and (max-width: 1229px) {
        /* line 82, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
        .main-posts-1 .mp-section:first-child {
          height: 414px;
          overflow: hidden; } }
      @media screen and (max-width: 1077px) {
        /* line 82, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
        .main-posts-1 .mp-section:first-child {
          height: auto;
          overflow: hidden; } }

/* line 100, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-posts-2 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 17px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .main-posts-2::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 100, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-2 {
      max-width: 1020px; } }
  @media screen and (max-width: 1077px) {
    /* line 100, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-2 {
      max-width: 710px; } }
  /* line 110, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-2 .mp-section {
    margin: 0 -3px 28px; }
    /* line 113, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-2 .mp-section > div {
      padding: 0 3px; }
      @media screen and (max-width: 639px) {
        /* line 113, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
        .main-posts-2 .mp-section > div {
          margin-top: 6px; }
          /* line 117, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
          .main-posts-2 .mp-section > div:first-child {
            margin-top: 0; } }

/* line 125, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-posts-3 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 17px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .main-posts-3::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 125, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 {
      max-width: 1020px; } }
  @media screen and (max-width: 1077px) {
    /* line 125, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 {
      max-width: 710px; } }
  /* line 135, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-3 .mp-section {
    margin: -2px -2px 26px; }
  /* line 139, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-3 .post {
    padding: 2px; }
  /* line 142, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-3 .title-13 {
    margin: 2px 0 7px; }
  @media screen and (max-width: 1077px) {
    /* line 145, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 .sm-post {
      height: 197px; } }
  @media screen and (max-width: 767px) {
    /* line 145, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 .sm-post {
      height: 145px; } }
  @media screen and (max-width: 1077px) {
    /* line 153, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 .md-post {
      height: 375px; } }
  @media screen and (max-width: 767px) {
    /* line 153, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 .md-post {
      height: 330px; } }
  @media screen and (max-width: 479px) {
    /* line 153, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-3 .md-post {
      height: 255px; } }

/* line 166, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-posts-4 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 45px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .main-posts-4::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 166, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4 {
      max-width: 1020px; }
      /* line 172, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .post-tp-12 figure,
      .main-posts-4 .post-tp-12 a,
      .main-posts-4 .post-tp-12 img {
        height: 100%; }
      /* line 177, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .post-tp-12 img {
        max-width: none;
        width: auto; }
      /* line 183, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .post-tp-20 figure {
        height: 50%; }
      /* line 186, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .post-tp-20 a,
      .main-posts-4 .post-tp-20 img {
        height: 100%; }
      /* line 190, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .post-tp-20 img {
        max-width: none;
        width: auto; } }
  @media screen and (max-width: 1077px) {
    /* line 166, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4 {
      max-width: 710px; }
      /* line 198, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .one-quarter.sm-full {
        height: auto; }
      /* line 205, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .twitter-block .tb-twitts li {
        width: 50%;
        float: left; } }
  @media screen and (max-width: 767px) {
    /* line 213, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4 .twitter-block .tb-twitts li {
      width: 100%;
      float: none; }
    /* line 220, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4 .post-tp-12 img,
    .main-posts-4 .post-tp-20 img {
      width: 100%;
      height: auto; } }
  /* line 227, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-4 .mp-section {
    background: #fff;
    overflow: hidden; }
  /* line 231, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-4 .half,
  .main-posts-4 .one-quarter {
    height: 511px; }
    @media screen and (max-width: 767px) {
      /* line 231, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .half,
      .main-posts-4 .one-quarter {
        height: 330px; } }
    @media screen and (max-width: 479px) {
      /* line 231, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-posts-4 .half,
      .main-posts-4 .one-quarter {
        height: 260px; } }
  /* line 241, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-4 .post,
  .main-posts-4 .twitter-block {
    height: 100%; }
  @media screen and (max-width: 1077px) {
    /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4.tp-2 .post-tp-12 img,
    .main-posts-4.tp-2 .post-tp-20 img {
      width: 100%;
      height: auto; } }
  @media screen and (max-width: 767px) {
    /* line 257, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-4.tp-2 .half.sm-full:last-child {
      height: auto; } }

/* line 265, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-posts-5 {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  margin-bottom: 17px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .main-posts-5::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 265, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-5 {
      max-width: 1020px; } }
  @media screen and (max-width: 1077px) {
    /* line 265, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-posts-5 {
      max-width: 710px; } }
  /* line 275, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-5 .mp-section {
    margin: -2px -3px 26px; }
  /* line 279, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-5 .post {
    padding: 2px 3px; }
  /* line 282, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-posts-5 .title-13 {
    margin: 2px 0 7px; }

/* line 287, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1,
.main-slider-2,
.main-slider-3 {
  position: relative; }

/* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .nav-arrow {
  background: #0c0b0a;
  cursor: pointer;
  display: block;
  width: 51px;
  height: 51px;
  padding: 23px 21px;
  line-height: 5px; }
  @media screen and (max-width: 767px) {
    /* line 294, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-slider-1 .nav-arrow {
      display: none; } }
  /* line 305, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .nav-arrow:hover {
    background: #000; }
  /* line 308, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .nav-arrow i {
    vertical-align: top; }

/* line 312, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .nav-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -26px; }
  /* line 318, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .nav-arrow:hover .slide-count {
    opacity: 1;
    transform: translateX(0); }

/* line 324, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .prev {
  left: 0; }
  /* line 326, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .prev .slide-count {
    left: 100%;
    transform: translateX(-50%); }

/* line 331, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .next {
  right: 0; }
  /* line 333, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .next .slide-count {
    right: 100%;
    transform: translateX(50%); }

/* line 338, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .slide-count {
  background: #1c1c1c;
  color: #fff;
  min-width: 70px;
  height: 51px;
  position: absolute;
  left: auto;
  top: 0;
  font: 400 11px/21px "Noto Sans";
  padding: 15px 10px;
  text-align: center;
  opacity: 0; }
  /* line 350, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .slide-count span {
    margin: 0 2px; }

/* line 354, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .save-tp-1 {
  margin-left: 40px; }
  @media screen and (max-width: 639px) {
    /* line 354, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-slider-1 .save-tp-1 {
      display: none; } }

/* line 360, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-1 .post {
  box-sizing: border-box; }

@media screen and (max-width: 479px) {
  /* line 369, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .post-tp-1 figure img, .main-slider-1 .post-tpv-1 figure img, .main-slider-1 .post-tpv-2 figure img, .main-slider-1 .post-tp-2 figure img, .main-slider-1 .post-tp-12 figure img, .main-slider-1 .post-tp-14 figure img {
    height: 250px;
    width: auto;
    max-width: none;
    float: right; }
  /* line 376, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-1 .post-tp-1 .ptp-1-data, .main-slider-1 .post-tpv-1 .ptp-1-data, .main-slider-1 .post-tpv-2 .ptp-1-data, .main-slider-1 .post-tp-2 .ptp-1-data, .main-slider-1 .post-tp-12 .ptp-1-data, .main-slider-1 .post-tp-14 .ptp-1-data {
    padding: 0 15px 27px; } }

/* line 385, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .slick-arrow,
.main-slider-3 .slick-arrow {
  background: rgba(0, 0, 0, 0.9);
  cursor: pointer;
  display: block;
  width: 39px;
  height: 51px;
  padding: 23px 15px;
  line-height: 5px; }
  /* line 393, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-2 .slick-arrow i,
  .main-slider-3 .slick-arrow i {
    vertical-align: top; }

/* line 397, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .slick-arrow,
.main-slider-2 .slide-count,
.main-slider-3 .slick-arrow,
.main-slider-3 .slide-count {
  float: left; }

/* line 401, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .prev,
.main-slider-3 .prev {
  left: 0;
  padding: 23px 5px 23px 25px; }

/* line 405, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .next,
.main-slider-3 .next {
  right: 0;
  padding: 23px 25px 23px 5px; }

/* line 409, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .slide-count,
.main-slider-3 .slide-count {
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  min-width: 50px;
  height: 51px;
  right: 51px;
  font: 300 11px/23px "Poppins";
  padding: 15px 0;
  text-align: center; }
  /* line 418, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-2 .slide-count span,
  .main-slider-3 .slide-count span {
    margin: 0 2px; }

/* line 422, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .date-tp-1,
.main-slider-3 .date-tp-1 {
  margin: 20px 23px; }

/* line 425, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.main-slider-2 .ptp-1-data,
.main-slider-3 .ptp-1-data {
  padding: 0 205px 17px 23px; }
  @media screen and (max-width: 639px) {
    /* line 425, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-slider-2 .ptp-1-data,
    .main-slider-3 .ptp-1-data {
      padding: 0 23px 17px 23px; }
      /* line 429, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
      .main-slider-2 .ptp-1-data .save-tp-1,
      .main-slider-3 .ptp-1-data .save-tp-1 {
        display: none; } }

@media screen and (max-width: 639px) {
  /* line 434, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-2 .meta-tp-1,
  .main-slider-3 .meta-tp-1 {
    margin: 0 0 10px; } }

@media screen and (max-width: 767px) {
  /* line 439, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
  .main-slider-2 .post-tp-27,
  .main-slider-3 .post-tp-27 {
    height: 300px; }
    /* line 442, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-slider-2 .post-tp-27 figure,
    .main-slider-2 .post-tp-27 a,
    .main-slider-2 .post-tp-27 img,
    .main-slider-3 .post-tp-27 figure,
    .main-slider-3 .post-tp-27 a,
    .main-slider-3 .post-tp-27 img {
      height: 100%; }
    /* line 447, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .main-slider-2 .post-tp-27 img,
    .main-slider-3 .post-tp-27 img {
      width: auto;
      max-width: none; } }

/* line 455, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.ms-navs {
  position: absolute;
  right: 0;
  bottom: 0;
  text-transform: uppercase; }
  @media screen and (max-width: 479px) {
    /* line 455, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
    .ms-navs {
      display: none; } }

/* line 465, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.ms-count {
  display: inline-block;
  width: 50px;
  text-align: center; }

/* line 471, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.ms-prev, .sbr-prev,
.ms-next,
.sbr-next {
  display: inline-block;
  text-indent: -9999px; }

/* line 477, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.ms-prev, .sbr-prev {
  background-image: url(../img/sprite-retina.png);
  background-position: -34px 0px;
  width: 9px;
  height: 5px; }

/* line 481, D:/aartyomenko/Git/spectr-html/app/scss/_main_posts.scss */
.ms-next, .sbr-next {
  background-image: url(../img/sprite-retina.png);
  background-position: -52px 0px;
  width: 9px;
  height: 5px; }

/* line 1, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
.main-video-posts {
  background: #1f2124;
  margin-bottom: 45px; }

/* line 6, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
.video-posts-pane {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px; }
  /* line 20, D:/aartyomenko/Git/spectr-html/app/vendor/bourbon/app/assets/stylesheets/addons/_clearfix.scss */
  .video-posts-pane::after {
    clear: both;
    content: "";
    display: table; }
  @media screen and (max-width: 1229px) {
    /* line 6, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
    .video-posts-pane {
      max-width: 1020px; } }
  @media screen and (max-width: 1077px) {
    /* line 6, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
    .video-posts-pane {
      max-width: 710px; } }
  /* line 15, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
  .video-posts-pane .owl-controls {
    position: absolute;
    top: 50%;
    right: -30px;
    height: 100px;
    width: 11px;
    line-height: 100px;
    margin-top: -50px; }
  /* line 24, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
  .video-posts-pane .owl-pagination {
    display: inline-block;
    line-height: 7px;
    vertical-align: middle; }
    /* line 28, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
    .video-posts-pane .owl-pagination .owl-page {
      margin: 2px 2px 3px; }
      /* line 30, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
      .video-posts-pane .owl-pagination .owl-page span {
        background: #fff; }
      /* line 34, D:/aartyomenko/Git/spectr-html/app/scss/_main_video_posts.scss */
      .video-posts-pane .owl-pagination .owl-page.active span {
        background: #fd3a13;
        border-color: #fd3a13; }

@media screen and (max-width: 1520px) {
  /* line 4, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-full:last-child {
      margin-right: 0; }
  /* line 7, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-half:last-child {
      margin-right: 0; }
  /* line 10, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-one-third:last-child {
      margin-right: 0; }
  /* line 13, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-two-thirds:last-child {
      margin-right: 0; }
  /* line 16, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-one-quarter:last-child {
      margin-right: 0; }
  /* line 19, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-one-sixth:last-child {
      margin-right: 0; }
  /* line 22, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-three-quarters:last-child {
      margin-right: 0; }
  /* line 25, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-one-fifth:last-child {
      margin-right: 0; }
  /* line 28, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-four-fifths:last-child {
      margin-right: 0; }
  /* line 31, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-two-fifths:last-child {
      margin-right: 0; }
  /* line 34, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-three-fifths:last-child {
      margin-right: 0; }
  /* line 37, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-half:last-child {
      margin-right: 0; }
  /* line 40, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-one-third:last-child {
      margin-right: 0; }
  /* line 43, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 46, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 49, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 52, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 55, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 58, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 61, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .lg-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 64, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .lg-hide {
    display: none !important; } }

@media screen and (max-width: 1229px) {
  /* line 72, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-full:last-child {
      margin-right: 0; }
  /* line 75, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-half:last-child {
      margin-right: 0; }
  /* line 78, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-one-third:last-child {
      margin-right: 0; }
  /* line 81, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-two-thirds:last-child {
      margin-right: 0; }
  /* line 84, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-one-quarter:last-child {
      margin-right: 0; }
  /* line 87, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-one-sixth:last-child {
      margin-right: 0; }
  /* line 90, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-three-quarters:last-child {
      margin-right: 0; }
  /* line 93, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-one-fifth:last-child {
      margin-right: 0; }
  /* line 96, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-four-fifths:last-child {
      margin-right: 0; }
  /* line 99, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-two-fifths:last-child {
      margin-right: 0; }
  /* line 102, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-three-fifths:last-child {
      margin-right: 0; }
  /* line 105, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-half:last-child {
      margin-right: 0; }
  /* line 108, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-one-third:last-child {
      margin-right: 0; }
  /* line 111, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 114, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 117, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 120, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 123, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 126, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 129, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .md-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 132, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .md-hide {
    display: none !important; } }

@media screen and (max-width: 1077px) {
  /* line 140, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-full:last-child {
      margin-right: 0; }
  /* line 143, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-half:last-child {
      margin-right: 0; }
  /* line 146, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-one-third:last-child {
      margin-right: 0; }
  /* line 149, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-two-thirds:last-child {
      margin-right: 0; }
  /* line 152, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-one-quarter:last-child {
      margin-right: 0; }
  /* line 155, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-one-sixth:last-child {
      margin-right: 0; }
  /* line 158, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-three-quarters:last-child {
      margin-right: 0; }
  /* line 161, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-one-fifth:last-child {
      margin-right: 0; }
  /* line 164, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-four-fifths:last-child {
      margin-right: 0; }
  /* line 167, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-two-fifths:last-child {
      margin-right: 0; }
  /* line 170, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-three-fifths:last-child {
      margin-right: 0; }
  /* line 173, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-half:last-child {
      margin-right: 0; }
  /* line 176, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-one-third:last-child {
      margin-right: 0; }
  /* line 179, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 182, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 185, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 188, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 191, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 194, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 197, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .sm-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 200, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .sm-hide {
    display: none !important; } }

@media screen and (max-width: 767px) {
  /* line 207, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-full:last-child {
      margin-right: 0; }
  /* line 210, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-half:last-child {
      margin-right: 0; }
  /* line 213, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-one-third:last-child {
      margin-right: 0; }
  /* line 216, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-two-thirds:last-child {
      margin-right: 0; }
  /* line 219, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-one-quarter:last-child {
      margin-right: 0; }
  /* line 222, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-one-sixth:last-child {
      margin-right: 0; }
  /* line 225, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-three-quarters:last-child {
      margin-right: 0; }
  /* line 228, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-one-fifth:last-child {
      margin-right: 0; }
  /* line 231, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-four-fifths:last-child {
      margin-right: 0; }
  /* line 234, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-two-fifths:last-child {
      margin-right: 0; }
  /* line 237, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-three-fifths:last-child {
      margin-right: 0; }
  /* line 240, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-half:last-child {
      margin-right: 0; }
  /* line 243, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-one-third:last-child {
      margin-right: 0; }
  /* line 246, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 249, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 252, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 255, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 258, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 261, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 264, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .xs-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 267, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .xs-hide {
    display: none !important; } }

@media screen and (max-width: 639px) {
  /* line 274, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-full:last-child {
      margin-right: 0; }
  /* line 277, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-half:last-child {
      margin-right: 0; }
  /* line 280, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-one-third:last-child {
      margin-right: 0; }
  /* line 283, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-two-thirds:last-child {
      margin-right: 0; }
  /* line 286, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-one-quarter:last-child {
      margin-right: 0; }
  /* line 289, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-one-sixth:last-child {
      margin-right: 0; }
  /* line 292, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-three-quarters:last-child {
      margin-right: 0; }
  /* line 295, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-one-fifth:last-child {
      margin-right: 0; }
  /* line 298, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-four-fifths:last-child {
      margin-right: 0; }
  /* line 301, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-two-fifths:last-child {
      margin-right: 0; }
  /* line 304, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-three-fifths:last-child {
      margin-right: 0; }
  /* line 307, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-half:last-child {
      margin-right: 0; }
  /* line 310, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-one-third:last-child {
      margin-right: 0; }
  /* line 313, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 316, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 319, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 322, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 325, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 328, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 331, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-ls-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 334, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-ls-hide {
    display: none !important; } }

@media screen and (max-width: 479px) {
  /* line 342, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-full {
    float: left;
    display: block;
    margin-right: 0%;
    width: 100%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-full:last-child {
      margin-right: 0; }
  /* line 345, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-half:last-child {
      margin-right: 0; }
  /* line 348, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-one-third:last-child {
      margin-right: 0; }
  /* line 351, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-two-thirds:last-child {
      margin-right: 0; }
  /* line 354, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-one-quarter:last-child {
      margin-right: 0; }
  /* line 357, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-one-sixth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 16.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-one-sixth:last-child {
      margin-right: 0; }
  /* line 360, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-three-quarters:last-child {
      margin-right: 0; }
  /* line 363, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-one-fifth:last-child {
      margin-right: 0; }
  /* line 366, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-four-fifths:last-child {
      margin-right: 0; }
  /* line 369, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-two-fifths:last-child {
      margin-right: 0; }
  /* line 372, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-three-fifths:last-child {
      margin-right: 0; }
  /* line 375, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-half {
    float: left;
    display: block;
    margin-right: 0%;
    width: 50%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-half:last-child {
      margin-right: 0; }
  /* line 378, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-one-third {
    float: left;
    display: block;
    margin-right: 0%;
    width: 33.33333%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-one-third:last-child {
      margin-right: 0; }
  /* line 381, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-two-thirds {
    float: left;
    display: block;
    margin-right: 0%;
    width: 66.66667%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-two-thirds:last-child {
      margin-right: 0; }
  /* line 384, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-one-quarter {
    float: left;
    display: block;
    margin-right: 0%;
    width: 25%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-one-quarter:last-child {
      margin-right: 0; }
  /* line 387, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-three-quarters {
    float: left;
    display: block;
    margin-right: 0%;
    width: 75%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-three-quarters:last-child {
      margin-right: 0; }
  /* line 390, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-one-fifth {
    float: left;
    display: block;
    margin-right: 0%;
    width: 20%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-one-fifth:last-child {
      margin-right: 0; }
  /* line 393, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-two-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 40%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-two-fifths:last-child {
      margin-right: 0; }
  /* line 396, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-three-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 60%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-three-fifths:last-child {
      margin-right: 0; }
  /* line 399, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-col-four-fifths {
    float: left;
    display: block;
    margin-right: 0%;
    width: 80%; }
    /* line 89, D:/aartyomenko/Git/spectr-html/app/vendor/neat/app/assets/stylesheets/grid/_span-columns.scss */
    .mb-pt-col-four-fifths:last-child {
      margin-right: 0; }
  /* line 402, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .mb-pt-hide {
    display: none !important; } }

@media screen and (max-width: 1077px) {
  /* line 411, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .main-footer .bottom-part .ft-social {
    margin-top: 25px; } }

@media screen and (max-width: 1077px) {
  /* line 419, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .ft-menu {
    text-align: center; } }

@media screen and (max-width: 1077px) {
  /* line 427, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .main-slider-1 .ptp-1-data {
    padding: 0 60px 27px; } }

@media screen and (max-width: 767px) {
  /* line 432, D:/aartyomenko/Git/spectr-html/app/scss/_media.scss */
  .main-slider-1 .ptp-1-data {
    padding: 0 30px 27px; } }
