$main-font: 'Poppins';
$content-font: 'Noto Sans';
$accent-font: 'Inconsolata';
$accent-font-2: 'Montserrat';
$accent-font-3: 'Georgia';

$accent-color: #fd3a13;

$md-index: 0.9;
$sm-index: 0.8;
$mb-index: 0.6;