.posts {
    .post {
        &:last-child {
            border: none;
            &.post-tp-17 {
                padding-bottom: 15px;
                margin-bottom: 0;
            }
        }
        &.post-tp-24,
        &.post-tp-25,
        &.post-tp-26 {
            &:first-child {
                margin-top: 8px;
            }
        }
    }
}

.post {
    @extend .clearfix;
    position: relative;
    &:hover {
        .title-3,
        .title-5,
        .title-6,
        .title-10 {
            color: $accent-color;
        }
    }
    figure {
        img {
            width: 100%;
        }
    }
    .ptp-1-overlay,
    .ptp-27-overlay,
    .ptp-28-overlay,
    .ptp-31-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    .meta-tp-1 {
        div {
            pointer-events: auto;
        }
    }
    .category-tp-1,
    .date-tp-1 {
        pointer-events: auto;
    }
    a {
        pointer-events: auto;
    }
}

.post-tp-1 {
    color: #fff;
    overflow: hidden;
    &:hover {
        figure {
            a:before {
                background: rgba(28, 3, 3, .27);
            }
        }
    }
    figure {
        position: relative;
        overflow: hidden;
        max-height: 100%;
        img {
            width: 100%;
        }
        &:before {
            @include background(linear-gradient(transparent, rgba(0, 0, 0, .36)) top repeat);
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 40%;
            pointer-events: none;
        }
        a {
            display: block;
            position: relative;
            &:before {
                background: rgba(0, 0, 0, .20);
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: all 500ms;
            }
        }
    }
    .ptp-1-data {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        width: 100%;
        padding: 0 30px 27px;
    }
    .title-1 {
        margin: 0 0 6px;
    }
    .meta-tp-1 {
        margin: 0 0 20px;
    }
}

.post-tpv-1 {
    @extend .post-tp-1;
    &:hover {
        figure {
            a:before {
                background: rgba(0, 0, 0, .36);
            }
        }
    }
    figure {
        position: relative;
        &:before {
            @include background(linear-gradient(transparent, rgba(0, 0, 0, .36)) top repeat);
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 45%;
        }
        a {
            &:before {
                background: rgba(0, 0, 0, .26);
            }
        }
        img {
            width: 100%;
        }
    }
    .ptp-1-data {
        padding: 0 23px;
    }
    .video-lg-ic {
        position: absolute;
        left: 24px;
        top: 24px;
    }
    .video-sm-ic {
        margin: 0 0 14px;
    }
    .title-7 {
        margin-bottom: 7px;
    }
    .category-tp-1 {
        margin-bottom: 18px;
    }
}

.post-tpv-2 {
    @extend .post-tpv-1;
    &:hover {
        figure {
            a:before {
                background: rgba(0, 0, 0, .45);
            }
        }
        .title-5 {
            color: inherit;
        }
    }
    figure {
        a {
            &:before {
                background: rgba(0, 0, 0, .35);
            }
        }
    }
    .title-5 {
        color: #fff;
    }
    .ptp-1-data {
        padding: 10px 16px;
    }
    .date-tp-2 {
        color: #fff;
    }
}

.post-tp-2 {
    @extend .post-tp-1;
    &:hover {
        figure {
            a:before {
                background: rgba(0, 0, 0, .5);
            }
        }
    }
    figure {
        a {
            &:before {
                background: rgba(0, 0, 0, .40);
            }
        }
    }
    .title-2 {
        margin-bottom: 6px;
    }
    .ptp-1-data {
        padding: 0 20px 20px;
    }
    .meta-tp-1 {
        margin: 0;
    }
    .category-tp-1 {
        margin: 0 0 16px;
    }
    .ptp-1-views,
    .ptp-1-comments {
        i {
            position: relative;
            top: 1px;
        }
    }
}

.post-tp-3 {
    margin: 0 0 15px;
    figure {
        float: left;
        margin: 7px 20px 0 0;
    }
    .category-tp-2 {
        margin-bottom: 5px;
    }
    .title-3 {
        margin-bottom: 2px;
    }
}

.post-tp-4 {
    margin: 23px 0;
    &:hover {
        .ptp-4-overlay {
            opacity: 1;
        }
        .ptp-4-data {
            transform: translateY(0);
        }
    }
    .ptp-4-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-4-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
    figure {
        margin: 0 0 20px 0;
        position: relative;
    }
    .title-3 {
        margin-bottom: 3px;
    }
}

.post-tp-5 {
    padding: 11px 0 11px;
    &:hover {
        .ptp-5-overlay {
            opacity: 1;
        }
        .ptp-5-data {
            transform: translateY(0);
        }
    }
    .ptp-5-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-5-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
    figure {
        margin: 0 0 20px 0;
        position: relative;
    }
    .meta-tp-2 {
        margin-bottom: 4px;
    }
    .title-5 {
        margin-bottom: 5px;
    }
    p {
        margin-bottom: 5px;
    }
}

.post-tp-6 {
    padding: 7px 0 11px;
    figure {
        float: left;
        margin: 4px 18px 0 0;
        @include media($md) {
            max-width: 105px;
        }
    }
    .title-6 {
        margin-bottom: 5px;
    }
    .rate-tp-1 {
        margin: 4px 0;
    }
}

.post-tp-7 {
    padding: 8px 0 5px;
    text-align: center;
    .title-5 {
        margin-bottom: 10px;
    }
    figure {
        border-radius: 50%;
        width: 67px;
        height: 67px;
        margin: 0 auto 12px;
        overflow: hidden;
    }
    p {
        margin-bottom: 6px;
    }
}

.post-tp-8 {
    margin: 8px 0 18px;
    &:hover {
        .ptp-8-overlay {
            opacity: 1;
        }
        .ptp-8-data {
            transform: translateY(0);
        }
    }
    .ptp-8-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-8-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
    figure {
        margin: 0 0 20px 0;
        position: relative;
    }
    .title-5 {
        margin-bottom: 4px;
    }
}

.post-tp-9 {
    @extend .post-tp-6;
    border-bottom: 1px dashed #ebebeb;
    padding: 10px 0px 15px;
    &:last-child {
        border: none;
    }
}

.post-tp-10 {
    @extend .post-tp-5;
    .ptp-10-data {
        padding: 3px 20px 29px;
    }
}

.post-tp-11 {
    .meta-tp-2 {
        margin-bottom: 5px;
    }
}

.post-tp-12 {
    @extend .post-tp-2;
    &:hover {
        figure {
            a:before {
                background: rgba(28, 3, 3, .27);
            }
        }
    }
    figure {
        &:before {
            @include background(linear-gradient(transparent, rgba(0, 0, 0, .36)) top repeat);
        }
        a {
            &:before {
                background: rgba(28, 3, 3, .20);
            }
        }
    }
    .category-tp-1 {
        margin: 0px 0px 15px;
    }
    .title-15 {
        margin-bottom: 4px;
    }
    .title-13 {
        margin-bottom: 7px;
    }
}

.post-tp-13 {
    margin-bottom: 14px;
    &:hover {
        .ptp-13-overlay {
            opacity: 1;
        }
        .ptp-13-data {
            transform: translateY(0);
        }
    }
    figure {
        float: left;
        margin: 8px 20px 0 0;
        position: relative;
    }
    .category-tp-4 {
        margin-bottom: 5px;
    }
    .meta-tp-2 {
        margin: 4px 0;
    }
    .ptp-13-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-13-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
}

.post-tp-14 {
    @extend .post-tp-2;
    &:hover {
        figure {
            a:before {
                background: rgba(28, 3, 3, .40);
            }
        }
    }
    figure {
        &:before {
            @include background(linear-gradient(transparent, rgba(0, 0, 0, .60)) top repeat);
        }
        a {
            &:before {
                background: rgba(28, 3, 3, .20);
            }
        }
    }
}

.post-tp-15 {
    figure {
        position: relative;
        margin-bottom: 18px;
    }
    .title-5 {
        margin-bottom: 6px;
    }
    p {
        margin-bottom: 4px;
    }
    .category-tp-1 {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 14px;
    }
}

.post-tp-16 {
    @extend .post-tp-5;
    padding: 8px 0;
    .title-14 {
        margin-bottom: 4px;
    }
    p {
        line-height: 23px;
    }
}

.post-tp-17 {
    @extend .post-tp-13;
    border-bottom: 1px solid #f2f2f2;
    padding: 0 0 20px;
    margin-bottom: 13px;
    @include media($mb-pt) {
        padding-top: 7px;
    }
    figure {
        margin: 7px 20px 0 0;
        @include media($mb-ls) {
            img {
                width: 160px;
            }
        }
        @include media($mb-pt) {
            float: none;
            margin: 0 0 20px 0;
            img {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.post-tp-18 {
    @extend .post-tp-15;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 0 14px;
    margin-bottom: 12px;
}

.post-tp-19 {
    figure {
        position: relative;
        margin: 0 0 6px;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-top: 6px solid $accent-color;
            border-right: 6px solid transparent;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 2;
        }
        &:after {
            content: '';
            background: #f0f0f0;
            width: 1px;
            height: 32px;
            position: absolute;
            left: 0;
            top: 100%;
            z-index: 1;
        }
    }
    .data {
        padding: 13px 20px 20px;
    }
    .meta-tp-2 {
        margin: 3px 0 1px;
    }
}

.post-tp-20 {
    .ptp-20-overlay {
        background: #fff;
        color: #6d6d6d;
        height: 50%;
        position: relative;
        &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            left: 50%;
            margin-left: -5px;
            z-index: 2;
        }
        &.up {
            margin-bottom: 1px;
            &:before {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #fff;
                top: 100%;
            }
        }
        &.down {
            margin-top: 1px;
            &:before {
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #fff;
                position: absolute;
                bottom: 100%;
            }
        }
    }
    .ptp-20-data {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 12px;
    }
    .meta-tp-1 {
        color: #888;
        margin: 4px 0 7px;
        > div {
            &:before {
                background: rgba(174, 174, 174, .24);
            }
        }
    }
    .date-tp-1 {
        margin: 20px;
    }
}

.post-tp-21 {
    @extend .post-tp-17;
    margin: 16px 0 0;
    figure {
        margin: 4px 20px 0 0;
        @include media($mb-ls) {
            img {
                width: 160px;
            }
        }
        @include media($mb-pt) {
            float: none;
            margin: 4px 0 20px 0;
            img {
                width: 100%;
                max-width: none;
            }
        }
    }
}

.post-tp-22 {
    background: #fff;
    margin: 0 0 20px;
    &:last-child {
        margin: 0;
    }
    .title-3 {
        margin: 0 0 4px;
    }
    .data {
        padding: 15px 18px 11px;
    }
}

.post-tp-23 {
    background: #fff;
    margin: 0 0 20px;
    &:hover {
        .ptp-23-overlay {
            opacity: 1;
        }
        .top-rate {
            &:before {
                border-bottom-color: $accent-color;
            }
        }
        .title-3 {
            color: #fff;
        }
    }
    &:last-child {
        margin: 0;
    }
    .ptp-23-overlay {
        background: rgba(0, 0, 0, .56);
        padding: 16px 18px;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
    }
}

.post-tp-24 {
    border-bottom: 1px solid #f2f2f2;
    padding: 0 0 20px;
    margin: 0 0 22px;
    &:hover {
        .ptp-24-overlay {
            opacity: 1;
        }
        .ptp-24-data {
            transform: translateY(0);
        }
    }
    .ptp-24-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-24-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
    figure {
        float: left;
        margin: 0 20px 0 0;
        position: relative;
        @include media($mb-ls) {
            img {
                width: 160px;
            }
        }
        @include media($mb-pt) {
            float: none;
            margin: 0 0 20px 0;
            img {
                width: 100%;
                max-width: none;
            }
        }
    }
    .title-14 {
        margin-bottom: 4px;
    }
    .meta-tp-2 {
        margin: 5px 0;
    }
}

.post-tp-25 {
    border-bottom: 1px solid #f2f2f2;
    padding: 0 0 20px;
    margin: 0 0 30px;
    &:hover {
        .ptp-25-overlay {
            opacity: 1;
        }
        .ptp-25-data {
            transform: translateY(0);
        }
    }
    .ptp-25-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-25-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
    figure {
        margin: 0 0 23px 0;
        position: relative;
    }
    .title-17 {
        margin-bottom: 6px;
    }
    .meta-tp-2 {
        margin: 7px 0 2px;
    }
    p {
        font-size: 14px;
        line-height: 23px;
    }
}

.post-tp-26 {
    border-bottom: 1px solid #f2f2f2;
    padding: 0 0 20px 59px;
    margin: 0 0 20px;
    @include media($mb-ls) {
        padding: 0 0 20px 0;
        .date-tp-3 {
            z-index: 1;
        }
    }
    &:hover {
        figure {
            &:before {
                opacity: 1;
            }
        }
    }
    figure {
        float: left;
        margin: 0 20px 0 0;
        position: relative;
        &:before {
            content: '';
            background-color: rgba(0, 0, 0, .07);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            pointer-events: none;
        }
        @include media($mb-ls) {
            img {
                width: 160px;
            }
        }
        @include media($mb-pt) {
            float: none;
            margin: 0 0 20px 0;
            img {
                width: 100%;
                max-width: none;
            }
        }
    }
    .meta-tp-2 {
        margin: 5px 0;
    }
    .date-tp-3 {
        position: absolute;
        left: 0;
        top: 0;
    }
}

.post-tp-27 {
    &:hover {
        .ptp-27-overlay {
            background-color: rgba(15,2,2,.54);
        }
    }
    .ptp-27-overlay {
        background-color: rgba(22,4,4,.4);
        padding: 40px 20%;
        text-align: center;
        @include media($xs) {
            padding: 40px 5%;
        }
    }
    .category-tp-1 {
        margin-bottom: 22px;
    }
    .meta-tp-4 {
        margin: 9px 0 0;
    }
    &.mb-height {
        overflow: hidden;
        @include media($mb-ls) {
            height: 350px;
            figure,
            a,
            img {
                height: 100%;
            }
            figure img {
                max-width: none;
                width: auto;
            }
        }
    }
}

.post-tp-28 {
    &:hover {
        .ptp-28-overlay {
            background-color: rgba(0,0,0,.5);
        }
    }
    .ptp-28-overlay {
        background-color: rgba(0,0,0,.5);
        padding: 40px 8%;
        text-align: center;
        @include media($mb-ls) {
            padding: 40px 15px;
        }
    }
    .category-tp-1 {
        margin-bottom: 22px;
    }
    .date-tp-4 {
        margin: 9px 0;
    }
    .icon {
        margin: 0 0 36px;
    }
    &.mb-height {
        overflow: hidden;
        @include media($mb-ls) {
            height: 350px;
            figure,
            a,
            img {
                height: 100%;
            }
            figure img {
                max-width: none;
                width: auto;
            }
        }
    }
}

.post-tp-29 {
    margin: 0 0 24px;
    .date-tp-2 {
        margin: 13px 0 4px;
    }
}

.post-tp-30 {
    figure {
        position: relative;
        margin-bottom: 15px;
    }
    &:hover {
        .ptp-30-overlay {
            opacity: 1;
        }
        .ptp-30-data {
            transform: translateY(0);
        }
    }
    .ptp-30-overlay {
        background: rgba(31, 33, 36, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 7px solid $accent-color;
            border-right: 7px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        a,
        span {
            color: #fff;
            font: 700 12px/23px $accent-font;
            margin: 0 4px 0 0;
            text-decoration: none;
            pointer-events: auto;
            i {
                font-size: 11px;
                margin: 0 6px 0 0;
            }
        }
    }
    .ptp-30-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 6px 16px;
        transform: translateY(25px);
        transition: all 300ms;
    }
}

.post-tp-31 {
    @include media($mb-ls) {
        height: 350px;
        figure,
        a,
        img {
            height: 100%;
        }
        figure img {
            max-width: none;
            width: auto;
        }
    }
    &:hover {
        .ptp-31-overlay {
            background-color: rgba(15,2,2,.54);
        }
    }
    .ptp-31-overlay {
        background-color: rgba(22,4,4,.4);
        padding: 40px 20%;
        text-align: center;
        @include media($sm) {
            padding: 40px 10%;
        }
        @include media($mb-ls) {
            padding: 40px 15px;
        }
    }
    .category-tp-1 {
        margin-bottom: 22px;
    }
    .meta-tp-4 {
        margin: 9px 0 0;
    }
}
