.main-footer {
    p {
        color: #bec1c3;
    }
}

.ft-widget {
    margin: 0 0 22px;
    @include media($md) {
         margin: 0 0 37px;
    }
}

.top-part {
    border-bottom: 1px solid #303338;
    padding: 40px 0 10px;
    .ft-title-1 {
        margin: 0 0 12px;
    }
}

.ft-list-1,
.ft-list-2 {
    li {
        border-bottom: 1px dashed #303338;
        padding: 10px 0 12px;
        @extend .clearfix;
    }
    .date {
        color: #888;
        font-family: $accent-font;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
    }
    figure {
        float: left;
        margin: 4px 18px 2px 0;
        img {
            display: block;
        }
    }
}

.ft-list-2 {
    li:last-child {
        border: none;
    }
}

.bottom-part {
    color: #bec1c3;
    padding: 40px 0;
}

.ft-social-list {
    @extend .clearfix;
    li {
        float: left;
        margin: 0 2px 0 1px;
    }
    a {
        border: 1px solid #303338;
        border-radius: 50%;
        color: #fff;
        font-size: 12px;
        line-height: 34px;
        display: block;
        width: 34px;
        height: 34px;
        text-align: center;
    }
    .tw a:hover {
        color: #00abed;
    }
    .fb a:hover {
        color: #4c68b3;
    }
    .gp a:hover {
        color: #e51616;
    }
    .in a:hover {
        color: #1d5e9f;
    }
    .dr a:hover {
        color: #f51f9d;
    }
}

.bottom-bar {
    background: #121314;
    color: #fff;
    font-size: 11px;
    font-weight: 500;
    padding: 18px 0 17px;
    text-transform: uppercase;
}

.ft-menu-list {
    @extend .clearfix;
    @include media($sm) {
        display: inline-block;
    }
    @include media($xs) {
        margin: 15px 0;
    }
    li {
        float: left;
        margin: 0 25px 0 0;
        &:last-child {
            margin: 0;
        }
        @include media($xs) {
            float: none;
            text-align: center;
            margin: 0 0 5px;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
    }
}

.copyright {
    text-align: right;
    @include media($sm) {
        text-align: center;
        margin-top: 15px;
    }
}

.ft-title-1 {
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    margin: 0 0 20px;
    padding: 0;
}

.ft-title-2 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0 0 4px;
    padding: 0;
    a {
        color: #fff;
        text-decoration: none;
    }
}

.ft-search {
    padding: 15px 0;
    .search {
        background: #272a2e;
        border-radius: 2px;
        display: table;
        padding: 7px 0;
        width: 100%;
        height: 38px;
        .search-input,
        .search-btn {
            display: table-cell;
        }
        .search-btn {
            width: 22%;
            position: relative;
            &:before {
                content: '';
                background: #3b3f45;
                width: 1px;
                position: absolute;
                top: 5px;
                bottom: 5px;
            }
        }
        input {
            background-color: transparent;
            border: none;
            color: #fff;
            font: 11px $content-font;
            width: 100%;
            height: 24px;
            padding: 0 12px;
        }
        button {
            appearance: none;
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: #fff;
            font: 500 11px $main-font;
            width: 100%;
            height: 24px;
            padding: 0 6px;
            text-align: center;
            text-transform: uppercase;
        }
    }
}

.inst-list {
    margin: 0 -1px 0 -5px;
    padding: 12px 0;
    @extend .clearfix;
    li {
        width: 20%;
        float: left;
        padding: 0 0 0 5px;
    }
    img {
        display: block;
        width: 100%;
    }
}
