.content,
.content-sm {
    .pst-block {
        margin-bottom: 30px;
    }
    > div:last-child,
    .js-sidebar-zone > div:last-child {
        > .pst-block {
            margin: 0;
        }
    }
}

.page-content {
    background-color: #fff;
    padding: 15px 20px;
    &.trsp {
        background: none;
        padding: 0;
    }
}

.side-bar,
.side-bar-sm {
    .pst-block {
        margin-bottom: 30px;
    }
    > div > div:last-child {
        .pst-block {
            margin: 0;
        }
    }
    // .js-sidebar {
    //     > div:last-child {
    //         margin: 0;
    //     }
    // }
}

.side-bar-sm {
    .sbsb-block-1 {
        margin-bottom: 0;
    }
}

.full-section {
    margin-bottom: 45px;
}

.category-tp-1 {
    background: $accent-color;
    color: #fff;
    display: inline-block;
    font-family: $accent-font;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    height: 20px;
    padding: 3px 7px 2px 9px;
    position: relative;
    margin: 0 0 15px;
    text-transform: uppercase;
    text-decoration: none;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 4px solid $accent-color;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: -4px;
    }
}

.category-tp-2 {
    color: #888;
    display: inline-block;
    font: 400 12px/23px $accent-font;
    text-decoration: none;
    text-transform: uppercase;
}

.category-tp-3 {
    @extend .category-tp-1;
    background: #000;
    &:after {
        border-left: 4px solid #000;
    }
}

.category-tp-4 {
    color: #888;
    display: inline-block;
    font: 700 12px/23px $accent-font;
    text-decoration: none;
    text-transform: uppercase;
}

.category-tp-5 {
    background: $accent-color;
    color: #fff;
    display: inline-block;
    font-family: $accent-font;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    height: 20px;
    padding: 3px 7px 2px 9px;
    position: relative;
    margin: 0 0 15px;
    text-transform: uppercase;
    text-decoration: none;
}

.reviews-tp-1 {
    background: #000;
    color: #fff;
    display: inline-block;
    font-family: $accent-font;
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    height: 20px;
    padding: 3px 7px 2px 9px;
    position: relative;
    margin: 0 0 15px;
    text-transform: uppercase;
    text-decoration: none;
    &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 4px solid #000;
        border-bottom: 10px solid transparent;
        position: absolute;
        top: 0;
        right: -4px;
    }
}

.date-tp-1 {
    display: inline-block;
    font-size: 34px;
    font-weight: 300;
    line-height: 38px;
    margin: 26px 30px;
    sub {
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
        text-transform: uppercase;
        vertical-align: top;
    }
    .month {
        display: block;
        font-size: 10px;
        font-weight: 500;
        line-height: 10px;
        text-transform: uppercase;
    }
}

.date-tp-2 {
    color: #888;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
}

.date-tp-3 {
    background-color: #f2f2f2;
    color: #000;
    font-size: 20px;
    line-height: 19px;
    width: 39px;
    padding: 9px 6px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    span {
        display: block;
        font: 400 10px/10px $accent-font;
    }
}

.date-tp-4 {
    color: #fff;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    i {
        font-size: 10px;
        margin: 0 6px;
    }
}

.author-tp-1 {
    color: #888;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    i {
        font-size: 10px;
        margin: 0 7px 0 0;
    }
}

.author-tp-2 {
    color: #fff;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    i {
        font-size: 10px;
        margin: 0 7px 0 0;
    }
    .photo {
        background-color: #f5f5f5;
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0 10px 0 0;
        vertical-align: middle;
        @include media($mb-pt) {
            display: none;
        }
    }
}

.meta-tp-1 {
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    > div {
        display: inline-block;
        padding: 0 4px 0 0;
        white-space: nowrap;
        position: relative;
        &:first-child {
            margin: 0 9px 0 0;
        }
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    i {
        font-size: 11px;
        margin: 0 7px 0 0;
    }
}

.meta-tp-2 {
    color: #888;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    > div {
        display: inline-block;
        padding: 0 13px 0 0;
        margin: 0 7px 0 0;
        white-space: nowrap;
        position: relative;
        &:before {
            background: rgba(174, 174, 174, .24);
            content: '';
            width: 1px;
            position: absolute;
            right: 0;
            top: 6px;
            bottom: 4px;
        }
        &:last-child {
            &:before {
                display: none;
            }
        }
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    i {
        font-size: 10px;
        margin: 0 7px 0 0;
    }
}

.meta-tp-3 {
    color: #888;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    > div {
        display: inline-block;
        margin: 0 7px 0 0;
        white-space: nowrap;
        position: relative;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    i {
        font-size: 11px;
        margin: 0 7px 0 0;
    }
}

.meta-tp-4 {
    color: #fff;
    font: 400 12px/23px $accent-font;
    text-transform: uppercase;
    > div {
        display: inline-block;
        margin: 0 9px;
        white-space: nowrap;
        position: relative;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    i {
        font-size: 10px;
        margin: 0 7px 0 0;
    }
    .ptp-1-comments {
        margin-left: 0;
    }
}

.meta-tp-5 {
    @extend .meta-tp-4;
    color: #888;
    .author-tp-2,
    .date-tp-4 {
        color: #888;
    }
    .author-tp-2 {
        margin-left: 0;
    }
}

.rate-tp-1 {
    ul {
        font-size: 0;
    }
    li {
        display: inline-block;
        margin-right: 3px;
        &:last-child {
            margin: 0;
        }
        i {
            color: $accent-color;
            font-size: 12px;
            margin: 0;
        }
    }
    a {
        text-decoration: none;
    }
}

.rate-tp-2 {
    ul {
        @extend .clearfix;
    }
    li {
        color: #c2c2c2;
        float: left;
        margin-right: 4px;
        &:last-child {
            margin: 0;
        }
        &.active {
            color: $accent-color;
        }
        i {
            display: block;
            font-size: 15px;
            margin: 0;
        }
    }
    a {
        color: inherit;
        line-height: 26px;
        display: block;
        text-decoration: none;
    }
}

.read-tp-1,
.save-tp-1 {
    color: #fff;
    font: 700 12px/25px $accent-font;
    text-decoration: none;
    text-transform: uppercase;
    span {
        display: inline-block;
        vertical-align: middle;
        margin: 0 4px 0 0;
    }
}

.trend-pst-slider {
    height: 100%;
    .pst-block-main {
        margin: -5px 0 0;
    }
    .trpst-block {
        height: 100%;
        padding: 5px 0 51px;
        position: relative;
    }
    .trpst-block-foot {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        .more-txt {
            position: relative;
            top: 1px;
        }
    }
    .sbr-dots {
        float: right;
        padding: 0;
    }
    @include media($md) {
        .post-tp-3 {
            margin: 0 0 7px;
            .adaptive {
                max-width: 103px;
            }
        }
    }
}

.pst-block-head {
    @extend .clearfix;
    background: #fff;
    padding: 14px 20px 13px;
    position: relative;
    margin-bottom: 7px;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .title-4 {
        position: relative;
        top: 1px;
    }
}

.pst-block-main {
    @extend .clearfix;
    background: #fff;
    padding: 15px 20px;
    &.trsp {
        background: none;
        padding: 0;
        margin-bottom: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
}

.pst-block-foot {
    background: #fcfcfc;
    color: #1f1f1f;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    margin-top: 7px;
    text-transform: uppercase;
    a {
        color: inherit;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 13px 20px;
        &:hover {
            color: $accent-color;
        }
    }
    .arr-right-dark-ic {
        float: right;
    }
}

.pst-box {
    margin-bottom: 50px;
}

.pst-box-head {
    @extend .clearfix;
    .title-blc-1 {
        float: left;
        padding: 0 24px;
        margin: 22px -24px;
    }
    .navs {
        color: #9b9b9b;
        font-size: 17px;
        float: right;
        margin: 19px -7px;
        span {
            cursor: pointer;
            display: inline-block;
            float: left;
            padding: 0 7px;
        }
    }
}

.pst-box-main {
    border-bottom: 1px solid #f7f7f7;
    .pst-box-inner {
        @extend .clearfix;
        margin-left: -9px;
        margin-right: -9px;
        img {
            width: 100%;
        }
    }
    .one-third {
        padding: 0 9px;
    }
    .title-6 {
        margin-bottom: 19px;
    }
}

.pst-block-hr {
    border: none;
    border-top: 1px solid #f2f2f2;
    margin: 12px -20px;
}

.xs-hr {
    width: 24px;
    border: none;
    border-top: 1px solid #d8d8d8;
    margin: 15px 0;
}

.vpst-block {
    padding: 18px 0 0;
}

.vpst-block-head {
    @extend .clearfix;
    margin: 22px 0;
    padding: 0 0 0 18px;
    position: relative;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    i {
        &:before {
            vertical-align: middle;
        }
    }
    .title-4 {
        color: #fff;
    }
    .filters {
        color: #cdcdcd;
        .filters-list-1 {
            border-color: #383b3f;
            a {
                &:hover,
                &.active {
                    color: #fff;
                }
            }
        }
    }
    .filters-drop {
        top: 130%;
        .arr {
            right: 4px;
        }
    }
}

.vpst-block-main {
    @extend .clearfix;
    margin: 0 0 30px;
}

.vpst-block-foot {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
    a {
        border-top: 1px solid #2e3136;
        color: inherit;
        display: block;
        text-align: center;
        text-decoration: none;
        padding: 14px 20px 13px;
    }
}

.trpst-block {
    // padding: 18px 0 0;
}

.trpst-block-head {
    @extend .clearfix;
    padding: 13px 20px 8px;
}

.trpst-block-main {
    padding: 0 20px;
}

.trpst-block-foot {
    background: #fcfcfc;
    color: #1f1f1f;
    font-size: 12px;
    font-weight: 500;
    line-height: 25px;
    text-transform: uppercase;
    a {
        color: inherit;
        display: block;
        text-decoration: none;
        padding: 13px 20px;
        &:hover {
            color: $accent-color;
        }
    }
    .arr-right-dark-ic {
        float: right;
    }
}

.filters {
    color: #929292;
    float: right;
}

.filters-list-1 {
    font: 400 13px/25px $accent-font;
    float: left;
    padding: 0 10px;
    text-transform: uppercase;
    @include media($xs) {
        display: none;
    }
    li {
        float: left;
    }
    a {
        color: inherit;
        display: block;
        padding: 0 10px;
        text-decoration: none;
        &:hover,
        &.active {
            color: #474747;
        }
    }
}

.filters-more {
    border-left: 1px solid #ebebeb;
    font-size: 18px;
    float: left;
    padding: 1px 0 1px 18px;
    i {
        &:before {
            vertical-align: middle;
        }
    }
}

.filters-btn {
    cursor: pointer;
}

.filters-drop {
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .15);
    border-radius: 2px;
    display: none;
    width: 130px;
    padding: 9px 0;
    position: absolute;
    top: 99%;
    right: -5px;
    z-index: 1;
    .arr {
        display: block;
        width: 20px;
        height: 10px;
        position: absolute;
        right: 24px;
        top: -10px;
        overflow: hidden;
        &:after {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background: #fff;
            transform: rotate(45deg);
            top: 5px;
            left: 5px;
            box-shadow: 0 0 6px rgba(0, 0, 0, .15);
        }
    }
    a {
        color: #929292;
        display: block;
        font: 700 13px/28px $accent-font;
        padding: 0 16px;
        text-decoration: none;
        text-transform: uppercase;
        &:hover,
        &.active {
            color: $accent-color;
        }
    }
}

.filters-list-2 {
    @extend .filters-list-1;
    border: none;
    margin: 0;
    padding: 0;
    a {
        padding: 0 0 0 6px;
    }
}

.filters-list-3 {
    @extend .clearfix;
    font: 500 12px/21px $main-font;
    text-transform: uppercase;
    li {
        float: left;
        margin-right: 12px;
    }
    a {
        color: inherit;
        display: block;
        padding: 2px 0 17px;
        position: relative;
        text-decoration: none;
        &:before {
            background: $accent-color;
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            opacity: 0;
            transform: scale(0.5, 1);
            transform-origin: 50% 0;
            transition: transform 300ms, opacity 200ms;
        }
        &:hover,
        &.active {
            color: #000;
            &:before {
                opacity: 1;
                transform: scale(1, 1);
            }
        }
    }
}

.filters-blc-1 {
    @extend .clearfix;
    background-color: #f8f8f8;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    padding: 22px 24px;
    .members-items {
        background-color: #fff;
        border-radius: 2px;
        display: inline-block;
        font-size: 9px;
        line-height: 22px;
        text-align: center;
        min-width: 23px;
        padding: 0 3px;
        margin-left: 6px;
        vertical-align: middle;
    }
    .filters-list {
        text-align: right;
        @include media($mb-pt) {
            text-align: left;
            margin-top: 10px;
        }
    }
}

.filters-blc-2 {
    @extend .clearfix;
    background-color: #fff;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
    padding: 32px 24px 7px;
    .members-items {
        background-color: #fff;
        border-radius: 2px;
        display: inline-block;
        font-size: 9px;
        line-height: 22px;
        text-align: center;
        min-width: 23px;
        padding: 0 3px;
        margin-left: 6px;
        vertical-align: middle;
    }
}

.all-sb {
    float: right;
    a {
        color: #474747;
        display: inline-block;
        font-family: $accent-font;
        font-weight: 400;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
            color: $accent-color;
        }
    }
}

.inner-filters {
    color: #838181;
    + .pst-block-hr {
        margin-top: 0;
    }
}

.post-navs {
    float: left;
    padding: 0 18px;
    a {
        text-decoration: none;
    }
}

.reviews-rate {
    color: #fff;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 39px;
    height: 39px;
    font-size: 10px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 0 0 15px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 0px;
        height: 0px;
        border-bottom: 39px solid #181818;
        border-left: 39px solid transparent;
    }
    span {
        position: relative;
        z-index: 2;
    }
}

.top-rate {
    color: #fff;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 39px;
    height: 39px;
    font-size: 10px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    padding: 15px 15px 0 0;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 0px;
        height: 0px;
        border-bottom: 39px solid #181818;
        border-right: 39px solid transparent;
    }
    span {
        position: relative;
        z-index: 2;
    }
}

// .spst-slider,
.js-pst-block {
    [class^="col-"],
    [class*=" col-"] {
        width: 100%;
    }
}

.latest-blg {
    .owl-item {
        padding: 0 10px;
        &.active + .active {
            border-left: 1px solid #f2f2f2;
        }
    }
}

.owl-pagination,
.sbr-dots {
    .owl-page,
    li {
        display: inline-block;
        margin: 2px;
        &.active,
        &.slick-active {
            span {
                background: $accent-color;
                border-color: $accent-color;
            }
        }
    }
    span {
        cursor: pointer;
        display: block;
        border: 1px solid #a9a9a9;
        border-radius: 50%;
        width: 7px;
        height: 7px;
    }
}

.sbr-dots {
    text-align: center;
    padding: 15px 20px 11px;
}

.social-tp-1 {
    padding: 8px 0;
}

.social-list {
    li {
        color: #fff;
        font-size: 12px;
        line-height: 19px;
        margin: 0 0 9px;
        &:last-child {
            margin: 0;
        }
        &.fb {
            background: #516fc2;
            .soc-ic {
                background: #4c68b3;
            }
        }
        &.tw {
            background: #09b2f4;
            .soc-ic {
                background: #00abed;
            }
        }
        &.gp {
            background: #f73232;
            .soc-ic {
                background: #eb1212;
            }
        }
    }
    a {
        color: inherit;
        display: block;
        height: 33px;
        padding: 8px 14px 8px 46px;
        position: relative;
        text-decoration: none;
    }
    .soc-btn {
        border-left: 1px solid rgba(255, 255, 255, .3);
        display: inline-block;
        float: right;
        padding: 0 0 0 16px;
    }
    .soc-ic {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 33px;
        font-size: 14px;
        line-height: 35px;
        text-align: center;
    }
}

.social-tp-2 {
    color: #1f2124;
    ul {
        overflow: hidden;
        li {
            float: left;
            margin-right: 18px;
        }
        a {
            color: inherit;
            display: inline-block;
            font-size: 12px;
            padding: 0 5px;
        }
        i {
            margin: 0;
        }
        .tw a:hover {
            color: #00abed;
        }
        .fb a:hover {
            color: #4c68b3;
        }
        .gp a:hover {
            color: #e51616;
        }
        .in a:hover {
            color: #1d5e9f;
        }
        .dr a:hover {
            color: #f51f9d;
        }
    }
}

.post-sharing-tp-1 {
    @extend .clearfix;
    margin: 20px 0 33px;
    ul {
        float: left;
    }
    li {
        float: left;
        margin: 0 5px 5px 0;
        &:last-child {
            margin: 0;
        }
    }
    .fb {
        a {
            background-color: #526eac;
            &:hover {
                background-color: #4360a0;
            }
        }
    }
    .tw {
        a {
            background-color: #00abed;
            &:hover {
                background-color: #0d9fd8;
            }
        }
    }
    .vk {
        a {
            background-color: #426b9a;
            &:hover {
                background-color: #355e8d;
            }
        }
    }
    .gp {
        a {
            background-color: #eb4026;
            &:hover {
                background-color: #e23319;
            }
        }
    }
    a {
        border-radius: 2px;
        color: #fff;
        display: block;
        font-size: 11px;
        font-weight: 500;
        line-height: 20px;
        min-width: 125px;
        padding: 6px 15px;
        text-align: center;
        text-decoration: none;
        @include media($mb-pt) {
            min-width: 56px;
        }
        .fa {
            margin-right: 9px;
            @include media($mb-pt) {
                margin: 0;
            }
        }
    }
}

.sharing-block {
    background-color: #f8f8f8;
    border-radius: 4px;
    padding: 20px 21px 25px;
    .sharing-title {
        color: #000;
        font-weight: 600;
        margin-bottom: 12px;
    }
    .comments {
        color: #1f2124;
        float: right;
        font-size: 11px;
        font-weight: 500;
        padding: 5px 0;
        text-transform: uppercase;
        @include media($mb-pt) {
            margin-top: 10px;
        }
        i {
            color: #8a8a8a;
            line-height: 10px;
            display: inline-block;
            margin-right: 6px;
            vertical-align: middle;
        }
    }
}

.post-sharing-tp-2 {
    @extend .post-sharing-tp-1;
    margin: 0;
    a {
        min-width: 83px;
        @include media($mb-pt) {
            min-width: 45px;
        }
    }
}

.sb-banner {
    background: #fff;
    padding: 20px;
    margin-bottom: 30px;
    .banner-inner {
        position: relative;
        &:before {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 6px solid $accent-color;
            border-right: 6px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .banner-overlay {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        padding: 22px 20px;
    }
}

.sbsb-block-1 {
    background: #f7f7f7;
    padding: 36px 20px 40px;
    margin-bottom: 30px;
}

.sbsb-form-1 {
    background: #fff;
    border-radius: 2px;
    .sbsb-form {
        display: table;
        width: 100%;
        padding: 4px;
        > div {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .sbsb-input {
        position: relative;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 30px;
            width: 100%;
            padding: 5px 10px 5px 37px;
        }
    }
    .sbsb-icon {
        color: #8d8d8d;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 17px;
        line-height: 30px;
        padding: 0 0 0 11px;
        i:before {
            vertical-align: middle;
        }
    }
    .sbsb-btn {
        width: 88px;
        button {
            appearance: none;
            color: #fff;
            background-color: $accent-color;
            border-radius: 2px;
            cursor: pointer;
            font: 500 11px/20px $main-font;
            border: none;
            display: block;
            padding: 6px 10px 4px;
            min-width: 88px;
            text-align: center;
            text-transform: uppercase;
            &:hover,
            &:active,
            &:focus {
                background-color: lighten($accent-color, 6%);
            }
        }
    }
}

.search-form-1 {
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    .src-form {
        display: table;
        width: 100%;
        padding: 4px;
        > div {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .src-input {
        position: relative;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 30px;
            width: 100%;
            padding: 5px 10px 5px 37px;
        }
    }
    .src-icon {
        color: #8d8d8d;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 17px;
        line-height: 30px;
        padding: 0 0 0 11px;
        i:before {
            vertical-align: middle;
        }
    }
    .src-btn {
        width: 88px;
        button {
            appearance: none;
            color: #fff;
            background-color: $accent-color;
            border-radius: 2px;
            cursor: pointer;
            font: 500 11px/20px $main-font;
            border: none;
            display: block;
            padding: 6px 10px 4px;
            min-width: 88px;
            text-align: center;
            text-transform: uppercase;
            &:hover,
            &:active,
            &:focus {
                background-color: #f15232;
            }
        }
    }
}

.search-form-2 {
    background: #fff;
    border-radius: 2px;
    overflow: hidden;
    .src-form {
        display: table;
        width: 100%;
        // padding: 4px;
        > div {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .src-input {
        position: relative;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 38px;
            width: 100%;
            padding: 5px 15px 5px 15px;
        }
    }
    .src-btn {
        width: 38px;
        button {
            appearance: none;
            color: #fff;
            background-color: $accent-color;
            border-radius: 2px;
            cursor: pointer;
            font: 500 13px/28px $main-font;
            border: none;
            display: block;
            padding: 6px 10px 4px;
            width: 38px;
            text-align: center;
            text-transform: uppercase;
            &:hover,
            &:active,
            &:focus {
                background-color: #f15232;
            }
        }
    }
}

.search-form-3 {
    padding: 8px 0;
    .src-input {
        position: relative;
        input {
            border: 1px solid #ededed;
            border-radius: 2px;
            color: #626465;
            font: 12px/20px $content-font;
            height: 38px;
            width: 100%;
            padding: 5px 15px 5px 15px;
        }
    }
    .src-icon {
        color: #a9a9a9;
        font-size: 13px;
        height: 38px;
        line-height: 38px;
        padding: 0 12px 0 0;
        position: absolute;
        right: 0;
        top: 0;
        i {
            display: block;
            transform: scaleX(-1);
        }
    }
}

.sbsb-form-2 {
    .sbsb-form {}
    .sbsb-input {
        background: #fff;
        border-radius: 2px;
        position: relative;
        margin: 0 0 6px;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 38px;
            width: 100%;
            padding: 9px 10px 9px 37px;
        }
    }
    .sbsb-icon {
        color: #8d8d8d;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 17px;
        line-height: 38px;
        padding: 0 0 0 11px;
        i:before {
            vertical-align: middle;
        }
    }
    .sbsb-btn {
        width: 100%;
        button {
            appearance: none;
            color: #fff;
            background-color: $accent-color;
            border-radius: 2px;
            cursor: pointer;
            font: 500 11px/28px $main-font;
            border: none;
            display: block;
            padding: 6px 10px 4px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            &:hover,
            &:active,
            &:focus {
                background-color: #f15232;
            }
        }
    }
}

.sbr-slider {
    .pst-block-main,
    .post-tp-10 {
        padding: 0;
    }
}

.sidebar-slider {
    position: relative;
    figure {
        position: relative;
    }
    .category-tp-1 {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 18px 20px;
    }
    .sbr-navs {
        background: #181818;
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .slick-arrow {
        cursor: pointer;
        width: 39px;
        height: 39px;
        padding: 17px 15px;
        float: left;
        position: relative;
        i {
            vertical-align: top;
        }
        &.prev {
            margin-right: 1px;
            &:after {
                background: #3e3e3e;
                content: '';
                position: absolute;
                right: -1px;
                top: 12px;
                bottom: 12px;
                width: 1px;
            }
        }
    }
}

.sbr-prev {
    @extend .ms-prev;
}

.sbr-next {
    @extend .ms-next;
}

.tags-block {
    padding: 8px 0 5px;
}

.tags-list {
    @extend .clearfix;
    font-size: 0;
    li {
        float: left;
        margin: 0 5px 5px 0;
    }
    a {
        background: #ededed;
        color: #1f1f1f;
        display: block;
        font: 700 10px/15px $content-font;
        padding: 5px 8px;
        min-width: 52px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.post-tags {
    padding: 10px 0;
    margin-bottom: 25px;
}

.post-tags-list {
    @extend .clearfix;
    font-size: 0;
    li {
        float: left;
        margin: 0 5px 5px 0;
    }
    a {
        background: #fff;
        border-radius: 2px;
        border: 1px solid #eaeaea;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, .03);
        color: #1f1f1f;
        display: block;
        font: 500 10px/15px $main-font;
        padding: 5px 8px;
        min-width: 60px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
    }
}

.ltst-reviews,
.ftrd-author {
    .pst-block-main {
        padding: 0;
    }
    .full-img {
        margin-bottom: 18px;
        position: relative;
    }
    .post-wrap {
        padding: 0 20px 5px;
    }
    .post-tp-11 {
        border-bottom: 1px dashed #ebebeb;
        padding-bottom: 14px;
        margin-bottom: 9px;
    }
    .post-tp-6 {
        border-bottom: 1px dashed #ebebeb;
        &:last-child {
            border: none;
        }
    }
    .category-tp-1,
    .category-tp-3 {
        position: absolute;
        left: 0;
        bottom: 0;
        margin: 18px 20px;
    }
}

.poling-block {
    padding: 10px 0;
}

.poling-list {
    margin-bottom: 14px;
    li {
        padding: 3px 0;
    }
}

.info-tp-1 {
    a {
        border-left: 1px solid #f6f6f6;
        color: #c5c5c5;
        display: inline-block;
        font-family: $accent-font;
        font-weight: 700;
        line-height: 25px;
        padding-left: 18px;
        margin-left: 16px;
        vertical-align: top;
        text-transform: uppercase;
        text-decoration: none;
        @include media($md) {
            padding-left: 10px;
            margin-left: 8px;
        }
        &:first-child {
            border: none;
            margin: 0;
            padding-left: 0;
        }
        &:hover {
            color: $accent-color;
        }
    }
}

.twitter-widget {
    .info-tp-1 {
        float: right;
    }
    .post-list {
        li {
            border-bottom: 1px dashed #ebebeb;
            padding: 14px 0 9px 26px;
            position: relative;
            &:last-child {
                border: none;
            }
        }
        p {
            color: #6f6f6f;
            font: 14px/23px 'Georgia';
        }
        a {
            color: $accent-color;
            text-decoration: none;
        }
        .date {
            color: #c7c7c7;
            font-family: $accent-font;
            font-size: 12px;
            font-weight: 700;
            margin: 3px 0;
            text-transform: uppercase;
        }
        .fa {
            color: #e7e7e7;
            font-size: 16px;
            position: absolute;
            left: 0;
            top: 17px;
        }
    }
}

.map-widget {
    .map {
        height: 298px;
        margin: -15px -20px;
    }
}

.weather-widget {
    .pst-block-head {
        .arr-ic-3 {
            float: right;
        }
    }
    .pst-block-main {
        padding: 15px 20px 0;
        margin-bottom: 0;
    }
    .title-4 {
        i {
            color: $accent-color;
            font-size: 14px;
            line-height: 14px;
        }
    }
}

.weather-block {
    color: #676666;
    font-size: 12px;
    min-height: 60px;
    padding: 5px 0 5px 112px;
    position: relative;
    .temperature {
        position: absolute;
        left: 0;
    }
    i {
        display: inline-block;
        color: $accent-color;
        font-size: 26px;
        margin-top: 5px;
        vertical-align: top;
    }
}

.day {
    color: #8e8e8e;
    font-size: 9px;
    line-height: 8px;
    text-align: right;
    text-transform: uppercase;
}

.degrees-1 {
    color: #000;
    display: inline-block;
    font-size: 34px;
    font-weight: 500;
    line-height: 40px;
}

.degrees-2 {
    color: #000;
    display: inline-block;
    font-size: 23px;
    font-weight: 500;
    line-height: 26px;
    i {
        display: inline-block;
        vertical-align: top;
        margin: 2px 4px;
    }
}

.degrees-ic-1 {
    display: inline-block;
    border: 2px solid #000;
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.degrees-ic-2 {
    display: inline-block;
    border: 1px solid #000;
    border-radius: 50%;
    width: 5px;
    height: 5px;
}

.weather-days {
    margin: -12px 0 0;
}

.weather-days-list {
    @extend .clearfix;
    li {
        float: left;
        width: 20%;
        text-align: center;
        &:hover,
        &.active {
            a {
                background: rgba(235, 235, 235, .11);
            }
        }
        a {
            display: block;
            padding: 22px 0 25px;
            text-decoration: none;
        }
        .degrees-2 {
            margin-right: -13px;
        }
        .day {
            text-align: center;
        }
    }
}

.page-nav {
    @extend .clearfix;
    color: #c3c3c3;
    font-size: 12px;
    font-weight: 500;
    margin: 14px 0 15px;
    .pn-item {
        color: #646464;
        border: 1px solid #ececec;
        border-radius: 50%;
        display: block;
        font-size: 12px;
        float: left;
        width: 33px;
        height: 33px;
        padding: 4px;
        margin: 0 5px 10px 0;
        text-align: center;
        text-decoration: none;
        &.current {
            background: $accent-color;
            border-color: $accent-color;
            color: #fff;
        }
    }
    .extend {
        display: block;
        font-family: $accent-font-2;
        font-size: 12px;
        float: left;
        width: 33px;
        height: 33px;
        padding: 4px;
        margin: 0 5px 0 0;
        text-align: center;
        text-decoration: none;
    }
    .page-count {
        display: block;
        float: right;
        line-height: 33px;
    }
}

.page-nav-prev-ic {
    @include sprite($page-nav-prev-ic);
    display: inline-block;
}

.page-nav-next-ic {
    @include sprite($page-nav-next-ic);
    display: inline-block;
}

.go-top {
    border: 2px solid #b1b5bc;
    border-radius: 50%;
    color: #8e9399;
    font-size: 16px;
    line-height: 35px;
    display: block;
    width: 39px;
    height: 39px;
    position: fixed;
    top: 50%;
    right: 37px;
    margin-top: -20px;
    text-align: center;
    @include media($xs) {
        display: none;
    }
}

.banner {
    background: #fff;
    padding: 11px;
    margin: 45px 0;
}

.lifestyle-pst {
    .post-tp-17 {
        &:last-child {
            border: none;
            padding-bottom: 15px;
        }
    }
}

.latest-pst {
    .post-tp-15 {
        margin: 8px 0 18px;
    }
    .post-tp-17 {
        // &:first-child {
        //     margin: 0;
        // }
        &:last-child {
            border: none;
            padding: 0 0 15px;
        }
    }
    .meta-tp-2 {
        margin: 4px 0 3px;
    }
}

.latest-nws {
    .posts {
        margin-bottom: 25px;
    }
}

.trend-pst-tp-2 {
    .pst-block-main {
        padding: 0;
        > div > div {
            &:first-child {
                figure:after {
                    display: none;
                }
            }
        }
    }
}

.twitter-block {
    background: #121314;
    color: #fff;
    padding: 20px 20px 74px;
    position: relative;
    a {
        color: inherit;
        text-decoration: none;
    }
    p {
        color: #fff;
        max-height: 92px; // design bag
        overflow: hidden; // design bag
    }
    .tb-icon {
        font-size: 20px;
        padding: 5px 0;
    }
    .tb-user {
        overflow: hidden;
        margin: 14px 0 18px;
        .photo {
            border-radius: 50%;
            float: left;
            overflow: hidden;
            width: 55px;
            height: 55px;
            margin: 0 12px 0 0;
        }
        .tb-name {
            font-weight: 500;
            margin: 8px 0 0;
        }
        .tb-login {
            color: #727272;
            font-size: 10px;
            line-height: 16px;
        }
    }
    .time-date {
        display: block;
        color: #727272;
        font-size: 10px;
        margin: 7px 0;
    }
    .tb-twitts {
        .twitt {
            padding: 8px 0;
        }
        li {
            border-bottom: 1px solid #26282c;
            margin: 0 0 8px;
        }
    }
    .tb-bottom {
        height: 74px;
        line-height: 24px;
        padding: 25px 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
    }
    .td-action {
        float: left;
        a {
            margin-right: 40px;
            &:last-child {
                margin: 0;
            }
        }
    }
    .tb-nav {
        float: right;
        font-size: 7px;
    }
}

// Animate
.animated {
    visibility: hidden;
}

.fadeOutUp,
.fadeInUp,
.fadeIn {
    visibility: visible;
}

.page-head-tile {
    margin: 29px 0 26px;
}

.breadcrumbs-block {
    padding: 8px 0;
}

.page-title {
    @extend .clearfix;
    padding: 8px 0;
    position: relative;
    .filters-more {
        border-color: #dddddd;
    }
    .filters-drop {
        .arr {
            right: 4px;
        }
    }
}

.breadcrumbs {
    @extend .clearfix;
    color: #6f6f6f;
    font-family: $content-font;
    .bc-item {
        float: left;
    }
    .fa {
        font-size: 10px;
        line-height: 23px;
        vertical-align: top;
        margin: 0 7px 0 5px;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
}

.video-content {
    .page-title {
        .filters-more {
            border-color: #383a3e;
        }
    }
    .filters-list-1 {
        a {
            &:hover,
            &.active {
                color: #fffefe;
            }
        }
    }
    .title-16 {
        color: #fff;
    }
}

.thumbs-slider {
    margin: -15px -20px 0;
}

.thumbs-img {
    cursor: pointer;
    &:hover {
        figure {
            &:before {
                background-color: rgba(0, 0, 0, .5);
            }
        }
    }
    figure {
        position: relative;
        &:before {
            content: '';
            background-color: rgba(0, 0, 0, .2);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }
        img {
            width: 100%;
        }
    }
}

.post-tile {
    background-color: #f8f8f8;
    margin: 0 -20px 32px;
    padding: 40px 20px 10px;
    .col-eq {
        > div {
            @include media($sm) {
                .title-3 {
                    height: 42px;
                    overflow: hidden;
                }
            }
        }
    }
}

.tpg-block,
.scd-block {
    padding: 14px 0 0;
    .bq-tp-3 {
        margin-right: -20px;
        margin-left: -20px;
    }
}

.scd-block {
    &:first-child {
        padding-top: 0;
    }
}

.post-content {
    padding: 10px 4px;
    .title-blc-1,
    .title-blc-2 {
        padding: 0 24px;
        margin-right: -24px;
        margin-left: -24px;
    }
    .bq-tp-3 {
        margin-right: -24px;
        margin-left: -24px;
    }
    figure {
        img {
            width: 100%;
        }
    }
    @include media($mb-ls) {
        .align-left {
            float: none;
            margin: 6px 0 15px;
        }
    }
}

.post-main-img {
    margin: -25px -24px 35px;
    position: relative;
    figure {
        position: relative;
        &:before {
            @include background(linear-gradient(transparent, rgba(0, 0, 0, .36)) top repeat);
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 50%;
        }
    }
    .post-main-overlay {
        background-color: rgba(0, 0, 0, .2);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .post-main-data {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 30px 24px;
    }
    .title-21 {
        color: #fff;
    }
    .meta-tp-5,
    .author-tp-2,
    .date-tp-4 {
        color: #fff;
    }
}

.post-main-tile {
    background-color: #f8f8f8;
    margin: -25px -24px 35px;
    padding: 45px 24px 15px;
    p {
        color: #aaa;
    }
    .meta-tp-5 {
        margin-bottom: 12px;
    }
}

.post-video {
    margin: -25px -24px 35px;
}

.small-gallery {
    @extend .clearfix;
    margin: 0 -3px;
    padding: 10px 0 33px;
    .item {
        @include span-columns(4);
        padding: 0 3px;
        @include media($mb-ls) {
            @include span-columns(12);
            padding: 3px;
        }
    }
    figure {
        position: relative;
        &:before {
            content: '';
            background-color: rgba(22, 23, 26, .15);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            opacity: 0;
        }
        img {
            width: 100%;
        }
    }
}

.half-gallery {
    @extend .clearfix;
    margin: -3px -3px -2px -2px;
    padding: 10px 0 33px;
    .item {
        padding: 3px 3px 2px 2px;
    }
}

.medium-gallery {
    @extend .clearfix;
    margin: -3px -3px -2px -2px;
    padding: 10px 0 33px;
    .item {
        padding: 3px 3px 2px 2px;
    }
    .left {
        float: left;
        width: 55.6%;
        @include media($mb-ls) {
            width: 100%;
        }
    }
    .right {
        float: right;
        width: 44.4%;
        @include media($mb-ls) {
            width: 100%;
        }
    }
}

.large-gallery {
    @extend .clearfix;
    margin: -3px -27px -2px -26px;
    padding: 10px 0 33px;
    .item {
        padding: 3px 3px 2px 2px;
    }
    .left {
        float: left;
        width: 64.104%;
        @include media($mb-ls) {
            width: 100%;
        }
    }
    .right {
        float: right;
        width: 35.896%;
        @include media($mb-ls) {
            width: 100%;
        }
    }
}

.post-img-1 {
    margin-bottom: 17px;
    figcaption {
        color: #626465;
        font: italic 400 12px/24px $content-font;
        padding: 8px 0;
    }
}

.post-img-2 {
    margin-bottom: 23px;
    figcaption {
        color: #626465;
        font: italic 400 12px/24px $content-font;
        padding: 8px 0;
    }
}

.post-img-3 {
    margin: 0 -24px 23px;
    figcaption {
        color: #626465;
        font: italic 400 12px/24px $content-font;
        padding: 8px 0;
    }
}

.post-next-prev {
    @extend .clearfix;
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    color: #a9a9a9;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 40px 0;
    a {
        color: inherit;
        display: block;
        padding: 25px;
        position: relative;
        text-decoration: none;
        &:hover {
            color: #000;
            .fa {
                color: $accent-color;
            }
        }
        .fa {
            color: #bbb;
            font-size: 17px;
            position: absolute;
            top: 50%;
            margin-top: -8px;
        }
    }
    .post-prev,
    .post-next {
        width: 50%;
        float: left;
    }
    .post-prev {
        text-align: right;
        a {
            border-right: 1px solid #f1f1f1;
            margin-right: -1px;
        }
        .fa {
            left: 0;
        }
    }
    .post-next {
        a {
            border-left: 1px solid #f1f1f1;
        }
        .fa {
            right: 0;
        }
    }
}

.author-box-1 {
    background-color: #f8f8f8;
    padding: 28px 20px 27px 132px;
    position: relative;
    margin-bottom: 27px;
    @include media($mb-pt) {
        padding: 28px 20px 27px 20px;
    }
    .author-photo {
        background-color: #fff;
        border-radius: 50%;
        width: 95px;
        height: 95px;
        overflow: hidden;
        position: absolute;
        left: 20px;
        top: 22px;
        @include media($mb-pt) {
            display: none;
        }
    }
    .author-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 1px;
    }
    .author-profession {
        color: #8e8e8e;
        font: italic 12px/22px $accent-font-3;
        margin-bottom: 6px;
    }
    .author-description {
        color: #6f6f6f;
        font: 13px/21px $accent-font-3;
        margin-bottom: 8px;
    }
    .author-social {
        color: #000;
        ul {
            overflow: hidden;
            li {
                float: left;
                margin-right: 8px;
            }
            a {
                color: inherit;
                display: inline-block;
                padding: 0 5px;
            }
            i {
                margin: 0;
            }
            .tw a:hover {
                color: #00abed;
            }
            .fb a:hover {
                color: #4c68b3;
            }
            .gp a:hover {
                color: #e51616;
            }
            .in a:hover {
                color: #1d5e9f;
            }
            .dr a:hover {
                color: #f51f9d;
            }
        }
    }
}

.author-box-2 {
    background-color: #f8f8f8;
    padding: 36px 20px 25px;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    .author-photo {
        background-color: #fff;
        border-radius: 50%;
        width: 134px;
        height: 134px;
        margin: 0 auto 16px;
        overflow: hidden;
    }
    .author-name {
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
    }
    .author-profession {
        color: #8e8e8e;
        font: italic 12px/22px $accent-font-3;
        margin-bottom: 6px;
    }
    .author-description {
        color: #6f6f6f;
        font: 13px/21px $accent-font-3;
        margin-bottom: 12px;
    }
    .author-social {
        color: #000;
        display: inline-block;
        ul {
            overflow: hidden;
            li {
                float: left;
                margin-right: 8px;
            }
            a {
                color: inherit;
                display: inline-block;
                padding: 0 5px;
            }
            i {
                margin: 0;
            }
            .tw a:hover {
                color: #00abed;
            }
            .fb a:hover {
                color: #4c68b3;
            }
            .gp a:hover {
                color: #e51616;
            }
            .in a:hover {
                color: #1d5e9f;
            }
            .dr a:hover {
                color: #f51f9d;
            }
        }
    }
}

.author-box-3 {
    @extend .author-box-2;
    .author-description {
        padding: 0 10px;
    }
}

.comments-block {
    margin-bottom: 49px;
}

.comment-list {
    .comment {
        padding: 0 0 0 61px;
        position: relative;
        .comment-photo {
            background-color: #e8e6df;
            border-radius: 50%;
            width: 49px;
            height: 49px;
            position: absolute;
            left: 0;
            top: 4px;
            overflow: hidden;
        }
        .title-20 {
            margin-bottom: 4px;
        }
        article {
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 13px;
            margin-bottom: 18px;
            &:hover {
                .reply {
                    opacity: 1;
                }
            }
        }
        p {
            color: #6f6f6f;
            font: 400 15px/25px $accent-font-3;
            margin-bottom: 5px;
        }
        .comment-date {
            color: #afafaf;
            display: inline-block;
            font-size: 11px;
            line-height: 23px;
            margin-right: 10px;
        }
        .reply {
            cursor: pointer;
            color: $accent-color;
            display: inline-block;
            font-size: 11px;
            font-weight: 600;
            line-height: 23px;
            margin-right: 10px;
            opacity: 0;
            @include media($mb-pt) {
                opacity: 1;
            }
        }
    }
}

.comments-form {
    padding: 12px 0 7px;
    margin-top: -20px;
    .btn-3 {
        margin: 8px 0;
    }
    .half,
    .full {
        padding: 0 15px;
        .form-control {
            input {
                width: 100%;
            }
        }
    }
}

.form-control {
    label {
        color: #000;
        font: 500 11px/21px $main-font;
        text-transform: uppercase;
    }
    input {
        appearance: none;
        background-color: #f5f5f5;
        border: none;
        border-radius: 2px;
        font: 500 13px/25px $main-font;
        display: block;
        width: 480px;
        max-width: 100%;
        padding: 10px;
        margin: 7px 0 12px;
    }
    textarea {
        appearance: none;
        background-color: #f5f5f5;
        border: none;
        border-radius: 2px;
        resize: none;
        font: 500 13px/25px $main-font;
        display: block;
        width: 100%;
        height: 164px;
        padding: 10px;
        margin: 7px 0 12px;
    }
    .rate-tp-2 {
        margin: 4px 0;
    }
}

.img-slider {
    margin-bottom: 34px;
    &.tp-2 {
        .slider {
            margin-left: -24px;
            margin-right: -24px;
        }
    }
    .navs {
        color: #6f6f6f;
        font: 400 11px/24px $accent-font-3;
        > div {
            display: inline-block;
        }
        .fa {
            color: #626465;
            font-size: 15px;
            line-height: 26px;
        }
        .slide-count {
            margin: 0 4px;
        }
        .prev,
        .next {
            border: 2px solid #dedede;
            border-radius: 50%;
            cursor: pointer;
            width: 30px;
            height: 30px;
            text-align: center;
        }
    }
}

.page-content {
    padding: 20px;
    .posts {
        padding: 8px 0;
    }
}

.team-person-blocks {
    .half,
    .one-third {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.magazine-details {
    padding: 1px 0 5px;
}

.details-info {
    color: #626465;
    font: 400 14px/25px $content-font;
    > div {
        margin-bottom: 10px;
    }
    .address,
    .phone,
    .email {
        i {
            font-size: 15px;
            line-height: 24px;
            vertical-align: middle;
            margin-right: 6px;
        }
    }
    .email {
        a {
            color: $accent-color;
            text-decoration: none;
        }
    }
    .social-tp-2 {
        padding: 8px 0 10px;
    }
}

.contactf-form-block {
    padding: 1px 0 0;
}

.search-pane {
    background-color: #f8f8f8;
    margin: -15px -20px 30px;
    padding: 30px 20px;
}

.page-404 {
    padding: 170px 0 57px;
    .search-form-2 {
        max-width: 486px;
        margin-top: 43px;
        margin-bottom: 50px;
    }
    h2 {
        padding: 13px 0 5px;
    }
}

.large-404 {
    color: #dedede;
    font: 600 200px/188px $main-font;
    @include media($xs) {
        font-size: 150px;
        line-height: 148px;
    }
}

.tags {
    padding-top: 17px;
}

.review-rating-tp-1,
.review-rating-tp-3 {
    margin: -2px -3px -3px -2px;
    padding: 10px 0 30px;
    .percent {
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
    }
}

.rw-item {
    padding: 2px 3px 3px 2px;
    &.lg {
        float: left;
        width: 80%;
        @include media($mb-ls) {
            width: 100%;
        }
    }
    &.sm {
        float: left;
        width: 20%;
        @include media($mb-ls) {
            width: 100%;
        }
        .rw-rating {
            padding: 24px 12px 18px;
            @include media($xs) {
                padding: 24px 8px 18px;
            }
        }
    }
}

.rw-rating {
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 24px 24px 18px;
}

.rating-line-tp-1 {
    margin-bottom: 18px;
}

.rating-line-tp-2 {
    border-bottom: 1px solid #ebebeb;
    padding: 0 0 10px;
    margin-bottom: 13px;
    &:last-child {
        border: none;
        padding: 0;
        margin-bottom: 2px;
    }
    .rate-tp-2 {
        float: right;
        @include media($mb-pt) {
            &.mb-left {
                float: left;
            }
        }
    }
}

.rating-title {
    @extend .clearfix;
    .title-23 {
        float: left;
    }
    .percent {
        float: right;
    }
}

.rating-meter {
    background-color: #e3e3e3;
    border-radius: 2px;
    height: 4px;
    position: relative;
    margin-top: 6px;
    > span {
        background-color: $accent-color;
        border-radius: 2px;
        display: block;
        height: 100%;
    }
}

.rw-summary {
    @extend .clearfix;
    .title-6 {
        line-height: 24px;
        margin-bottom: 2px;
    }
    p {
        color: #626465;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 12px;
    }
}

.result-tp-1 {
    color: #000;
    font-weight: 600;
    line-height: 14px;
    padding: 23px 0;
    text-align: center;
    span {
        display: block;
    }
    .num {
        display: block;
        font-size: 19px;
        strong {
            display: inline-block;
            font-size: 40px;
            line-height: 38px;
        }
    }
}

.result-tp-2 {
    @extend .result-tp-1;
    padding: 5px 0 4px;
    .rate-tp-2 {
        float: none;
        display: inline-block;
        margin-top: 8px;
        li {
            &.active {
                color: #000;
            }
        }
    }
}

.review-rating-tp-2 {
    margin: -2px -3px -3px -2px;
    padding: 10px 0 30px;
}

.drop-box-1 {
    color: #616161;
    cursor: pointer;
    font-weight: 400;
    display: inline-block;
    margin-left: 4px;
    .fa {
        margin-left: 4px;
    }
}

.members {
    padding: 15px 0 31px;
}

.members-items {
    @extend .clearfix;
    margin: -12px;
    .one-third {
        padding: 12px;
    }
}

.members-item {
    background-color: #fafafa;
    display: block;
    padding: 42px 10px 36px;
    position: relative;
    text-align: center;
    text-decoration: none;
    &:before {
        content: '';
        width: 0;
        height: 0;
        border-bottom: 6px solid $accent-color;
        border-right: 6px solid transparent;
        position: absolute;
        left: 0;
        bottom: 0;
        opacity: 0;
    }
    &:hover {
        &:before {
            opacity: 1;
        }
        .members-photo {
            &:before {
                background-color: rgba(0, 0, 0, .11);
            }
            &:after {
                opacity: 1;
            }
        }
    }
    .members-photo {
        background-color: #fff;
        border-radius: 50%;
        width: 134px;
        height: 134px;
        margin: 0 auto 16px;
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
        &:after {
            display: inline-block;
            font: normal normal normal 6px/134px FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\f111 \f111 \f111';
            color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            letter-spacing: 2px;
            z-index: 2;
        }
    }
    .members-name {
        color: #000;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
    }
}

.mmbs-pagination {
    color: #656565;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 20px;
}
.profile,
.groups,
.friends,
.forums {
    .title-blc-2 {
        margin-top: 7px;
    }
}

.mess {
    border: 1px solid #656565;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    padding: 12px 17px;
    &.info {
        color: #656565;
        border-color: #f6d0c9;
    }
}

.profile-fields {
    .name {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
    }
}

.search-members {
    background-color: #f8f8f8;
    padding: 30px 24px;
}

.search-forums {
    background-color: #f8f8f8;
    padding: 30px 24px;
}

.mmb-form {
    background: #fff;
    border-radius: 2px;
    .mmb-form {
        width: 100%;
        padding: 4px;
    }
    .mmb-input {
        position: relative;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 30px;
            width: 100%;
            padding: 5px 10px 5px 37px;
        }
    }
    .mmb-icon {
        color: #8d8d8d;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 17px;
        line-height: 30px;
        padding: 0 0 0 11px;
        i:before {
            vertical-align: middle;
        }
    }
}

.srcf-form {
    background: #fff;
    border-radius: 2px;
    .srcf-form {
        width: 100%;
        padding: 4px;
    }
    .srcf-input {
        position: relative;
        input {
            border: none;
            color: #626465;
            font: 12px/20px $content-font;
            height: 30px;
            width: 100%;
            padding: 5px 37px 5px 12px;
        }
    }
    .srcf-icon {
        color: #8d8d8d;
        display: block;
        position: absolute;
        top: 0;
        right: 12px;
        font-size: 13px;
        line-height: 30px;
        i {
            display: block;
            transform: scaleX(-1);
            &:before {
                vertical-align: middle;
            }
        }
    }
}

.members-list {
    .mls-item {
        border-bottom: 1px solid #f7f7f7;
        position: relative;
        padding: 23px 0 24px 62px;
        min-height: 80px;
        &:last-child {
            border: none;
        }
    }
    .mls-photo {
        background-color: #f8f8f8;
        border-radius: 50%;
        width: 49px;
        height: 49px;
        position: absolute;
        left: 0;
        top: 15px;
        overflow: hidden;
        img {
            display: block;
        }
    }
    .mls-name {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }
    .mls-date {
        color: #939393;
        font-size: 11px;
        line-height: 13px;
    }
}

.member-main {
    background-color: #fff;
    overflow: hidden;
}

.mmb-blc {
    background-color: #f8f8f8;
    margin-bottom: 3px;
    padding: 13px 24px;
    font-size: 13px;
    font-weight: 500;
    line-height: 26px;
}

.member-info {
    padding: 40px 24px 33px;
    text-align: center;
}

.mmbi-photo {
    background-color: #fff;
    border-radius: 50%;
    width: 163px;
    height: 163px;
    margin: 0 auto 28px;
    overflow: hidden;
}

.mmbi-name {
    color: #000;
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 4px;
}

.mmbi-nick {
    color: #ababab;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 4px;
}

.mmbi-active {
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 17px;
}

.mmbi-description {
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    a {
        color: #000;
        text-decoration: none;
    }
}

.member-filter {
    @extend .clearfix;
    .mf-item {
        margin-right: 25px;
        float: left;
        &.active {
            a {
                color: #000;
            }
        }
        a {
            display: block;
            color: #828282;
            font-size: 13px;
            font-weight: 500;
            line-height: 26px;
            text-transform: uppercase;
            text-decoration: none;
            @include media($mb-pt) {
                padding: 5px 0;
            }
            span {
                background-color: #fff;
                color: #000;
                display: inline-block;
                font-size: 9px;
                line-height: 21px;
                margin: 0 0 0 6px;
                padding: 0 4px;
                min-width: 23px;
                vertical-align: middle;
                text-align: center;
            }
        }
    }
}

.member-stream {}

.mmbs-item {
    background-color: #f8f8f8;
    min-height: 124px;
    padding: 30px 10px 28px 123px;
    position: relative;
    margin-bottom: 20px;
    @include media($mb-pt) {
        padding: 30px 10px 28px 83px;
    }
    .mmbs-item-photo {
        background-color: #fff;
        border-radius: 50%;
        width: 82px;
        height: 82px;
        position: absolute;
        left: 21px;
        top: 21px;
        overflow: hidden;
        @include media($mb-pt) {
            width: 62px;
            height: 62px;
            left: 11px;
        }
    }
    .mmbs-item-title {
        color: #8f8f8f;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 7px;
        b {
            color: #000;
        }
        .mmbs-icon-photo {
            background-color: #fff;
            border-radius: 50%;
            display: inline-block;
            width: 23px;
            height: 23px;
            margin: -2px 5px 0;
            vertical-align: middle;
            overflow: hidden;
        }
    }
    .mmbs-item-date {
        color: #ababab;
        font-size: 12px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 8px;
        text-decoration: none;
        i {
            font-size: 13px;
        }
    }
    .mmbs-item-date-active {
        color: #898989;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        margin: 8px 0;
        text-decoration: none;
        i {
            font-size: 13px;
        }
    }
    .mmbs-item-description {
        color: #626465;
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .mmbs-item-text {
        color: #898989;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        a {
            color: #000;
            text-decoration: none;
        }
    }
    .btn-4 {
        min-width: 142px;
        margin: 15px 0 6px;
    }
}

.member-sub-stream {
    margin: 23px 0 0;
    @include media($mb-pt) {
        margin: 23px 0 0 -40px;
    }
    .mmbs-item {
        min-height: 0;
        padding: 0 0 21px 62px;
        margin: 0;
        &:last-child {
            padding-bottom: 0;
        }
        .mmbs-item-photo {
            left: 0;
            top: -2px;
            width: 49px;
            height: 49px;
        }
    }
}

.fs-list-block {
    .fs-item {
        color: #414141;
        border-bottom: 1px solid #f7f7f7;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        padding: 10px 0 10px 12px;
        position: relative;
        &:last-child {
            border: none;
        }
        &:after {
            display: inline-block;
            font: normal normal normal 12px/38px FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\f105';
            color: #000;
            position: absolute;
            left: 0;
            top: 0;
        }
        .count {
            float: right;
            font-weight: 400;
        }
        a {
            color: inherit;
            display: block;
            text-decoration: none;
        }
    }
}

.forum-block {
    .breadcrumbs-block {
        padding: 0 0 23px;
    }
}

.forums-list-block {}

.forum-list-block {
    border-bottom: 7px solid #f8f8f8;
    margin-bottom: 30px;
}

.forums-comment-block {
    margin-bottom: 30px;
    .forum-titles,
    .bbp-footer {
        .bbp-reply-author {
            width: 15%;
            @include media($mb-pt) {
                width: 83px;
            }
        }
        .bbp-reply-content {
            width: 85%;
            @include media($mb-pt) {
                width: auto;
            }
        }
    }
}

.forums-list {
    .bbp-forum-topic {
        width: 51%;
        @include media($mb-pt) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .bbp-forum-voices-count {
        width: 12%;
        font-size: 12px;
        text-align: center;
        @include media($mb-pt) {
            width: 28%;
            text-align: left;
        }
    }
    .bbp-forum-posts-count {
        width: 12%;
        font-size: 12px;
        text-align: center;
        @include media($mb-pt) {
            width: 26%;
            text-align: left;
        }
    }
    .bbp-forum-freshness {
        width: 25%;
        text-align: center;
        @include media($mb-pt) {
            width: 46%;
            text-align: left;
        }
        > a {
            color: #656565;
            font-size: 12px;
            font-weight: 500;
            text-decoration: none;
        }
    }
}

.bbp-header {
    background-color: #1f2124;
    border-radius: 3px;
    padding: 10px 17px;
    li {
        float: left;
    }
}

.bbp-body {
    li {
        float: left;
    }
}

.bbp-footer {
    background-color: #f8f8f8;
    border-radius: 3px;
    padding: 10px 17px;
    li {
        float: left;
    }
    .forum-titles {
        color: #000;
    }
}

.forum-titles {
    @extend .clearfix;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    text-transform: uppercase;
}

.bbp-forum {
    @extend .clearfix;
    padding: 25px 17px;
    &:nth-child(even) {
        background-color: #fcfcfc;
    }
}

.bbp-forum-title {
    display: block;
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 4px;
    text-decoration: none;
}

.bbp-forum-content {
    color: #656565;
    font-size: 11px;
    font-weight: 500;
}

.bbp-topic-started-by {
    color: #656565;
    font-size: 11px;
    font-weight: 500;
    a {
        color: #000;
        font-size: 11px;
        font-weight: 500;
        text-decoration: none;
    }
}

.bbp-meta {
    margin: 0;
}

.bbp-author {
    color: #000;
    font-size: 11px;
    font-weight: 500;
    text-decoration: none;
}

.bbp-author-avatar {
    background-color: #f8f8f8;
    border-radius: 50%;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: -2px 5px 0;
    vertical-align: middle;
    overflow: hidden;
}

.bbp-pagination {
    padding: 0 0 22px;
    margin: -6px 0 0;
    .bbp-pagination-count {
        color: #656565;
        font-size: 12px;
        font-weight: 400;
    }
}

.bbp-reply-post {
    min-height: 195px;
    margin: 4px 0;
    .bbp-reply-header {
        background-color: #f8f8f8;
        color: #646464;
        padding: 5px 18px;
        font-size: 11px;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        .bbp-reply-post-date {
            float: left;
        }
        a {
            color: inherit;
            text-decoration: none;
        }
        .bbp-reply-permalink {
            margin-left: 10px;
        }
    }
    .bbp-reply-topic {
        position: relative;
        padding: 30px 0 30px 100px;
        .bbp-reply-author {
            position: absolute;
            left: 7px;
            top: 25px;
            max-width: 90px;
        }
        .bbp-reply-avatar {
            background-color: #f8f8f8;
            border-radius: 50%;
            display: block;
            width: 72px;
            height: 72px;
            margin: 0 auto 10px;
            overflow: hidden;
        }
        .bbp-author-name {
            color: #000;
            display: block;
            font-size: 13px;
            font-weight: 600;
            text-align: center;
            text-decoration: none;
        }
        .bbp-author-role {
            color: #8c8c8c;
            display: block;
            font: italic 400 11px/12px $accent-font-3;
            text-align: center;
        }
    }
}

.shop-products-block {}

.shop-products-items {
    @extend .clearfix;
    margin: -9px -9px 3px;
    .one-third {
        padding: 9px;
    }
}

.shop-products-item {
    text-align: center;
    .shop-products-item-img {
        background-color: #fafafa;
        position: relative;
        &:hover {
            .shop-products-item-overlay {
                opacity: 1;
                visibility: visible;
            }
        }
        a {
            display: block;
        }
        img {
            display: block;
            max-width: 100%;
            width: 100%;
        }
    }
    .shop-products-item-overlay {
        background-color: rgba(22, 23, 26, .3);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        text-align: center;
        pointer-events: none;
        &:after {
            content: '';
            width: 0;
            height: 0;
            border-bottom: 6px solid $accent-color;
            border-right: 6px solid transparent;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .btn-5 {
        pointer-events: auto;
        min-width: 125px;
    }
    .shop-products-item-name {
        color: #202020;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        margin-top: 15px;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .shop-products-item-price {
        color: $accent-color;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 7px;
    }
}

.cart-widget {
    .cart-products {
        color: #626465;
        font: 13px/22px $content-font;
        padding: 5px 0;
        i {
            margin: -3px 6px 0 0;
        }
    }
}

.lpw-list {
    margin-top: 11px;
    .fs-item {
        border-bottom: 1px solid #f0f0f0;
        min-height: 72px;
        position: relative;
        padding: 3px 0 13px 88px;
        margin: 0 0 13px;
        &:last-child {
            border: none;
            margin-bottom: 0;
        }
    }
    .product-img {
        position: absolute;
        left: 0;
        top: 0;
    }
    .product-title {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .product-price {
        color: #696868;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        strike {
            text-decoration: none;
            position: relative;
            margin-right: 7px;
            &:before {
                background-color: #696868;
                content: '';
                position: absolute;
                top: 45%;
                left: -2px;
                right: -2px;
                height: 1px;
            }
        }
    }
}

.range-wrap {
    padding: 3px 0 8px;
}

.range-slider {
    background-color: #e0e0e0;
    height: 2px;
    margin: 8px 0 16px;
    .ui-slider-range {
        background-color: #000;
    }
    .ui-slider-handle {
        background-color: $accent-color;
        cursor: pointer;
        width: 8px;
        height: 8px;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid $accent-color;
        }
    }
}

.range-amount-block {
    color: #6d6d6d;
    font: 12px/25px $content-font;
    padding: 6px 0 0;
    .range-amount {
        display: block;
        line-height: 36px;
        float: right;
        white-space: nowrap;
    }
}

.shop-product-block {
    .title-blc-3 {
        margin-bottom: 23px;
    }
    .product-description {
        p {
            font-size: 14px;
            line-height: 24px;
        }
    }
    .product-addinfo {
        .pai-list {
            color: #6f6f6f;
            font: 14px/24px $content-font;
            margin: -8px 0 23px;
            .pai-item {
                border-bottom: 1px solid #f0f0f0;
                padding: 8px 0;
                &:last-child {
                    border: none;
                }
            }
        }
    }
}

.shop-product-item {
    @extend .clearfix;
    margin-top: 30px;
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 34px;
    .half {
        padding-left: 12px;
        padding-right: 12px;
    }
    .shop-product-img {
        padding: 6px 0;
        @include media($mb-pt) {
            margin-bottom: 20px;
        }
    }
    .shop-product-title {
        color: #202020;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        padding: 0;
        margin-bottom: 10px;
    }
    .shop-product-rate {
        color: #8f8f8f;
        font: 400 12px $accent-font-3;
        margin-bottom: 12px;
        .rate-tp-1 {
            display: inline-block;
        }
        span {
            margin-left: 8px;
            letter-spacing: 0.03em;
        }
    }
    .shop-product-price {
        color: #000;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 14px;
    }
    .shop-product-description {
        font-size: 13px;
        line-height: 23px;
    }
    .shop-product-controls {
        .quantity {
            display: inline-block;
            cursor: default;
            margin-right: 20px;
            user-select: none;
            .minus,
            .plus {
                background-color: #efefef;
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                width: 17px;
                height: 17px;
                line-height: 17px;
                text-align: center;
                position: relative;
                vertical-align: middle;
                &:before {
                    background-color: #000;
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 5px;
                    width: 7px;
                    height: 1px;
                }
            }
            .plus {
                &:after {
                    background-color: #000;
                    content: '';
                    position: absolute;
                    top: 5px;
                    left: 8px;
                    width: 1px;
                    height: 7px;
                }
            }
            .num {
                display: inline-block;
                color: #626465;
                font: 13px/17px $content-font;
                padding: 0 5px;
                vertical-align: middle;
            }
        }
        .btn-5 {
            min-width: 125px;
        }
    }
}

.product-reviews {
    .pr-comments {
        border-bottom: 1px solid #f0f0f0;
        padding-bottom: 20px;
        margin-bottom: 40px;
        .title-25 {
            margin-bottom: 16px;
        }
    }
    .pr-comments-list {}
    .pr-comments-list-item {
        border-bottom: 1px solid #f0f0f0;
        padding: 8px 112px 8px 84px;
        position: relative;
        margin-bottom: 20px;
        @include media($mb-pt) {
            padding: 8px 0 8px 84px;
        }
        &:last-child {
            border: none;
            margin: 0;
        }
        .prc-avatar {
            background-color: #efefef;
            border-radius: 50%;
            display: block;
            width: 67px;
            height: 67px;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
        }
        p {
            font-size: 14px;
            line-height: 24px;
        }
        .prc-rating {
            border-left: 1px solid #ebebeb;
            position: absolute;
            right: 0;
            top: 0;
            height: 66px;
            line-height: 66px;
            width: 102px;
            text-align: right;
            @include media($mb-pt) {
                position: relative;
            }
            .rate-tp-1 li {
                margin-right: 4px;
                &:last-child {
                    margin: 0;
                }
                i {
                    font-size: 13px;
                }
            }
        }
    }
}

.mini-cart {
    padding: 0 0 0 23px;
    .total {
        color: #686666;
        font-size: 13px;
        font-weight: 500;
        margin: 3px 0 17px;
        .amount {
            color: #000;
        }
    }
    .buttons {
        margin-bottom: 5px;
        .btn-5 {
            min-width: 110px;
            margin-right: 1px;
        }
    }
}

.product-list-widget {
    .mini-cart-item {
        border-bottom: 1px solid #f0f0f0;
        min-height: 72px;
        position: relative;
        padding: 3px 0 13px 88px;
        margin: 0 0 13px;
        &:last-child {
            border: none;
            margin-bottom: 0;
        }
    }
    .product-img {
        position: absolute;
        left: 0;
        top: 0;
    }
    .product-title {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .product-price {
        display: inline-block;
        color: #696868;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        strike {
            text-decoration: none;
            position: relative;
            margin-right: 7px;
            &:before {
                background-color: #696868;
                content: '';
                position: absolute;
                top: 45%;
                left: -2px;
                right: -2px;
                height: 1px;
            }
        }
    }
    .remove {
        position: absolute;
        top: 18px;
        left: -23px;
    }
}

.shop-table-wrap {
    margin: 15px -20px 30px;
    // overflow-x: auto;
}

.shop-table-cart {
    border-radius: 2px;
    width: 100%;
    // min-width: 530px;
    th {
        background-color: #fcfcfc;
        border: 1px solid #ebebeb;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        padding: 12px 10px;
        text-align: center;
        text-transform: uppercase;
        @include media($mb-pt) {
            padding: 12px 5px;
        }
    }
    td {
        background-color: #fff;
        border: 1px solid #ebebeb;
        color: #000;
        font-size: 13px;
        font-weight: 500;
        line-height: 24px;
        padding: 19px 10px;
        vertical-align: middle;
        &.product-remove {
            padding: 19px 0;
            a {
                display: block;
                line-height: 40px;
                padding: 0 12px;
            }
        }
        &.product-thumbnail {
            padding: 19px 18px;
            @include media($mb-pt) {
                padding: 19px 5px;
            }
            figure {
                display: inline-block;
                width: 58px;
                margin-right: 16px;
                vertical-align: middle;
                @include media($mb-pt) {
                    display: none;
                }
            }
            a {
                color: inherit;
                text-decoration: none;
            }
        }
        &.product-price {
            color: $accent-color;
        }
        &.product-quantity {}
        &.product-subtotal {
            color: $accent-color;
        }
    }
    .product-remove {
        width: 38px;
        text-align: center;
    }
    .product-thumbnail {
        width: 535px;
    }
    .product-price {
        width: 180px;
        text-align: center;
    }
    .product-quantity {
        width: 210px;
        text-align: center;
        .quantity {
            display: inline-block;
            cursor: default;
            user-select: none;
            .minus,
            .plus {
                cursor: pointer;
                display: inline-block;
                width: 10px;
                height: 10px;
                line-height: 10px;
                text-align: center;
                position: relative;
                vertical-align: middle;
                @include media($mb-pt) {
                    display: block;
                    margin: 8px auto;
                }
                &:before {
                    background-color: #000;
                    content: '';
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 10px;
                    height: 2px;
                }
            }
            .plus {
                &:after {
                    background-color: #000;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 4px;
                    width: 2px;
                    height: 10px;
                }
            }
            .num {
                color: #222;
                display: inline-block;
                padding: 0 5px;
                min-width: 35px;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    .product-subtotal {
        width: 130px;
        text-align: center;
    }
}

.actions {
    margin-bottom: 35px;
    .input-tp-1 {
        width: 40%;
        @include media($xs) {
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .btn-7 {
        @include media($xs) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    @include media($sm) {
        .right-side {
            text-align: right;
        }
    }
}

.cart-collaterals {
    margin-bottom: 25px;
    .title-14 {
        margin-bottom: 23px;
    }
    .mb-offset {
        margin-bottom: 55px;
    }
}

.cart {
    .right-side {
        width: 366px;
        float: right;
        @include media($sm) {
            width: auto;
            float: none;
        }
    }
    .cart-line {
        margin-bottom: 20px;
        @include media($xs) {
            .mb-offset {
                margin-bottom: 20px;
            }
        }
    }
    .form-block {
        margin-bottom: 35px;
    }
}

.checkout {
    .checkout-line {
        margin-bottom: 20px;
    }
    p {
        font-size: 14px;
        line-height: 24px;
    }
}

.select-pt-1 {
    width: 100%;
}

.ui-selectmenu-button {
    background-color: #fff;
    display: block;
    width: 100%;
    span.ui-icon {
        color: #777777;
        font-size: 14px;
        right: 16px;
        text-indent: 0;
    }
    span.ui-selectmenu-text {
        border: 1px solid #ebebeb;
        border-radius: 2px;
        color: #818181;
        font: 12px/26px $content-font;
        padding: 6px 40px 6px 12px;
    }
}

.ui-selectmenu-menu {
    .ui-menu {
        background-color: #fff;
        border: 1px solid #ebebeb;
        border-radius: 0 0 2px 2px;
        padding-top: 2px;
        margin-top: -2px;
        .ui-menu-item {
            color: #777777;
            font: 12px/26px $content-font;
            padding: 6px 40px 6px 12px;
            &.ui-state-focus {
                background-color: #fafafa;
                margin: 0;
            }
        }
    }
}

.cart_totals {
    color: #797979;
    font: 13px/20px $content-font;
    width: 100%;
    margin: -6px 0 42px;
    td {
        padding: 0 0 18px;
    }
    span {
        color: $accent-color;
    }
}

.remember {
    color: #797979;
    font: 12px/40px $content-font;
    a {
        color: $accent-color;
        text-decoration: none;
        margin-left: 15px;
    }
    .btn-7 {
        margin-right: 25px;
    }
}

.login-customer-block {
    margin: 15px 0 50px;
}

.enter-coupon-block {
    margin-bottom: 44px;
}

.checkout-address-block {
    .checkout-line {
        margin-bottom: 11px;
    }
    .offbt {
        margin-bottom: 20px;
    }
    @include media($xs) {
        .col-half {
            margin-bottom: 11px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.create-account {
    color: #797979;
    font: 12px/40px $content-font;
    a {
        color: $accent-color;
        text-decoration: none;
        margin-left: 15px;
    }
}

.your-order-block {
    margin-bottom: 25px;
}

.order-table {
    border: 1px solid #ebebeb;
    width: 100%;
    .list-first {
        td {
            padding-top: 25px;
        }
    }
    .list-last {
        td {
            padding-bottom: 25px;
        }
    }
    th {
        background-color: #fcfcfc;
        border: 1px solid #ebebeb;
        color: #000;
        font-size: 12px;
        font-weight: 500;
        line-height: 25px;
        width: 50%;
        padding: 11px 24px;
        text-align: left;
        text-transform: uppercase;
        @include media($mb-pt) {
            padding: 11px 10px;
        }
    }
    td {
        color: #797979;
        width: 50%;
        padding: 11px 24px;
        @include media($mb-pt) {
            padding: 11px 10px;
        }
        &.bd {
            border: 1px solid #ebebeb;
        }
        &.bdr {
            border-right: 1px solid #ebebeb;
        }
    }
    .ot-list {
        margin: 3px 0;
        
    }
    .ot-list-item {
        color: #797979;
        font: 13px/16px $content-font;
    }
    .black {
        color: #222;
    }
    .orange {
        color: $accent-color;
    }
    .payment {
        padding: 12px 0;
        .title-28 {
            margin-bottom: 7px;
            i {
                margin: 0 8px;
            }
        }
        p {
            color: #888;
            font: 14px/25px $accent-font-3;
            margin: 0;
        }
    }
    .submit {
        padding: 19px 0;
    }
}
